import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { HttpService } from '@services/http.service';

import { User } from '@app/models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUser$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  // store the URL so we can redirect after logging in
  public redirectUrl: string = '';

  constructor(
    private httpService: HttpService,
  ) { }

  public getCurrentUser$(): Observable<User | null> {
    return this.currentUser$;
  }

  public getCurrentUser(): Promise<User | null> {
    return new Promise<User | null>((resolve, reject) => {
      let user = this.currentUser$.value as User;

      if (!user) {
        return this.refreshUser().then(() => {
          return resolve(this.currentUser$.value);
        }).catch((error: any) => {
          return resolve(null);
        })
      }

      return resolve(user);
    });
  }

  public refreshUser(): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/auth/checklogin`).then((user: User) => {
        // let current = this.currentUser$.getValue();
        if (user) {
          this.currentUser$.next(user);
          return resolve();
        }

        this.currentUser$.next(null);
        return resolve();
      }).catch((err: any) => {
        return reject(err);
      })
    })
  }

  public loginUser(email: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/auth/login`, {
        email,
        password
      }, { fullResponse: true }).then((user: User) => {
        if (user) {
          this.currentUser$.next(user);
          return resolve();
        }

        this.currentUser$.next(null);
        return reject('Die Anmeldung ist derzeit nicht möglich.')
      }).catch((error: any) => {
        return reject(error.error);
      });
    })
  }

  public logoutUser(): Promise<void> {
    return new Promise((resolve, reject) => {

      return this.httpService.get(`**/auth/logout`).then((result: any) => {
        this.currentUser$.next(null);

        return resolve();
      }).catch((error: any) => {
        this.currentUser$.next(null);

        return resolve();
      });
    })
  }

  public requestPasswordRecoverEmail(email: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      return this.httpService.get(`**/auth/request-password-recover-email`, {
        email: email,
      }).then((result: any) => {
        return resolve();
      }).catch((error: any) => {
        return reject();
      });
    })
  }

  public checkPasswordRecoverRequest(email: string, uid: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      return this.httpService.get(`**/auth/check-password-recover-request`, {
        email,
        uid,
      }).then((result: any) => {
        return resolve();
      }).catch((error: any) => {
        return reject();
      });
    })
  }

}
