import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { DialogService } from '@services/dialog.service';
import { LangService } from '@services/lang.service';
import { PartyService } from '@services/party.service';
import { ErrorService } from '@services/error.service';
import { EmployerService } from '@services/employer.service';

import { Party } from '@models/party';
import { User } from '@models/user';
import { PartyRelationship } from '@models/party-relationship';


@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit, AfterViewInit {

  public currentUser: User | null = null;
  public userRoles: string[] = [];
  public currentEmployerPartyId: string | null = '';
  public currentEmployerPartyName: string = '';
  public partyRels: PartyRelationship[] = [];
  public alerts: any[] = [];
  public now: Date = new Date();
  public loading: boolean = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dialogService: DialogService,
    private employerService: EmployerService,
    private partyService: PartyService,
    private langService: LangService,
    private errorService: ErrorService
  ) {
  }

  ngOnInit(): void {
    this.authService.getCurrentUser$().subscribe((user: (User | null)) => {
      this.currentUser = user;

      this.userRoles = user?.roles || [];

      if (user && user.partyId) {
        this.initPartyRelationships(user).then(() => {
          this.getSelectedParty();
        })

        this.langService.getLangAttribute(user.partyId).then((lang: string) => {
          this.langService.setLanguage(lang);
        });
      }
    });

    this.dialogService.alerts$.subscribe((alert: any) => {
      this.alerts.unshift(alert);

      if (alert.duration) {
        setTimeout(() => {
          this.alerts.removeValue(alert);
        }, alert.duration);
        setTimeout(() => {
          alert.fadingOut = true;
        }, alert.duration - 300);
      }
    });
  }

  ngAfterViewInit() {
    this.dialogService.loading$.subscribe((loading: boolean) => {
      setTimeout(() => {
        this.loading = loading;
      });
    });
  }


  private initPartyRelationships(user: User): Promise<void> {
    let partyId: string;
    this.partyRels = [];
    if (!user || !user.partyId) {
      return Promise.reject();
    } else {
      partyId = user.partyId;
    }

    return new Promise((resolve, reject) => {
      return this.partyService.getPartyRelationships(partyId).then((rels: PartyRelationship[]) => {
        if (rels.length > 0) {
          rels.forEach((rel) => {
            this.partyRels.push(rel);
          })
        }

        return resolve();
      }).catch((err) => {
        return reject(err);
      })
    })
  }

  // take first partyRel if no one is selected
  private selectFallbackParty() {
    if (this.partyRels.length) {
      if (this.partyRels[0].toParty && this.partyRels[0].toParty.partyId) {
        this.currentEmployerPartyId = this.partyRels[0].toParty.partyId;
      }

      if (this.partyRels[0].toParty && this.partyRels[0].toParty.attributes) {
        this.currentEmployerPartyName = this.partyRels[0].toParty.attributes!['NAME'];
      }
    }
  }

  private getSelectedParty() {
    let currentPartyId: string;
    if (this.currentUser && this.currentUser.partyId) {
      currentPartyId = this.currentUser.partyId;
    } else {
      this.errorService.printError("Either currentUser or userParty is empty.");
    }

    // first, try to get employerParty from backend
    this.employerService.getCurrentEmployerParty$().subscribe((party: Party | null) => {
      if (party != null) {
        this.currentEmployerPartyId = party.partyId;
        if (party.attributes) {
          this.currentEmployerPartyName = party.attributes['NAME'];
        }
      } else {
        // if backend does not provide employerParty, use fallback (first partyRel)
        this.selectFallbackParty();
      }
    })
  }

  public switchEmployerPartyId(partyId: string): void {
    if (this.currentUser && this.currentUser.partyId && partyId) {
      if (!(this.currentUser.partyId == partyId)) {
        this.employerService.updateEmployerParty(this.currentUser.partyId, partyId);
      } else {
        this.errorService.printError("Attempted to set user as employerParty.");
      }
    } else {
      this.errorService.printError("Either currentUser, userParty or targetParty is empty.");
    }
  }

  public logout() {
    this.authService.logoutUser().then(() => {
      this.router.navigate(['/login']);
    }).catch(() => {
      this.router.navigate(['/login']);
    });
  }

  closeAlert(alert: any) {
    this.alerts.removeValue(alert);
  }


}
