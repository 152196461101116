interface Array<T> {
  uniqueById(): Array<T>;
  removeValue(key: any): Array<T>;
}

Array.prototype.uniqueById = function () {
  var a = this.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i].id === a[j].id)
        a.splice(j--, 1);
    }
  }

  return a;
};

Array.prototype.removeValue = function (value: any): any[] {
  const index = this.indexOf(value, 0);
  if (index > -1) {
    this.splice(index, 1);
  }

  return this;
}