import { Injectable, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, first, Subject } from 'rxjs';

import { GenericModalComponent } from '@modals/generic-modal/generic-modal.component';
import { PromptModalComponent } from '@modals/prompt-modal/prompt-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  public alerts$: Subject<any> = new Subject<any>();
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private activeLoaders: any = {};
  public loadingMessage: string = '';

  private DEFAULT_LABEL: string;

  constructor(
    private translateService: TranslateService
  ) {
    this.DEFAULT_LABEL = this.translateService.instant("Service.DialogService.msg_pleaseWait");
  }

  public confirm(
    title: string | null,
    body: string,
    viewContainerRef: ViewContainerRef,
    buttons?: any[]
  ): Promise<boolean> {
    return new Promise((resolve) => {
      const confirmModal = viewContainerRef.createComponent(GenericModalComponent);

      if (title) {
        confirmModal.setInput('title', title);
      }
      confirmModal.setInput('body', body);
      if (buttons) {
        confirmModal.setInput('buttons', buttons);
      } else {
        confirmModal.setInput('buttons', [{
          text: this.translateService.instant("Common.btn_confirm"),
          color: 'secondary',
          index: 1,
        }]);
      }
      confirmModal.instance.indexOutput.pipe(first()).subscribe((index: number) => {
        if (index) {
          return resolve(true);
        }

        return resolve(false);
      })
    });
  }

  public alert(
    title: string | null,
    body: string,
    color?: string | null,
    duration?: number | null,
    unclosable?: boolean,
  ): void {
    this.alerts$.next({
      title,
      body,
      color: color || 'info',
      duration: (duration || duration === 0) ? duration : 8000,
      unclosable,
    });
  }

  public prompt(
    title: string,
    body: string,
    type: string,
    value: string | number,
    viewContainerRef: ViewContainerRef,
    label?: string
  ): Promise<string | number | null> {
    return new Promise((resolve, reject) => {
      const confirmModal = viewContainerRef.createComponent(PromptModalComponent);

      if (title) {
        confirmModal.setInput('title', title);
      }
      if (!label) {
        label = 'Eingabe'
      }
      confirmModal.setInput('body', body);
      confirmModal.setInput('inputs', [{
        name: 'input',
        label: label,
        type: type,
        value: value,
        required: true,
      }]);
      confirmModal.instance.indexOutput.pipe(first()).subscribe((values: any) => {
        return resolve(values.input);
      })
    });
  }

  public promptMultiple(
    title: string,
    body: string,
    fields: any[],
    viewContainerRef: ViewContainerRef,
  ): Promise<string | number | null> {
    return new Promise((resolve, reject) => {
      const confirmModal = viewContainerRef.createComponent(PromptModalComponent);

      let inputs = fields.map((field: any) => {
        return {
          name: field.name,
          label: field.label,
          type: field.type,
          value: field.value,
          required: field.required,
        };
      });

      if (title) {
        confirmModal.setInput('title', title);
      }
      confirmModal.setInput('body', body);
      confirmModal.setInput('inputs', inputs);
      confirmModal.instance.indexOutput.pipe(first()).subscribe((values: any) => {
        return resolve(values);
      })
    });
  }

  public unimplementedWarning(): void {
    this.alert(
      null,
      this.translateService.instant("Service.DialogService.msg_notImplementedYet"),
      'warning',
    );
  }

  public showLoader(id: string, pMessage?: string): void {
    this.loadingMessage = pMessage || this.DEFAULT_LABEL;

    this.activeLoaders[id] = true;

    this.loading$.next(true);
  }

  public dismissLoader(id: string): void {
    if (this.activeLoaders) {
      delete this.activeLoaders[id];
    }

    if (Object.keys(this.activeLoaders).length || !this.loading$.value) {
      return;
    }

    this.loading$.next(false);
  }

  public setProgress(progress: number): void {
    this.loadingMessage = `${progress}%`;
  }

}
