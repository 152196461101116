<div>
  <div class="upload-wizzard" [ngClass]="{ active: dataCatalogStatus?.status == 'PRISTINE' || dataCatalogStatus?.status == 'READY', done: dataCatalogStatus?.status != 'PRISTINE' }">
    <div class="row d-flex align-items-stretch">
      <div class="col-2 d-flex flex-column align-items-center justify-content-center">
        <h1>1</h1>
        <h5>{{ "Template.UploadComponent.subHeader_uploadData" | translate}}</h5>
      </div>
      <div class="col-4 d-flex flex-column justify-content-center" [ngClass]="{ enabled: dataCatalogStatus?.status == 'PRISTINE' || dataCatalogStatus?.status == 'READY' }">
        <input #formFile class="form-control" type="file" id="formFile" accept=".xlsx, .xls, .csv" (change)="onFileSelected($event)" [disabled]="!(dataCatalogStatus?.status == 'PRISTINE' || dataCatalogStatus?.status == 'READY')" />

        <button type="submit" (click)="postFile()" class="btn btn-secondary d-block w-100 mt-2" [disabled]="!file || isButtonDisabled">{{ "Template.FileUploadFormComponent.btn_upload" | translate }}</button>
      </div>
      <div class="col-6 d-flex flex-column justify-content-center">
        <p>{{ "Template.UploadComponent.text_firstStep" | translate}}</p>
      </div>
    </div>
  </div>

  <div class="upload-wizzard" [ngClass]="{ active: dataCatalogStatus?.status == 'UPLOADED' || dataCatalogStatus?.status == 'PROCESSING' || dataCatalogStatus?.status == 'CLEANING', done: dataCatalogStatus?.status == 'READY' }">
    <div class="row d-flex align-items-stretch">
      <div class="col-2 d-flex flex-column align-items-center justify-content-center">
        <h1>2</h1>
        <h5>{{ "Template.UploadComponent.subHeader_dataProcessing" | translate}}</h5>
      </div>
      <div class="col-4 d-flex flex-column justify-content-center">
        <div class="w-100 text-center">
          <div *ngIf="dataCatalogStatus?.status == 'UPLOADED'">
            <fa-icon icon="spinner" [spin]="true" size="2x"></fa-icon>
            <small class="d-block mt-2">{{ "Template.UploadComponent.text_importStarting" | translate}} </small>
          </div>

          <ngb-progressbar *ngIf="dataCatalogStatus?.status != 'UPLOADED'" [showValue]="true" type="success" [value]="dataCatalogStatus?.progress || 0" [animated]="true"></ngb-progressbar>
        </div>
      </div>
      <div class="col-6 d-flex flex-column justify-content-center">
        <p>{{ "Template.UploadComponent.text_secondStep" | translate}}</p>
      </div>
    </div>
  </div>

  <div class="upload-wizzard" *ngIf="dataCatalogStatus && mediaItemsByStatus" [ngClass]="{ active: dataCatalogStatus.status == 'READY' && (dataCatalogStatus.messages.length || mediaItemsByStatus['NOT_FOUND']?.length || mediaItemsByStatus['INVALID']?.length), done: dataCatalogStatus.status == 'READY' }">
    <div class="row d-flex align-items-stretch">
      <div class="col-2 d-flex flex-column align-items-center justify-content-center">
        <h1>3</h1>
        <h5 *ngIf="dataCatalogStatus.status == 'READY'">{{ "Template.UploadComponent.subHeader_ready" | translate}}</h5>
        <h5 *ngIf="dataCatalogStatus.status != 'READY'">{{ "Template.UploadComponent.subHeader_response" | translate}}</h5>
      </div>
      <div class="col-4 d-flex flex-column justify-content-center" *ngIf="dataCatalogStatus?.status == 'READY' && !dataCatalogStatus.messages?.length && !mediaItemsByStatus['NOT_FOUND']?.length && !mediaItemsByStatus['INVALID']?.length">
        <fa-icon icon="check" size="3x" class="text-success"></fa-icon>
      </div>
      <div class="d-flex flex-column justify-content-center" [ngClass]="{ 'col-10': dataCatalogStatus.status != 'READY' || (dataCatalogStatus.status == 'READY' && (dataCatalogStatus.messages.length || mediaItemsByStatus['NOT_FOUND']?.length || mediaItemsByStatus['INVALID']?.length)), 'col-6': dataCatalogStatus.status == 'READY' && !dataCatalogStatus.messages.length && !mediaItemsByStatus['NOT_FOUND']?.length && !mediaItemsByStatus['INVALID']?.length }">
        <p *ngIf="dataCatalogStatus.status != 'READY'">{{ "Template.UploadComponent.text_response" | translate}}</p>

        <p *ngIf="dataCatalogStatus.status == 'READY' && !dataCatalogStatus.messages?.length && !mediaItemsByStatus['NOT_FOUND']?.length && !mediaItemsByStatus['INVALID']?.length">{{ "Template.UploadComponent.text_uploadSuccess" | translate}}</p>
        <p *ngIf="dataCatalogStatus.status == 'READY' && (dataCatalogStatus.messages?.length || mediaItemsByStatus['NOT_FOUND']?.length || mediaItemsByStatus['INVALID']?.length)">{{ "Template.UploadComponent.text_uploadError" | translate}}</p>

        <p *ngIf="dataCatalogStatus.status == 'READY' && mediaItemsByStatus['NEW']?.length" class="mt-2">
          <fa-icon icon="exclamation-triangle" size="1x" class="text-warning me-2"></fa-icon>
          <strong>{{ "Template.UploadComponent.text_processingContinues1" | translate : { amount: mediaItemsByStatus["NEW"]?.length } }}</strong>
          {{ "Template.UploadComponent.text_processingContinues2" | translate }}
          <fa-icon icon="spinner" [spin]="true" size="1x"></fa-icon>
        </p>

        <app-messages-nav [dataCatalogStatus]="dataCatalogStatus" [mediaItemsByStatus]="mediaItemsByStatus"></app-messages-nav>

      </div>
    </div>
  </div>
</div>
