import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.scss']
})
export class PromptModalComponent implements AfterViewInit {

  @Input() public title: string = '';
  @Input() public body: string = '';
  @Input() public inputs: any[] = [];

  @Output() public indexOutput = new EventEmitter<any>();

  @ViewChild('modalContent') private modalContent!: TemplateRef<PromptModalComponent>;
  private modalRef!: NgbModalRef;

  public form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
  ) {
    this.form = this.formBuilder.group({});
  }

  ngAfterViewInit(): void {
    this.inputs.forEach((input: any) => {
      let control = new FormControl(input.value || '');

      let validators = [];
      if (input.required) {
        validators.push(Validators.required);
      }
      if (input.email) {
        validators.push(Validators.email);
      }
      control.setValidators(validators);

      if (!input.value && (input.type == 'text' || input.type == 'email')) {
        control.setValue('');
      } else if (!input.value && input.type == 'number') {
        control.setValue(0);
      }

      this.form.addControl(input.name, control);
    });

    this.open();
  }

  open() {
    this.modalRef = this.modalService.open(this.modalContent)
  }

  close() {
    this.modalRef.close();
    this.indexOutput.emit(this.form.value);
  }

  dismiss() {
    this.modalRef.dismiss();
    this.indexOutput.emit({});
  }

}
