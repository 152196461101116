<h3>E-Mail-Center</h3>

<button class="btn btn-success" type="button" (click)="isCollapsed = ! isCollapsed" [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapseExample">
    <fa-icon class="fas mr-2" icon="envelope"></fa-icon>
    {{ "Template.EmailsComponent.btn_createNewMail" | translate }}
</button>

<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" id="createEmail" class="pt-5">
    <form [formGroup]="emailForm" (ngSubmit)="submitForm()" class="mb-5">
        <div class="row">
            <div class="col-12 col-md-8 col-lg-9">
                <strong>{{ "Template.EmailsComponent.subHeader_subject" | translate }}:</strong>
                <div class="form-group">
                    <input type="text" formControlName="subject" class="form-control" id="subject" maxlength="120"
                        value="{{subject}}" required>
                </div>
            </div>

            <div *ngIf="dataStreamGroups" class="col-12 col-md-4 col-lg-3">
                <strong>{{ "Template.EmailsComponent.subHeader_receiver" | translate }}:</strong>

                <div *ngFor="let dataStreamGroup of dataStreamGroups; let i = index" formArrayName="dsg"
                    class="form-check">
                    <input class="form-check-input" type="checkbox" [formControlName]="i"
                        id="dsg_{{dataStreamGroup.id}}">
                    <label class="form-check-label" for="dsg_{{dataStreamGroup.id}}">
                        {{dataStreamGroup.name}}
                    </label>

                </div>
            </div>
        </div>

        <strong>{{ "Template.EmailsComponent.subHeader_message" | translate }}:</strong>
        <div class="wysiwyg">
            <textarea formControlName="message" placeholder="Nachricht" cols="64" rows="10" id="message"
                class="form-control">
				{{message}}
			</textarea>
            <small class="d-block">Hinweis: Die Ansprache ("Sehr geehrte(r) <em>Name</em>, ...") wird automatisch hinzugefügt.</small>
        </div>

        <div class="text-end">
            <button type="submit" class="btn btn-primary float-right">
                {{ "Template.EmailsComponent.btn_send" | translate }}
                <fa-icon class="ml-2" icon="paper-plane"></fa-icon>
            </button>
        </div>

    </form>

    <hr />
</div>



<h5 *ngIf="emails" class="mt-5 mb-2">{{ "Template.EmailsComponent.subHeader_mailsSent" | translate }}:</h5>
<table *ngIf="emails" class="table table-striped align-middle">
    <thead>
        <tr>
            <th scope="col" style="width: 15%">{{ "Template.EmailsComponent.label_created" | translate }}</th>
            <th scope="col" style="width: 5%">&nbsp;</th>
            <th scope="col" style="width: 35%">{{ "Template.EmailsComponent.label_subject" | translate }}</th>
            <th scope="col" style="width: 10%" class="text-center">{{ "Template.EmailsComponent.label_receiver" | translate }}</th>
            <th scope="col" style="width: 35%">{{ "Template.EmailsComponent.label_info" | translate }}</th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let email of emails">
            <td class="align-middle">
                {{email.createdDateTime | date:"d. MMMM yyyy"}}
                <span class="d-block">
                    {{email.createdDateTime | date: "HH:mm"}} Uhr
                </span>
            </td>
            <td class="align-middle">
                <button role="button" class="btn btn-link px-0 py-1" (click)="showDetailModal(email)">
                    <fa-icon icon="envelope-open-text" ngbTooltip="{{ 'Template.EmailsComponent.tooltip_viewCompleteMail' | translate }}">
                    </fa-icon>
                </button>
            </td>
            <td class="align-middle">
                <span class="d-block">{{email.subject}}</span>
            </td>
            <td class="text-center align-middle">
                {{email.receiversCount!}}
                <a (click)="getEmailReceivers(email)" target="_blank" class="btn btn-link p-0 pb-1 ml-2" type="button"
                    ngbTooltip="{{ 'Template.EmailsComponent.tooltip_downloadShippingDocument' | translate }}">
                    <fa-icon icon="file-download"></fa-icon>
                </a>
            </td>
            <td class="align-middle">
                <small class="d-block">{{ "Template.EmailsComponent.text_sentBy" | translate }}:</small>
                {{email.createdBy}}
                <small class="d-block mt-2">{{email.note}}</small>
            </td>
        </tr>
    </tbody>
</table>