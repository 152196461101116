import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { AuthService } from '@services/auth.service';
import { DialogService } from '@services/dialog.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  public error: any;
  public recoverPasswordForm: FormGroup;
  public isLoading: boolean = false;
  public isResetVerification: boolean = true; // if this component is entered via verification link (false: email and uid set)

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private dialogService: DialogService,
  ) {
    this.recoverPasswordForm = this.formBuilder.group({
      email: ['',
        Validators.compose([Validators.required, Validators.email])
      ],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams['email'] && queryParams['uid']) {
        this.isResetVerification = false;
        this.checkRecoverRequest(queryParams['email'], queryParams['uid']);
      }
    });
  }

  public checkEmail(): void {
    this.isLoading = true;
    let email = this.recoverPasswordForm.controls['email'].value;

    this.authService.requestPasswordRecoverEmail(email).then(() => {
      this.dialogService.alert(
        null,
        'Vielen Dank. Sofern die eingegebene E-Mail-Adresse korrekt ist werden Sie gleich eine E-Mail erhalten, die einen Link zum Zurücksetzen des Passworts enthält.'
      )

      this.recoverPasswordForm.reset();
      this.isLoading = false;
    }).catch(() => {
      this.dialogService.alert(
        null,
        'Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        'danger',
      )
      this.isLoading = false;
    })
  }

  private checkRecoverRequest(email: string, uid: string) {
    this.isLoading = true;

    this.authService.checkPasswordRecoverRequest(email, uid).then(() => {
      // this.dialogService.alert(
      //   null,
      //   'Ihr Passwort wurde erfolgreich zurückgesetzt. Sie erhalten in Kürze eine E-Mail mit einem neuen Passwort.',
      //   'success',
      //   null,
      //   true,
      // )

      this.recoverPasswordForm.reset();
      this.isLoading = false;
    }).catch(() => {
      this.dialogService.alert(
        null,
        'Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        'danger',
      )
      this.isLoading = false;
    })
  }

}
