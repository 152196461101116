export const ROLES: any = {
  CUSTOMER: "ROLE_CUSTOMER",
  SUPPLIER: "ROLE_SUPPLIER",
  ADMIN: "ROLE_ADMIN",
};

export const PARTY_RELATIONSHIP_TYPES: any = {
  EMPLOYMENT: "EMPLOYMENT",
  CUSTOMER_RELATIONSHIP: "CUSTOMER_RELATIONSHIP",
  SUPPLIER_RELATIONSHIP: "SUPPLIER_RELATIONSHIP",
  APPLICATION_RELATIONSHIP: "APPLICATION_RELATIONSHIP",
  ASSIGNMENT: "ASSIGNMENT"
}

export const PARTY_ATTRIBUTE_TYPES: any = {
  LAST_SELECTED_PARTYID: "LAST_SELECTED_PARTYID"
}