<div class="titlebar">
  <h3>{{ "Template.CustomersComponent.subHeader_tradingPartner" | translate }}</h3>

  <div class="buttons">
    <button class="btn btn-success" [disabled]="loading" (click)="openModal(createCustomerModal)">
      <fa-icon icon="plus"></fa-icon>
    </button>
    <button class="btn btn-secondary" [disabled]="loading" (click)="loadCustomers()">
      <fa-icon icon="arrow-rotate-right"></fa-icon>
    </button>
  </div>
</div>

<fa-icon icon="spinner" [spin]="true" *ngIf="loading"></fa-icon>

<div *ngIf="!loading && customers &&  !customers.length">
  <p>{{ "Template.CustomersComponent.text_NoTradingPartnersCreatedYet" | translate }}</p>
</div>

<div *ngIf="!loading && customers && customers.length">
  <table class="table table-striped">
    <caption>{{ "Template.CustomersComponent.text_numberOfTradingPartners" | translate: {number: customersCount} }}
    </caption>
    <thead>
      <tr>
        <th scope="col">{{ "Template.CustomersComponent.label_customerNo" | translate }}</th>
        <th scope="col">{{ "Template.CustomersComponent.label_company" | translate }}</th>
        <th scope="col" class="text-center">{{ "Template.CustomersComponent.label_enabled" | translate }}</th>
        <th scope="col">{{ "Template.CustomersComponent.label_datastreamGroups" | translate }}</th>
        <th scope="col">&nbsp;</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let customer of customers" [ngClass]="{'table-success': customer.internalParty}">
        <td scope="row" class="align-middle">
          <span *ngIf="customer.customerNo">
            {{customer.customerNo}}
          </span>

          <span *ngIf="!customer.customerNo">
            ---
          </span>
        </td>
        <td class="align-middle">
          {{customer.name!}}
        </td>
        <td class="text-center align-middle">
          <fa-icon icon="check" class="text-success" *ngIf="customer.enabled"></fa-icon>
          <fa-icon icon="times" class="text-danger" *ngIf="!customer.enabled"></fa-icon>
        </td>
        <td class="align-middle">
          <div class="form-check" *ngFor="let dataStreamGroup of dataStreamGroups">
            <input class="form-check-input" type="checkbox" [id]="dataStreamGroup.id" disabled [checked]="isDataStreamGroupIncluded((customer.dataStreamGroups || null), dataStreamGroup)">
            <label class="form-check-label" [for]="dataStreamGroup.id">
              {{dataStreamGroup.name!}}
            </label>
          </div>
        </td>
        <td class="text-end align-middle">
          <a [routerLink]="['/app', 'admin', 'customers', customer.partyId]" class="btn btn-secondary">
            {{ "Template.CustomersComponent.text_details" | translate }}
            <fa-icon icon="chevron-right"></fa-icon>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #createCustomerModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ "Template.CustomersComponent.subHeader_createCustomer" | translate
      }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div *ngIf="createStep == 1">
      <form [formGroup]="customerForm" novalidate (ngSubmit)="createCustomer()">
        <div class="form-group">
          <label for="customerNo">{{ "Template.CustomersComponent.label_EKCustomerNo" | translate }} <small>({{
              "Template.CustomersComponent.label_optionally" | translate }})</small></label>
          <input type="text" class="form-control" id="customerNo" formControlName="customerNo" placeholder="{{'Template.CustomersComponent.placeholder_EKCustomerNo' | translate}}">
          <small class="form-text text-muted">{{ "Template.CustomersComponent.text_skipFieldForExternals" | translate }}</small>
        </div>
        <div class="form-group">
          <label for="companyName">{{ "Template.CustomersComponent.label_companyName" | translate }}</label>
          <input type="text" class="form-control" id="companyName" formControlName="companyName" placeholder="{{'Template.CustomersComponent.placeholder_companyName' | translate}}">
        </div>

        <div class="form-group" *ngIf="dataStreamGroups && dataStreamGroups.length">
          <label for="em">{{ "Template.CustomersComponent.label_datastreams" | translate }}</label>
          <div class="px-2">
            <div class="form-check" *ngFor="let dataStreamGroup of dataStreamGroups" formArrayName="dataStreamGroupIds">
              <input class="form-check-input" type="checkbox" [id]="'dsg_' + dataStreamGroup.id" (change)="dataStreamGroupIdChange(dataStreamGroup.id!, $event)">
              <label class="form-check-label" [for]="'dsg_' + dataStreamGroup.id">
                {{dataStreamGroup.name}}
                <a *ngIf="dataStreamGroup.isCollapsed" class="btn btn-link px-1 py-0"
                  (click)="dataStreamGroup.isCollapsed = !dataStreamGroup.isCollapsed" role="button">
                  <fa-icon icon="chevron-down"></fa-icon>
                </a>
                <a *ngIf="!dataStreamGroup.isCollapsed" class="btn btn-link px-1 py-0"
                  (click)="dataStreamGroup.isCollapsed = !dataStreamGroup.isCollapsed" role="button">
                  <fa-icon icon="chevron-up"></fa-icon>
                </a>

                <div class="collapse px-1 mb-2" #collapse="ngbCollapse" [(ngbCollapse)]="dataStreamGroup.isCollapsed">
                  <app-data-stream-preview *ngIf="!dataStreamGroup.isCollapsed" [dataStreamGroup]="dataStreamGroup">
                  </app-data-stream-preview>
                </div>
              </label>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-success d-block w-100" [disabled]="loadingCreate || !customerForm.valid">
          {{ "Template.CustomersComponent.btn_continue" | translate }}
          <fa-icon icon="chevron-right"></fa-icon>
          <fa-icon icon="spinner" [spin]="true" *ngIf="loadingCreate"></fa-icon>
        </button>
      </form>
    </div>
    <div *ngIf="createStep == 2 && userProps.employerPartyId">
      <app-create-user-form [userProps]="userProps" (exitFormEvent)="exitUserForm($event)"></app-create-user-form>
    </div>

    <p class="alert alert-danger text-center mt-3" *ngIf="error">
      <strong>{{error}}</strong>
    </p>
  </div>
</ng-template>