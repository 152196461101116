import { Component, Input, OnInit } from '@angular/core';
import { Subscription, of, switchMap, timer } from 'rxjs';

import { DialogService } from '@services/dialog.service';
import { PartyService } from '@services/party.service';
import { ProductService } from '@services/product.service';

import { DataCatalogStatus } from '@models/data-catalog-status';

@Component({
  selector: 'app-data-management',
  templateUrl: './data-management.component.html',
  styleUrls: ['./data-management.component.scss']
})
export class DataManagementComponent implements OnInit {

  @Input() partyId: string | null = null;

  public dataCatalogStatus!: DataCatalogStatus | null;
  public mediaItemsByStatus: any | null = null;

  private subDc: Subscription | null = null;
  private subMi: Subscription | null = null;

  constructor(
    private partyService: PartyService,
    private productService: ProductService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.dialogService.showLoader("DataManagementComponent");
    this.subscribeDataCatalogStatus();
    this.subscribeMediaItems();
  }

  private subscribeDataCatalogStatus() {
    this.subDc = timer(0, 5000).pipe(switchMap(() => {
      if (this.partyId) {
        return this.partyService.getDataCatalogStatus(this.partyId);
      }

      return of(null);
    })).subscribe((dataCatalogStatus: DataCatalogStatus | null) => {
      this.dataCatalogStatus = dataCatalogStatus;
      this.dialogService.dismissLoader("DataManagementComponent");
    });
  }

  private subscribeMediaItems() {
    this.subMi = timer(0, 10000).pipe(switchMap(() => {
      if (this.partyId) {
        return this.productService.getMediaItemsForParty(this.partyId);
      }

      return of(null);
    })).subscribe((result: any) => {
      this.mediaItemsByStatus = result;
    });
  }

  ngOnDestroy() {
    if (this.subDc) {
      this.subDc.unsubscribe();
    }
    if (this.subMi) {
      this.subMi.unsubscribe();
    }
  }

}
