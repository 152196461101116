import { Component, OnInit } from '@angular/core';
import { IRequestParameters } from '@app/interfaces/irequest-parameters';
import { DataStream } from '@models/data-stream';
import { Supplier } from '@models/supplier';
import { DataStreamService } from '@services/data-stream.service';
import { ErrorService } from '@services/error.service';


@Component({
  selector: 'app-data-streams',
  templateUrl: './data-streams.component.html',
  styleUrls: ['./data-streams.component.scss'],
})
export class DataStreamsComponent implements OnInit {

  public requestParameters: IRequestParameters[] = [];
  public dataStreams: DataStream[] = [];
  public productCount: number = 0;
  public disabledProductCount: number = 0;
  public sortOrder: number = 1; // 1 = asc | -1 = desc

  constructor(
    private dataStreamService: DataStreamService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.init();
  }

  private init(): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.requestDataStreams().then((response) => {
        this.dataStreams = response;
        this.dataStreams = this.dataStreams.sort((a, b) => {
          return (a.id > b.id ? -1 : 1);
        })
        return Promise.resolve(this.dataStreams);
      }).then((dataStreams) => {
        return this.requestDataStreamSuppliers(dataStreams);
      }).then((dataStreams) => {
        return this.calculateProductCount(dataStreams);
      }).then(() => {
        return resolve();
      })
    })
  }

  private calculateProductCount(dataStreams: DataStream[]) {
    dataStreams.forEach((element) => {
      if (element.enabled && element.productCount) {
        this.productCount += element.productCount;
      }
      if (!element.enabled && element.productCount) {
        this.disabledProductCount += element.productCount;
      }
    })
  }

  private requestDataStreams(): Promise<DataStream[]> {
    return this.dataStreamService.requestAllDataStreams();
  }

  private requestDataStreamSuppliers(dataStreams: DataStream[]): Promise<DataStream[]> {
    let ret: DataStream[] = [];
    return new Promise(async (resolve, reject) => {
      for (let dataStream of dataStreams) {
        await this.dataStreamService.requestDataStreamSupplier(dataStream.id).then((supplier: Supplier | null) => {
          if (supplier) {
            dataStream.supplier = supplier;
          }
          ret.push(dataStream);
        })
      }
      return resolve(ret);
    })
  }

  public getDataStreamAnalysis() {

  }

  public sortDataStreamsByVersionDate(order?: string) {
    this.dataStreams = this.dataStreams.sort((a: DataStream, b: DataStream): number => {
      if (!a.versionDate && !b.versionDate) {
        return 0 * this.sortOrder;
      } else if (!a.versionDate) {
        return -1 * this.sortOrder;
      } else if (!b.versionDate) {
        return 1 * this.sortOrder;
      } else {
        return (new Date(a.versionDate).getTime() - new Date(b.versionDate).getTime()) * this.sortOrder;
      }
    })

    this.toggleSortOrder();
  }

  public sortDataStreamsByDataSource() {
    this.dataStreams = this.dataStreams.sort((a: DataStream, b: DataStream): number => {
      //TODO: sourcename not currently in the object
      return 0;
    })

    this.toggleSortOrder();
  }


  public sortDataStreamsByProductCount() {
    this.dataStreams = this.dataStreams.sort((a: DataStream, b: DataStream): number => {
      if (!a.productCount && !b.productCount) {
        return 0 * this.sortOrder;
      } else if (!a.productCount) {
        return -1 * this.sortOrder;
      } else if (!b.productCount) {
        return 1 * this.sortOrder;
      } else {
        return (a.productCount - b.productCount) * this.sortOrder;
      }
    })

    this.toggleSortOrder();
  }

  public sortDataStreamsByEnabled() {
    this.dataStreams = this.dataStreams.sort((a: DataStream, b: DataStream): number => {
      if (!a.enabled && !b.enabled || a.enabled && b.enabled) {
        return 0 * this.sortOrder;
      } else if (!a.enabled) {
        return -1 * this.sortOrder;
      } else if (!b.enabled) {
        return 1 * this.sortOrder;
      }
      return 0;
    })

    this.toggleSortOrder();
  }

  private toggleSortOrder() {
    if (this.sortOrder < 0) {
      this.sortOrder = 1;
    } else {
      this.sortOrder = -1;
    }
  }

}