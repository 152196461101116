import { Component, OnInit } from '@angular/core';
import { IDataCatalog } from '@app/interfaces/idata-catalog';
import { Party } from '@models/party';
import { EmployerService } from '@services/employer.service';

@Component({
  selector: 'app-cockpit',
  templateUrl: './cockpit.component.html',
  styleUrls: ['./cockpit.component.scss']
})
export class CockpitComponent implements OnInit {

  public dataCatalogs: IDataCatalog[] = [];
  public currentPartyId: string | null = null;

  constructor(
    private employerService: EmployerService
  ) { }

  ngOnInit(): void {
    this.employerService.getCurrentEmployerParty$().subscribe((currentParty: Party | null) => {
      if (currentParty) {
        this.currentPartyId = currentParty.partyId;
      }
    });
  }

}
