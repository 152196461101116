import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataStreamGroup } from '@models/data-stream-group';

import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@services/dialog.service';

interface IEmail {
  emailId: string;
  subject: string;
  message: string;
  createdBy?: any;
  note?: any;
  receiversCount: number;
  createdDateTime: Date;
}

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss']
})
export class EmailsComponent implements OnInit {

  public dataStreamGroups: DataStreamGroup[] = [];
  public subject: string = "";
  public message: string = "";
  public emails: IEmail[] = [];
  public isCollapsed = true;
  public emailForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private viewContainerRef: ViewContainerRef,
    private translateService: TranslateService
  ) {
    this.insertTestData();
    this.createForm();
    this.initForm();
  }

  ngOnInit(): void {
  }

  private createForm() {
    let defaultStreamGroups: DataStreamGroup[] = [];
    // choose deafult streamGroups
    defaultStreamGroups.push(this.dataStreamGroups[0]);
    this.emailForm = this.formBuilder.group({
      subject: '',
      message: '',
      dsg: this.formBuilder.array(
        // assign default selected streamGroups to form
        this.dataStreamGroups.map(x => defaultStreamGroups.indexOf(x) > -1)
      )
    });
  }

  private initForm() {
    this.emailForm.controls['subject'].setValue("");
    this.emailForm.controls['message'].setValue("");
  }

  public createEmail() { }

  public submitForm() {
    // TODO: add logic
    this.dialogService.unimplementedWarning();
  }

  private insertTestData() {
    this.emails.push({ createdDateTime: new Date(), message: "testmessage", emailId: "1", receiversCount: 0, subject: "testsubject" });
    this.dataStreamGroups.push({ id: "test", name: "EK Großhandel Sortiment", isCollapsed: true });
    this.dataStreamGroups.push({ id: "test2", name: "EK ZR Sortiment", isCollapsed: true });
  }

  public showDetailModal(email: IEmail) {
    let buttons = [];
    buttons.push({
      text: this.translateService.instant("Dialog.EmailsComponent.btn_close"),
      color: 'secondary',
      index: 1,
    });
    // TODO: Abbrechen-Button not needed
    this.dialogService.confirm(email.subject,
      email.message,
      this.viewContainerRef,
      buttons);
  }

  public getEmailReceivers(email: IEmail) {
    // TODO: get receivers via http for example (using emailId)
  }

}
