import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ErrorService } from '@services/error.service';

import { ROLES } from '@services/constants.service';

import { Customer } from '@models/customer';
import { DataStreamGroup } from '@models/data-stream-group';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomerService } from '@services/customer.service';
import { DataStreamService } from '@services/data-stream.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  public loading: boolean = false;
  public loadingCreate: boolean = false;
  public createStep: number = 1;

  public customers: Customer[] = [];
  public customersCount: number = 0;

  public customerForm: FormGroup;
  public userProps = { employerPartyId: "", employeeRoles: [ROLES.CUSTOMER] };

  private customerCreateModal: NgbModalRef | null = null;
  public error: string = '';
  public dataStreamGroups: DataStreamGroup[] = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private customerService: CustomerService,
    private dataStreamService: DataStreamService,
    private errorService: ErrorService,
    private translateService: TranslateService
  ) {
    this.customerForm = this.formBuilder.group({
      customerNo: ['',
        Validators.compose([])
      ],
      companyName: ['',
        Validators.compose([Validators.required])
      ],
      dataStreamGroupIds: this.formBuilder.array([], [Validators.required]),
    });    
  }

  ngOnInit(): void {
    this.loadCustomers();

    this.dataStreamService.getDataStreamGroups().then((dataStreamGroups: DataStreamGroup[]) => {
      this.dataStreamGroups = dataStreamGroups;
    });
  }

  public loadCustomers() {
    this.loading = true;

    this.customerService.getCustomers().then((customersMap: any) => {
      this.customers = customersMap.customers;
      this.customersCount = customersMap.count;

      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;

      this.errorService.printError(error);
    });
  }

  public dataStreamGroupIdChange(dataStreamGroupId: string, event: any) {
    const dataStreamGroupIds = <FormArray>this.customerForm.controls['dataStreamGroupIds'];

    if (event.target && event.target.checked) {
      dataStreamGroupIds.push(new FormControl(dataStreamGroupId));
    } else {
      let index = dataStreamGroupIds.controls.findIndex(x => x.value == dataStreamGroupId);
      dataStreamGroupIds.removeAt(index);
    }
  }

  public createCustomer() {
    this.loadingCreate = true;

    this.customerService.createCustomer(
      this.customerForm.controls['companyName'].value,
      this.customerForm.controls['customerNo'].value,
    ).then((respEmployerPartyId: string) => {
      if (!respEmployerPartyId) {
        return;
      }
      this.userProps.employerPartyId = respEmployerPartyId;
      return this.dataStreamService.setDataStreamGroupAccesses(this.userProps.employerPartyId, this.customerForm.controls['dataStreamGroupIds'].value);
    }).then(() => {
      this.createStep = 2;
      this.customerForm.reset();

      this.loadCustomers();
      this.loadingCreate = false;
      this.error = '';
    }).catch((error: string) => {
      this.error = error || this.translateService.instant("Dialog.CustomersComponent.msg_noCustomerCreationPossible");
      this.loadingCreate = false;
    });
  }

  public openModal(modal: any) {
    this.customerCreateModal = this.modalService.open(modal);
    this.customerCreateModal.result.then(() => {
      this.resetCustomerForm();
    }, () => {
      this.resetCustomerForm();
    });
  }

  private resetCustomerForm() {
    this.customerForm.reset();
    this.loadingCreate = false;
    this.error = '';
    this.createStep = 1;
  }

  public isDataStreamGroupIncluded(customerDataStreamGroups: DataStreamGroup[] | null, dataStreamGroup: DataStreamGroup) {
    return customerDataStreamGroups && customerDataStreamGroups.map(dataStreamGroup => dataStreamGroup.id).includes(dataStreamGroup.id);
  }

  public exitUserForm(event: boolean) {
    this.modalService.dismissAll();
    if (event == true) {
      this.router.navigate(['/app', 'admin', 'customers', this.userProps.employerPartyId]);
    }
  }

}
