import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, from, Observable, switchMap, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '@services/auth.service';

import { User } from '@models/user';

import { environment } from '@envs/environment';

@Injectable()
export class EkHttpInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthService,
    private readonly injector: Injector,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Avoid circular dependency
    const translateService = this.injector.get(TranslateService);

    request = request.clone({
      headers: request.headers.set('Accept-Language', translateService.currentLang || 'en')

    });

    if (!request.url.startsWith(environment.apiUrl)) {
      return next.handle(request)
    }

    if (!request.url.includes(`/auth/`)) {
      return from(this.authService.getCurrentUser()).pipe(switchMap((user: User | null) => {
        if (!user) {
          throw new HttpErrorResponse({
            error: 'Sie wurden automatisch abgemeldet.',
            status: 403,
            statusText: 'Automatische Abmeldung',
            url: request.url
          });
        }

        return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
      }));
    }

    return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if ((err.status === 401 || err.status === 403) && !err.url?.includes("/auth/")) {
      this.authService.logoutUser();
      this.router.navigateByUrl(`/login`);
    } else if (err.status === 0) {
      return throwError(() => {
        return {
          error: 'Das MedienCenter ist aktuell nicht erreichbar. Bitte versuchen Sie es später erneut.',
        }
      });
    }

    return throwError(() => err);
  }
}
