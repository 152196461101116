<div class="row">
    <div class="col-6">
        <h3>{{ "Template.DatastreamsComponent.subHeader_manageDatastreams" | translate }}</h3>
    </div>
    <div class="col-6 text-end">
        <a class="btn btn-secondary float-end" (click)="getDataStreamAnalysis()">
            <i class="fas fa-cloud-download-alt"></i>
            {{ "Template.DatastreamsComponent.btn_datastreamEvaluation" | translate }}
        </a>
    </div>
</div>


<table *ngIf="dataStreams" class="table table-striped table-hover">
    <thead>
        <tr>
            <th width="30%">{{ "Template.DatastreamsComponent.text_dataSupplier" | translate }}</th>
            <th width="15%" class="text-center" scope="col">
                {{ "Template.DatastreamsComponent.text_state" | translate }}
                <a (click)="sortDataStreamsByVersionDate()">
                    <fa-icon class="" icon="sort"></fa-icon>
                </a>
            </th>
            <th width="15%" class="text-center" scope="col">
                {{ "Template.DatastreamsComponent.text_dataSource" | translate }}
                <a (click)="sortDataStreamsByDataSource()">
                    <fa-icon class="" icon="sort"></fa-icon>
                </a>
            </th>
            <th width="10%" class="text-center" scope="col">
                {{ "Template.DatastreamsComponent.text_products" | translate }}
                <a (click)="sortDataStreamsByProductCount()">
                    <fa-icon class="" icon="sort"></fa-icon>
                </a>
            </th>
            <th width="10%" class="text-center" scope="col">
                {{ "Template.DatastreamsComponent.text_enabled" | translate }}
                <a (click)="sortDataStreamsByEnabled()">
                    <fa-icon class="" icon="sort"></fa-icon>
                </a>
            </th>
            <th width="10%" class="text-center" scope="col">{{ "Template.DatastreamsComponent.text_logo" | translate }}</th>
            <th width="10%" class="text-center" scope="col">{{ "Template.DatastreamsComponent.text_retrievable" | translate }}</th>
        </tr>
    </thead>
    <tbody>

        <tr *ngFor="let dataStream of dataStreams">
            <input type="hidden" name="dataStreamIds[]" value="{{dataStream.id}}" />
            <td class="align-middle">
                <span *ngIf="dataStream.supplier && dataStream.supplier.name">
                    {{dataStream.supplier.name}}
                </span>
                <small class="d-block mb-1">
                    <span *ngIf="dataStream.supplier && dataStream.supplier.identifications">
                        {{dataStream.supplier.identifications}}
                    </span>
                </small>

                <a *ngIf="dataStream.supplierPartyId" [routerLink]="['/app', 'admin', 'suppliers', dataStream.supplierPartyId]"
                    class="btn btn-link btn-sm">
                    {{ "Template.DatastreamsComponent.btn_details" | translate }}
                    <fa-icon icon="chevron-right"></fa-icon>
                </a>
            </td>
            <td class="align-middle text-center">
                <p *ngIf="dataStream.versionDate">
                    {{dataStream.versionDate| date: 'dd.MM.yyyy'}}
                </p>
                <p *ngIf="!dataStream.versionDate">
                    ---
                </p>
            </td>
            <td class="align-middle text-center">
                <!-- <p *ngIf="dataStream.dataSourceName">
                    {{dataStream.dataSourceName}}
                </p>
                <p *ngIf="!dataStream.dataSourceName">
                    ---
                </p> -->
            </td>
            <td class="align-middle text-center">
                {{dataStream.productCount}}
            </td>
            <td class="align-middle text-center">
                <fa-icon *ngIf="dataStream.enabled && dataStream.enabled" icon="check" class="text-success">
                </fa-icon>
                <fa-icon *ngIf="!dataStream.enabled || !dataStream.enabled" icon="xmark" class="text-danger">
                </fa-icon>
            </td>
            <td class="align-middle text-center">
                <div class="logo-image">
                    <button *ngIf="dataStream.logoImageUrl" type="button" class="btn btn-link"
                        data-toggle="popover" data-trigger="hover" data-placement="top" data-html="true"
                        data-content="todo: Logo-Content">
                        <fa-icon icon="check" class="text-success"></fa-icon>
                    </button>
                    <button *ngIf="!dataStream.logoImageUrl" type="button" class="btn btn-link"
                        data-toggle="popover" data-trigger="hover" data-placement="top"
                        data-content="Kein Logo hochgeladen">
                        <fa-icon icon="xmark" class="text-danger"></fa-icon>
                    </button>
                </div>
            </td>
            <td class="align-middle text-center">
                <!-- <#if Static["org.ofbiz.ekintegration.data.EkDataWorker"].isDataStreamManagedByVendor(delegator, dataStream.dataStreamId)>-->
                <div *ngIf="dataStream.managedBySupplier">
                    <!--  <#if dataStream.activeLogins?has_content && dataStream.activeLogins?size gt 0> -->
                    <div *ngIf="dataStream.accesses">
                        <!-- <#if dataStream.openAccess=="Y">
                                                <button *ngIf="dataStream.openAccess=='Y'" type="button" class="btn btn-link" data-toggle="popover" <i
                                                    class="fas fa-handshake text-success"></i>
                                                </button>
                                                <#else>
                                                    <button *ngIf="dataStream.openAccess!='Y'" type="button" class="btn btn-link" data-toggle="popover" <i
                                                        class="fas fa-handshake text-warning"></i>
                                                    </button>
                                            </#if> -->
                    </div>
                    <div *ngIf="!dataStream.accesses">
                        <button type="button" class="btn btn-link" data-toggle="popover" data-trigger="hover" data-placement="top"
                            data-content="Keine Zugänge vergeben oder aktiviert (ZR-Datenstream)">
                            <fa-icon icon="times" class="text-danger"></fa-icon>
                        </button>
                    </div>
                    </div>
                    <div *ngIf="!dataStream.managedBySupplier">
                        <button type="button" class="btn btn-link" data-toggle="popover" data-trigger="hover" data-placement="top"
                            data-content="Daten-Stream ist frei abrufbar">
                                <fa-icon icon="check" class="text-success"></fa-icon>
                                </button>
                </div>
            </td>
        </tr>
    </tbody>
    <caption>{{ "Template.DatastreamsComponent.text_dataStreamsProductsDisabledProducts" | translate: {'qtyDatastreams':
        dataStreams.length, 'qtyProducts': productCount, 'qtyDisabledProducts': disabledProductCount } }}
    </caption>
</table>