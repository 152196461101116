<table class="table table-striped">
    <thead>
        <tr>
            <th scope="col" class="col-md-2 col-sm-1">{{ "Template.DataCatalogsComponent.label_dataCatalogId" |
                translate }}</th>
            <th scope="col" class="col-md-2 col-sm-1">{{ "Template.DataCatalogsComponent.label_name" | translate }}</th>
            <th scope="col" class="col-md-2 col-sm-1">{{ "Template.DataCatalogsComponent.label_supplierParty" |
                translate }}</th>
            <th scope="col" class="col-md-1 col-sm-1">{{ "Template.DataCatalogsComponent.label_enabled" | translate }}
            </th>
            <th scope="col" class="col-md-2 col-sm-1">{{ "Template.DataCatalogsComponent.label_sourceFilename" |
                translate }}
            </th>
            <th scope="col" class="col-md-2">&nbsp;</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let dataCatalog of dataCatalogs">
            <td> {{ dataCatalog.dataCatalogId }} </td>
            <td> {{ dataCatalog.name }} </td>
            <td> {{ dataCatalog.supplierPartyId }} </td>
            <td>
                <fa-icon *ngIf="dataCatalog.enabled && dataCatalog.enabled==true" icon="check"></fa-icon>
                <fa-icon *ngIf="dataCatalog.enabled && dataCatalog.enabled!=true" icon="xmark"></fa-icon>
            </td>
            <td> {{ dataCatalog.sourceFilename }} </td>
            <td class="text-end align-middle">
                <a [routerLink]="['/app', 'admin', 'data-catalogs', dataCatalog.dataCatalogId]"
                    class="btn btn-secondary">
                    {{ "Template.DataCatalogsComponent.text_details" | translate }}
                    <fa-icon icon="chevron-right"></fa-icon>
                </a>
            </td>
        </tr>
    </tbody>
</table>