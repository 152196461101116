<div class="titlebar">
  <h3>
    {{ "Template.SupplierDetailComponent.subHeader_supplier" | translate }}<span *ngIf="supplier">: {{ supplier.name! }}</span>
  </h3>

  <div class="buttons">
    <button class="btn btn-secondary" [disabled]="loading['supplier']" (click)="reload()">
      <fa-icon icon="arrow-rotate-right"></fa-icon>
    </button>
  </div>
</div>

<fa-icon icon="spinner" [spin]="true" *ngIf="loading['supplier']"></fa-icon>

<div *ngIf="supplier">
  <ul class="nav nav-tabs" ngbNav #tabs="ngbNav">
    <li class="nav-item" ngbNavItem>
      <a class="nav-link" ngbNavLink="#details">{{ "Template.SupplierDetailComponent.text_details" | translate }}</a>

      <ng-template ngbNavContent>
        <div class="tab-pane fade show" id="details" role="tabpanel">
          <h5 class="mb-2">{{ "Template.SupplierDetailComponent.subHeader_generalInformation" | translate }}:</h5>
          <div class="row">
            <div class="col-6 col-xs-12 px-4">
              <div class="row">
                <div class="col-6">
                  <strong>{{ "Template.SupplierDetailComponent.text_gln" | translate }}:</strong>
                </div>
                <div class="col-6">
                  <span *ngIf="supplier.identifications && supplier.identifications['GLN']">{{ supplier.identifications["GLN"].value }}</span>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <strong>{{ "Template.SupplierDetailComponent.text_name" | translate }}:</strong>
                </div>
                <div class="col-6">
                  {{ supplier.name! }}
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <strong>{{ "Template.SupplierDetailComponent.text_enabled" | translate }}:</strong>
                </div>
                <div class="col-6">
                  <span *ngIf="supplier.enabled">{{ "Template.SupplierDetailComponent.text_yes" | translate }}</span>
                  <strong class="text-danger" *ngIf="!supplier.enabled">
                    {{ "Template.SupplierDetailComponent.text_no" | translate }}
                    <fa-icon icon="exclamation-triangle"></fa-icon>
                  </strong>
                </div>
              </div>
            </div>
            <div class="col-6 col-xs-12">
              <button class="btn btn-secondary d-block w-100 mb-2" (click)="changeSupplierName()">
                {{ "Template.SupplierDetailComponent.btn_changeCompanyName" | translate }}
              </button>
              <button class="btn btn-secondary d-block w-100 mb-2" (click)="changeSupplierLogo()">
                {{ "Template.SupplierDetailComponent.btn_changeLogo" | translate }}
              </button>

              <button class="btn btn-warning d-block w-100" *ngIf="supplier.enabled" (click)="toggleSupplier(false)">{{ "Template.SupplierDetailComponent.btn_disable" | translate }}</button>
              <button class="btn btn-success d-block w-100" *ngIf="!supplier.enabled" (click)="toggleSupplier(true)">{{ "Template.SupplierDetailComponent.btn_enable" | translate }}</button>
            </div>
          </div>

          <hr />

          <!-- nested/child component -->
          <app-employees *ngIf="supplier" [isCustomer]="false" [party]="supplier"></app-employees>
        </div>
      </ng-template>
    </li>

    <li class="nav-item" ngbNavItem>
      <a class="nav-link" ngbNavLink="#upload">{{ "Template.SupplierDetailComponent.text_dataManagement" | translate }}</a>
      <ng-template ngbNavContent>
        <div class="tab-pane fade show" id="upload" role="tabpanel">
          <app-data-management *ngIf="id" [partyId]="id"></app-data-management>
        </div>
      </ng-template>
    </li>

    <!-- <li class="nav-item" ngbNavItem>
      <a class="nav-link" ngbNavLink="#accesses">{{ "Template.SupplierDetailComponent.text_dataStream" | translate }}</a>
      <ng-template ngbNavContent>
        <div class="tab-pane fade show" id="accesses" role="tabpanel">
          <h5 class="mb-2">{{ "Template.SupplierDetailComponent.subHeader_approvedCustomers" | translate }}:</h5>
        <app-data-stream-accesses [partyId]="supplier.partyId"></app-data-stream-accesses>
        </div>
      </ng-template>
    </li> -->
    <!-- <li class="nav-item" ngbNavItem>
      <a class="nav-link" ngbNavLink="#accesses">{{ "Template.SupplierDetailComponent.text_connectionToZrBusiness" | translate
        }}</a>
      <ng-template ngbNavContent>
        <div class="tab-pane fade show" id="accesses" role="tabpanel">
          <h5 class="mb-2">...</h5>
        </div>
      </ng-template>
    </li> -->
  </ul>

  <div class="tab-content" [ngbNavOutlet]="tabs"></div>
</div>
