import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';

import { AuthService } from '@services/auth.service';

import { User } from '@models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      return this.authService.getCurrentUser().then((user: (User | null)) => {
        if (!user) {
          // Store the attempted URL for redirecting
          this.authService.redirectUrl = state.url;

          // Navigate to the login page with extras
          this.router.navigate(['/login']);
          return resolve(false);
        }

        if (route.data['roles'] && route.data['roles'].length) {
          if (!user.roles || !user.roles.length || !user.roles.filter((role: string) => {
            return route.data['roles'].indexOf(role) !== -1;
          }).length) {
            this.router.navigate(['/app/main']);

            return resolve(false);
          }
        }

        return resolve(true);
      }).catch((error: any) => {
        return resolve(false);
      });
    });
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

}
