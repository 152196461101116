import { Injectable } from '@angular/core';

import { AuthService } from '@services/auth.service';
import { ErrorService } from '@services/error.service';
import { HttpService } from '@services/http.service';
import { User } from '@models/user';
import { PARTY_RELATIONSHIP_TYPES } from './constants.service';
import { PartyService } from './party.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private errorService: ErrorService,
    private partyService: PartyService
  ) { }

  public updateName(
    firstname: string,
    lastname: string,
    username?: string,
  ): Promise<void> {
    return this.updateUser({
      firstname,
      lastname,
      username,
    });
  }

  public updateEmail(
    email: string,
    username?: number,
  ): Promise<void> {
    return this.updateUser({
      email,
      username,
    });
  }

  public updatePassword(
    password: string,
    username: string,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.updateUser({
        password,
        username
      }).then(() => {
        return resolve();
      }).catch((err) => {
        return reject(err);
      })
    })

  }

  public updateEnabled(
    enabled: boolean,
    username?: string,
  ): Promise<void> {
    return this.updateUser({
      enabled,
      username,
    });
  }

  private updateUser(fields: any): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/users/update`, fields).then((result: any) => {
        return this.authService.refreshUser();
      }).then(() => {
        return resolve();
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public linkUser(fromPartyId: string | undefined, toPartyId: string | undefined): Promise<void> {
    if (!fromPartyId || !toPartyId) {
      return Promise.reject();
    }

    return this.partyService.createPartyRelationship(fromPartyId, toPartyId, PARTY_RELATIONSHIP_TYPES.EMPLOYMENT);    
  }

  public unlinkUser(fromPartyId: string, toPartyId: string): Promise<void> {
    if (!fromPartyId || !toPartyId) {
      return Promise.reject();
    }
    return this.partyService.removePartyRelationship(fromPartyId, toPartyId, PARTY_RELATIONSHIP_TYPES.EMPLOYMENT);
  }

  public deleteUser(username?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/users/delete`, { username }).then((result: any) => {
        return resolve();
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public resetPassword(email?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/users/reset-password`, { email }).then((result: any) => {
        return resolve();
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public updateUserRole(email: string, newRoles: any[]): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/users/add-role`, { email: email, roles: newRoles }).then((response: any) => {
        return resolve(response);
      }).catch((err) => {
        return reject(err);
      })
    })
  }

  public removeUserRole(email: string, rolesToRemove: any[]) {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/users/remove-role`, { email: email, roles: rolesToRemove }).then((response: any) => {
        return resolve(response);
      }).catch((err) => {
        return reject(err);
      })
    })
  }

  getPartyId(user: User | null): string | null {
    if (!user || !user.partyId) {
      return null;
    }

    return user.partyId;
  }

  public createUser(
    firstname: string,
    lastname: string,
    email: string,
    roles: string[],
    employerPartyId: string,
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/users/create`, {
        employerPartyId,
        firstname,
        lastname,
        email,
        roles,
      }).then((user: User) => {
        return resolve(user);      
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public getUserByEmail(email: string): Promise<User | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/users/get`, {
        email,
      }, {
        fullResponse: true,
      }).then((user: User | null) => {
        if (user) {
          return resolve(user);
        }
        return resolve(null);
      }).catch((error: any) => {
        if (error && error.status && error.status == 404) {
          return resolve(null);
        }

        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public checkPassword(oldPassword: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/users/check-password`, { password: oldPassword }).then((result: boolean) => {
        return resolve(result);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public getUser(username: string): Promise<User> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/users/get`, { username }, {
        fullResponse: true,
      }).then((user: User) => {
        return resolve(user);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

}
