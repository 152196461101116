import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { DataCatalogStatus } from '@models/data-catalog-status';

@Component({
  selector: 'app-datamanagement-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class DataManagementStatusComponent implements OnInit {

  @Input() partyId: string | null = null;
  @Input() dataCatalogStatus: DataCatalogStatus | null = null;
  @Input() mediaItemsByStatus: any | null = null;

  constructor(
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.cdRef.detectChanges();
  }

}
