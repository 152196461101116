<ng-template #modalContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <button type="button" class="btn-close" (click)="dismiss()"></button>
  </div>
  <div class="modal-body">
    <div [innerHTML]="body"></div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="dismiss()">
      {{ "Common.btn_cancel" | translate }}
    </button>

    <button type="button" *ngFor="let button of buttons" class="btn" [ngClass]="'btn-' + button.color" (click)="close(button.index)">
      {{button.text}}
    </button>
  </div>
</ng-template>