<div class="titlebar">
  <h3>Impressum</h3>
</div>

<p>
  <strong>EK servicegroup eG</strong><br />
  Elpke 109<br />
  33605 Bielefeld<br />
  Fax: +49 (0) 521 / 2092 - 166<br />
  E-Mail: <a href="mailto:info@ek-retail.com">info@ek-retail.com</a>
</p>
<br />
<h5>Datenschutz</h5>
<p>
  Vorsitzender des Aufsichtsrates: Hannes Versloot
  <br>
  Vorstand: Martin Richrath (Vorsitzender), Frank Duijst, Gertjo Janssen, Jochen Pohle
  <br>
  EK servicegroup eG, Sitz: Bielefeld
  <br>
  Genossenschaftsregister Nr. 229, Amtsgericht Bielefeld
  <br>
  Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: DE124003623
</p>
<br>
<h5>Haftungshinweis</h5>
<p>
  Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
</p>
<br>
<h5>Copyright und Bildrechte</h5>
<p>
  Alle Inhalte dieser Seite unterliegen dem Urheberrecht.
</p>
<p>
  <strong>Eine Nutzung der Inhalte ist nur nach vorheriger, expliziter Zustimmung des Seitenbetreibers zulässig!</strong> Dies gilt insbesondere für die Bilddaten im Katalog und im Content-Bereich.
</p>
<p>
  Die Nutzung der Bilder und /oder Artikeltexte auf Portalen wie Amazon oder ebay kann zu empfindlichen Abmahnungen führen!
</p>
<br>
<h5>Sicherheit / Datenschutz</h5>
<p>
  Ihre persönlichen Daten behandeln wir selbstverständlich vertraulich, sie werden nur für unsere Kunden- und Interessentendatei verwendet. Darüber hinaus werden Ihre Adressdaten nur intern zu Werbungs- und Marktforschungszwecken genutzt.
</p>