import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@services/auth.service';

import { ROLES } from '@services/constants.service';

import { User } from '@models/user';
import { Party } from '@models/party';
import { ErrorService } from '@services/error.service';
import { environment } from '@envs/environment';
import { EmployerService } from '@services/employer.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {

  private currentEmployerParty: Party | null = null;
  private passwordChangeModalRef: NgbModalRef | null = null;
  public error = "";
  public username: string = "";

  @ViewChild('changePasswordModal')
  templateRef!: TemplateRef<any>;

  constructor(
    private router: Router,
    private authService: AuthService,
    private employerService: EmployerService,
    private errorService: ErrorService,
    private modalService: NgbModal
  ) { }

  ngAfterViewInit(): void {
    this.checkUserPasswordChangeRequired();
  }

  ngOnInit(): void {
    this.routeByEmployerParty();    
  }

  private routeByEmployerParty() {
    this.employerService.getCurrentEmployerParty$().subscribe((employerParty: Party | null) => {
      if (employerParty && employerParty.identifier) {
        // if the party is explicitly new, navigate
        if ((!this.currentEmployerParty) || (this.currentEmployerParty && this.currentEmployerParty.partyId != employerParty.partyId)) {
          this.navigateByIdentifier(employerParty.identifier);
        } else {
          // do nothing, because parties are equal
          if (!environment.production) {
            console.log("no routing, because parties are equal");
          }
        }
        this.currentEmployerParty = employerParty;
      } else {
        this.errorService.printError("party is null or no party identifier set");
      }
    })
  }

  private navigateByIdentifier(identifier: string) {
    if (identifier == "EK_ROOT") {
      this.router.navigate(['/app/admin/']);
    } else if (identifier == "EK_RETAIL_SUPPLIER") {
      this.router.navigate(['/app/suppliers/']);
    } else if (identifier == "EK_RETAIL_CUSTOMER") {
      this.router.navigate(['/app/customers/']);
    }
  }

  private routeByUser() {
    this.authService.getCurrentUser().then((user: (User | null)) => {
      if (user && user.roles) {
        if (user.roles && user.roles.includes(ROLES.ADMIN)) {
          this.router.navigate(['/app/admin']);
        } else if (user.roles && user.roles.includes(ROLES.SUPPLIER)) {
          this.router.navigate(['/app/suppliers/']);
        } else if (user.roles && user.roles.includes(ROLES.CUSTOMER)) {
          this.router.navigate(['/app/customers/']);
        } else {
          this.authService.logoutUser().then(() => {
            this.router.navigate(['/login/']);
          }).catch(() => {
            this.router.navigate(['/login/']);
          });
        }
      }
    }).catch(() => {
      this.router.navigate(['/login/']);
    });
  }

  private checkUserPasswordChangeRequired() {
    let options: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    }

    this.authService.getCurrentUser$().subscribe((user: User | null) => {
      if (user != null && user.requirePasswordChange && user.username != this.username) {
        // navigate or show modal
        this.username = user.username;
        if (!this.modalService.hasOpenModals()) {
          setTimeout(() => {
            this.passwordChangeModalRef = this.modalService.open(this.templateRef, options);
          })
        }
      }
    })
  }

  public exitPasswordForm(event: boolean) {
    this.modalService.dismissAll();
  }

}
