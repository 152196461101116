import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import './global';
import { AppRoutingModule } from './app-routing.module';
import { EkHttpInterceptor } from './interceptors/http.interceptor';
import { AppComponent } from './app.component';
import { ImprintComponent } from './extras/imprint/imprint.component';
import { PrivacyComponent } from './extras/privacy/privacy.component';
import { LoginComponent } from './extras/login/login.component';
import { ContainerComponent } from './pages/container/container.component';
import { MainComponent } from './common/main/main.component';
import { AdminMainComponent } from './pages/admin/main/main.component';
import { CustomersComponent } from './pages/admin/customers/customers.component';
import { SettingsComponent } from './common/settings/settings.component';
import { CustomerDetailComponent } from './pages/admin/customers/customer-detail/customer-detail.component';
import { GenericModalComponent } from './components/modals/generic-modal/generic-modal.component';
import { PromptModalComponent } from './components/modals/prompt-modal/prompt-modal.component';
import { SuppliersComponent } from './pages/admin/suppliers/suppliers.component';
import { SupplierDetailComponent } from './pages/admin/suppliers/supplier-detail/supplier-detail.component';
import { EmployeesComponent } from './components/employees/employees.component';
import { DataStreamsComponent } from './pages/admin/data/data-streams/data-streams.component';
import { EmailsComponent } from './pages/admin/emails/emails.component';
import { DataStreamPreviewComponent } from './pages/admin/customers/data-stream-preview/data-stream-preview.component';
import { DataStreamOverviewComponent } from './pages/admin/customers/customer-detail/data-stream-overview/data-stream-overview.component';
import { DataStreamAccessesComponent } from './pages/admin/suppliers/supplier-detail/data-stream-accesses/data-stream-accesses.component';
import { MediaSearchComponent } from './pages/admin/data/media-search/media-search.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JobsComponent } from './pages/admin/jobs/jobs.component';
import { DataCatalogsComponent } from './pages/admin/data/data-catalogs/data-catalogs.component';
import { DataCatalogDetailComponent } from './pages/admin/data/data-catalogs/data-catalog-detail/data-catalog-detail.component';
import { SupplierProductsOverviewComponent } from './pages/admin/data/data-catalogs/data-catalog-detail/supplier-products-overview/supplier-products-overview.component';
import { SupplierProductDetailComponent } from './pages/admin/data/data-catalogs/data-catalog-detail/supplier-products-overview/supplier-product-detail/supplier-product-detail.component';
import { SupplierProductSearchComponent } from './pages/admin/data/supplier-product-search/supplier-product-search.component';
import { RecoverPasswordComponent } from './extras/recover-password/recover-password.component';
import { CreateUserFormComponent } from './pages/admin/users/create-user-form/create-user-form.component';
import { ApiKeysComponent } from './components/api-keys/api-keys.component';
import { CockpitComponent } from './pages/supplier/cockpit/cockpit.component';
import { PasswordChangeFormComponent } from './components/password-change-form/password-change-form.component';
import { DataManagementComponent } from './components/data-management/data-management.component';
import { DataManagementStatusComponent } from '@components/data-management/status/status.component';
import { DataManagementUploadComponent } from '@components/data-management/upload/upload.component';
import { DataManagementFtpComponent } from './components/data-management/ftp/ftp.component';
import { DataManagementActionsComponent } from '@components/data-management/actions/actions.component';
import { MessagesComponent } from './components/data-management/upload/messages/messages.component';
import { EmployeeComponent } from './components/employees/employee/employee.component';
import { MessagesNavComponent } from './components/data-management/upload/messages-nav/messages-nav.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';

registerLocaleData(localeDe);

export function HttpLoaderFactory(httpHandler: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(httpHandler));
}

@NgModule({
  declarations: [
    AppComponent,
    ImprintComponent,
    PrivacyComponent,
    LoginComponent,
    ContainerComponent,
    MainComponent,
    AdminMainComponent,
    CustomersComponent,
    SettingsComponent,
    CustomerDetailComponent,
    GenericModalComponent,
    PromptModalComponent,
    SuppliersComponent,
    SupplierDetailComponent,
    EmployeesComponent,
    DataStreamsComponent,
    EmailsComponent,
    DataStreamPreviewComponent,
    DataStreamOverviewComponent,
    DataStreamAccessesComponent,
    MediaSearchComponent,
    JobsComponent,
    DataCatalogsComponent,
    DataCatalogDetailComponent,
    SupplierProductsOverviewComponent,
    SupplierProductDetailComponent,
    SupplierProductSearchComponent,
    RecoverPasswordComponent,
    CreateUserFormComponent,
    ApiKeysComponent,
    CockpitComponent,
    PasswordChangeFormComponent,
    DataManagementComponent,
    DataManagementStatusComponent,
    DataManagementUploadComponent,
    DataManagementActionsComponent,
    DataManagementFtpComponent,
    MessagesComponent,
    EmployeeComponent,
    MessagesNavComponent,
    PasswordStrengthComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      },
      defaultLanguage: 'de'
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: EkHttpInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'de-DE' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}