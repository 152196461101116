<div class="upload-response alert alert-warning" *ngIf="dataCatalogStatus && dataCatalogStatus.messages?.length || mediaItemsByStatus?.['NOT_FOUND']?.length || mediaItemsByStatus?.['INVALID']?.length">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
      <li [ngbNavItem]="1" *ngIf="dataCatalogStatus && dataCatalogStatus.messages?.length">
        <a ngbNavLink>
          <strong>{{ translatedErrorsLength }}</strong>
          {{ "Template.UploadComponent.text_errors" | translate}}
        </a>
        <!-- <ng-template ngbNavContent [ngIf]="errors"> -->
        <ng-template ngbNavContent>
          <app-messages [messages]="errors" (translatedEvent)="setTranslatedErrors($event)" ></app-messages>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" *ngIf="dataCatalogStatus?.messages?.length">
        <a ngbNavLink>
          <strong>{{ translatedWarningsLength }}</strong>
          {{ "Template.UploadComponent.text_warnings" | translate}}
        </a>
        <!-- <ng-template ngbNavContent [ngIf]="warnings"> -->
        <ng-template ngbNavContent>
          <app-messages [messages]="warnings" (translatedEvent)="setTranslatedWarnings($event)" ></app-messages>
        </ng-template>
      </li>
      <li [ngbNavItem]="3" *ngIf="mediaItemsByStatus?.['NOT_FOUND']?.length">
        <a ngbNavLink>
          <strong>{{ mediaItemsByStatus["NOT_FOUND"]?.length }}</strong>
          {{ "Template.UploadComponent.text_missingMedia" | translate}}
        </a>
        <ng-template ngbNavContent>
          <ul>
            <li *ngFor="let mediaItem of mediaItemsByStatus['NOT_FOUND']">
              <span *ngIf="mediaItem.originalFilename">{{ mediaItem.originalFilename }}</span>
              <span *ngIf="mediaItem.originalUrl">{{ mediaItem.originalUrl }}</span>
              <span *ngIf="mediaItem.message?.ean && mediaItem.message?.supplierArticleNo"> ({{mediaItem.message?.ean}} / {{mediaItem.message?.supplierArticleNo}})</span>
              <span *ngIf="mediaItem.message?.messageCode">: {{"MediaItemMessages." + mediaItem.message?.messageCode | translate}}</span>
            </li>
          </ul>
        </ng-template>
      </li>
      <li [ngbNavItem]="4" *ngIf="mediaItemsByStatus?.['INVALID']?.length">
        <a ngbNavLink>
          <strong>{{ mediaItemsByStatus["INVALID"]?.length }}</strong>
          {{ "Template.UploadComponent.text_invalidMedia" | translate}}
        </a>
        <ng-template ngbNavContent>
          <ul>
            <li *ngFor="let mediaItem of mediaItemsByStatus['INVALID']">
              <span *ngIf="mediaItem.originalFilename">{{ mediaItem.originalFilename }}</span>
              <span *ngIf="mediaItem.originalUrl">{{ mediaItem.originalUrl }}</span>
              <span *ngIf="mediaItem.message?.ean && mediaItem.message?.supplierArticleNo"> ({{mediaItem.message?.ean}} / {{mediaItem.message?.supplierArticleNo}})</span>
              <span *ngIf="mediaItem.message?.messageCode">: {{"MediaItemMessages." + mediaItem.message?.messageCode | translate}}</span>
            </li>
          </ul>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
