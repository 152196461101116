import { Injectable } from '@angular/core';
import { Supplier } from '@models/supplier';
import { AuthService } from './auth.service';
import { ErrorService } from './error.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private errorService: ErrorService
  ) { }

  public getSuppliers(): Promise<Supplier[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/suppliers/getAll`).then((response) => {
        return resolve(response);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject();
      });
    });
  }

  public getSupplier(id: string | null): Promise<Supplier> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/suppliers/get`, {
        partyId: id,
      }).then((response: Supplier) => {
        return resolve(response);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject();
      });
    });
  }

  public createSupplier(
    name: string,
    gln: string,
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/suppliers/create`, {
        gln,
        name,
      }).then((result: any) => {
        return resolve(result.partyId);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public getSupplierEmployees(partyId: string): Promise<string[]> {
    let employees: string[] = [];
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/suppliers/getEmployees`, { partyId }).then((result: string[]) => {
        if (result) {
          for (const employee of result) {
            if (employee) {
              employees.push(employee);
            }
          }
        }
        return this.authService.refreshUser();
      }).then(() => {
        return resolve(employees);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

}
