import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { DialogService } from '@services/dialog.service';
import { LangService } from '@services/lang.service';

import { User } from '@models/user';

import { ROLES } from '@services/constants.service';
import { TranslateService } from '@ngx-translate/core';
import { Party } from '@models/party';
import { PartyService } from '@services/party.service';
import { ErrorService } from '@services/error.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public currentUser: User | null = null;

  public nameForm: FormGroup;
  public emailForm: FormGroup;

  public nameError: string = '';
  public emailError: string = '';
  public showPasswordForm: boolean = false;
  public adminError: string = '';

  public nameLoading: boolean = false;
  public emailLoading: boolean = false;
  public adminLoading: boolean = false;

  public defaultFormValues: any = {};
  private adminModal: NgbModalRef | null = null;
  private currentLanguage: string;
  public selectedValue: string = '';
  public partyId: string | null =  null;

  public userProps = { employerPartyId: "", employeeRoles: [ROLES.ADMIN] };

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private authService: AuthService,
    private userService: UserService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private langService: LangService,
    private partyService: PartyService,
    private errorService: ErrorService
  ) {
    this.nameForm = this.formBuilder.group({
      firstname: ['',
        Validators.compose([Validators.required])
      ],
      lastname: ['',
        Validators.compose([Validators.required])
      ],
      editing: [false],
    });

    this.emailForm = this.formBuilder.group({
      email: ['',
        Validators.compose([Validators.required, Validators.email])
      ],
      editing: [false],
    });

    this.currentLanguage = this.translateService.currentLang;
  }

  ngOnInit(): void {
    this.authService.getCurrentUser$().subscribe((currentUser: User | null) => {
      if (currentUser) {
        this.currentUser = currentUser;

        this.nameForm.controls['firstname'].patchValue(currentUser.firstname);
        this.nameForm.controls['lastname'].patchValue(currentUser.lastname);
        this.emailForm.controls['email'].patchValue(currentUser.email);

        this.defaultFormValues = {
          ...currentUser
        }
      }

      // from the settingsPage, the only user to be created is an admin user
      // (admin user identifies by having an EMPLOYMENT relationship to EK Root party)
      this.partyService.getRootParty().then((party: Party | null) => {
        if (party) {
          this.userProps.employerPartyId = party.partyId;
        }
      }).catch((err) => {
        this.errorService.printError(err);
      })

    });

    this.getValueForDropDown().then((value: string) => {
      this.selectedValue = value;
    });
  }

  public updateName() {
    this.nameLoading = true;
    this.nameError = '';

    this.userService.updateName(
      this.nameForm.controls['firstname'].value,
      this.nameForm.controls['lastname'].value
    ).then(() => {
      this.nameError = '';
      this.nameLoading = false;

      this.showForm(this.nameForm);
    }).catch((error: any) => {
      this.nameLoading = false;
      this.nameError = error || this.translateService.instant("Dialog.SettingsComponent.msg_errorWhileSavingName");
    })
  }

  public updateEmail() {
    this.emailLoading = true;
    this.emailError = '';

    this.userService.updateEmail(
      this.emailForm.controls['email'].value,
    ).then(() => {
      this.emailError = '';
      this.emailLoading = false;

      this.showForm(this.emailForm);
    }).catch((error: any) => {
      this.emailLoading = false;
      this.emailError = error || this.translateService.instant("Dialog.SettingsComponent.msg_errorWhileSavingEmail");
    })
  }

  public togglePasswordForm() {
    this.showPasswordForm = !this.showPasswordForm;
  }

  public exitPasswordForm(event: boolean) {
    if (event) {
      this.showPasswordForm = false;
    } else {
      this.dialogService.alert("", this.translateService.instant("Dialog.SettingsComponent.msg_errorWhileSavingPassword"));
    }
  }

  public showForm(form: FormGroup) {
    if (form.controls['editing']) {
      if (form.controls['editing'].value) {
        form.controls['editing'].patchValue(false);

        Object.keys(form.controls).forEach((fieldName: string) => {
          form.controls[fieldName].patchValue(this.defaultFormValues[fieldName] || '');
        })
      } else {
        form.controls['editing'].patchValue(true);
      }
    }
  }

  public openAdminModal(modal: any) {
    this.adminModal = this.modalService.open(modal);

    this.adminModal.result.then(() => {
      this.adminError = '';
    });
  }

  onLangChange(selectedValue: string) {
    this.currentLanguage = selectedValue;
    if (this.currentUser) {
      this.partyId = this.userService.getPartyId(this.currentUser);
      if (this.partyId) {
        this.userProps.employerPartyId = this.partyId;
      }
      this.langService.setLangAttribute(this.partyId || '', this.currentLanguage || '');
      this.langService.setLanguage(this.currentLanguage);

    } else {
      console.error('either currentUser or currentLanguage is null.');
    }
  }


  // TODO: when refreshing the Setting page, an empty value by the language dropdown-menu appears

  getValueForDropDown (): any {
    return Promise.resolve(this.langService.getCurrentLanguage());
  }

  public exitUserForm(event: boolean) {
    this.adminModal?.close();
    if (event == true) {
      this.dialogService.alert(
        null,
        this.translateService.instant("Dialog.SettingsComponent.msg_adminCreated", { email: "" })
      );
    }
  }

}
