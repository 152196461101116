<div class="row">
  <div class="col-7 col-xs-12 px-4">
    <app-datamanagement-status [partyId]="partyId" [dataCatalogStatus]="dataCatalogStatus" [mediaItemsByStatus]="mediaItemsByStatus"></app-datamanagement-status>
  </div>

  <div class="col-5 col-xs-12">
    <app-datamanagement-actions [partyId]="partyId" [dataCatalogStatus]="dataCatalogStatus"></app-datamanagement-actions>
  </div>
</div>

<hr />

<app-datamanagement-upload [partyId]="partyId" [dataCatalogStatus]="dataCatalogStatus" [mediaItemsByStatus]="mediaItemsByStatus"></app-datamanagement-upload>

<app-datamanagement-ftp></app-datamanagement-ftp>
