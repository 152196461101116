import { Component, Input, OnInit } from '@angular/core';
import { DataStream } from '@models/data-stream';
import { DataStreamGroup } from '@models/data-stream-group';
import { DataStreamService } from '@services/data-stream.service';
import { ErrorService } from '@services/error.service';
import { HttpService } from '@services/http.service';

@Component({
  selector: 'app-data-stream-overview',
  templateUrl: './data-stream-overview.component.html',
  styleUrls: ['./data-stream-overview.component.scss']
})
export class DataStreamOverviewComponent implements OnInit {
  public _dataStreamGroup!: DataStreamGroup;
  @Input()
  public set dataStreamGroup(dataStreamGroup: DataStreamGroup) {
    this._dataStreamGroup = dataStreamGroup;
  }

  public get dataStreamGroup() {
    return this._dataStreamGroup;
  }

  public dataStreams: DataStream[] = [];

  constructor(
    private httpService: HttpService,
    private errorService: ErrorService,
    private dataStreamService: DataStreamService
  ) { }

  ngOnInit(): void {
    this.dataStreamService.requestDataStreams(this.dataStreamGroup.id).then((dataStreams: DataStream[]) => {
      this.dataStreams = dataStreams;
    });
  }

}
