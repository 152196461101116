<div class="titlebar">
  <h3>{{ "Template.CommonMainComponent.subHeader_EkMedienCenter" | translate }}</h3>
</div>
<fa-icon icon="spinner" [spin]="true"></fa-icon>

<ng-template #changePasswordModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ "Template.CommonMainComponent.subHeader_changePassword" | translate }}
      </h4>
      <!-- No close button so user is forced to change his password -->
      <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button> -->
    </div>
    <div class="modal-body">
      
      <div>
        <app-password-change-form [username]="username" (exitFormEvent)="exitPasswordForm($event)"></app-password-change-form>
      </div>
  
      <p class="alert alert-danger text-center mt-3" *ngIf="error">
        <strong>{{error}}</strong>
      </p>
    </div>
  </div>
</ng-template>