import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IDataCatalogMessage } from '@app/interfaces/idata-catalog-message';
import { DataCatalogStatus } from '@models/data-catalog-status';
import { MessageService } from '@services/message.service';

@Component({
  selector: 'app-messages-nav',
  templateUrl: './messages-nav.component.html',
  styleUrls: ['./messages-nav.component.scss']
})
export class MessagesNavComponent implements OnInit, OnChanges {
  @Input() dataCatalogStatus: DataCatalogStatus | null = null;
  @Input() mediaItemsByStatus: any | null = null;

  public errors: Map<number, IDataCatalogMessage[]> = new Map<number, IDataCatalogMessage[]>();
  public warnings: Map<number, IDataCatalogMessage[]> = new Map<number, IDataCatalogMessage[]>();
  public translatedWarningsLength: number = 0;
  public translatedErrorsLength: number = 0;

  constructor(
    private messageService: MessageService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataCatalogStatus && this.dataCatalogStatus.messages) {
      this.seperateMessages(this.dataCatalogStatus.messages);
    }
  }

  ngOnInit(): void { }

  private seperateMessages(messages: any[]) {
    if (!messages) {
      return;
    }

    let tmpErrors = messages.filter((item: any) => item.level === 'ERROR');
    let tmpWarnings = messages.filter((item: any) => item.level === 'WARNING');

    // get the length of every messages collection according to line number
    // sort messages by line number and column number
    tmpErrors.sort(this.messageService.sortMessages);
    tmpWarnings.sort(this.messageService.sortMessages);
    this.errors = this.messageService.createMessageMap(tmpErrors);
    this.warnings = this.messageService.createMessageMap(tmpWarnings);
    this.translatedErrorsLength = this.errors.size;
    this.translatedWarningsLength = this.warnings.size;
  }

  public setTranslatedErrors(event: number) {
    this.translatedErrorsLength = event;
    this.cdRef.detectChanges();
  }

  public setTranslatedWarnings(event: number) {
    this.translatedWarningsLength = event;
    this.cdRef.detectChanges();
  }

}
