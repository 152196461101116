import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, of, switchMap, timer } from 'rxjs';

import { DialogService } from '@services/dialog.service';
import { PartyService } from '@services/party.service';
import { ProductService } from '@services/product.service';

import { DataCatalogStatus } from '@models/data-catalog-status';
import { ErrorService } from '@services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-datamanagement-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class DataManagementUploadComponent implements OnInit {

  @Input() partyId: string | null = null;
  @Input() dataCatalogStatus: DataCatalogStatus | null = null;
  @Input() mediaItemsByStatus: any | null = null;

  public file: File | null = null;
  public fileUploaded: boolean = false;
  public isButtonDisabled: boolean = false;

  private sub: Subscription | null = null;

  // ViewChild is used to access the input element. 
  // this inputVar is a reference to our input. 
  @ViewChild("formFile") inputVar: ElementRef | undefined;

  constructor(
    private partyService: PartyService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private productService: ProductService,
    private cdRef: ChangeDetectorRef,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.checkStatus();
  }

  public onFileSelected(event: any) {
    this.file = event.target.files[0];
  }

  public postFile() {
    let file = this.file;
    if (file && this.partyId) {
      this.setButtonDisabled();
      this.fileUploaded = true;

      this.dialogService.showLoader("fileUpload", this.translateService.instant("Dialog.FileUploadFormComponent.msg_uploadingFile"));
      this.partyService.postFile(file, this.partyId).then(() => {
        this.dialogService.dismissLoader("fileUpload");
        this.file = null;
        this.fileUploaded = false;
        this.dialogService.alert(null, this.translateService.instant("Dialog.FileUploadFormComponent.msg_fileUploadSuccess"));

        if (this.inputVar) {
          this.inputVar.nativeElement.value = "";
        }
      }).catch((err: HttpErrorResponse) => {
        this.errorService.printError(err);
        this.fileUploaded = false;
        this.dialogService.dismissLoader("fileUpload");

        if (err.status === 400) {
          this.dialogService.alert(null, this.translateService.instant("DataCatalogStatusMessages." + err.error), 'danger', 0);
        } else {
          this.dialogService.alert(null, this.translateService.instant("Dialog.FileUploadFormComponent.msg_errFileUpload", { filename: file?.name }), 'danger', 0);
        }
      })
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  private setButtonDisabled() {
    if (this.dataCatalogStatus && !(this.dataCatalogStatus.status == 'PRISTINE' || this.dataCatalogStatus.status == 'READY')) {
      this.isButtonDisabled = true;
      this.cdRef.detectChanges();
    }
  }

  private setButtonEnabled() {
    this.isButtonDisabled = true;
    this.cdRef.detectChanges();
  }

  private async checkStatus() {
    // set the button enabled when the progress reaches 100%
    if (this.dataCatalogStatus && this.dataCatalogStatus.status == 'READY') {
      this.setButtonEnabled();
    }
  }

}
