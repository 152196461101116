import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@services/dialog.service';
import { HttpService } from '@services/http.service';
import { Location } from '@angular/common'; 
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '@services/product.service';

export enum imageStatus {
  new = "NEW",
  notFound = "NOT_FOUND",
  invalid = "INVALID",
  corrupted = "CORRUPTED",
  downloaded = "DOWNLOADED",
  scaled = "SCALED",
  stale = "STALE"
}

@Component({
  selector: 'app-data-catalog-detail',
  templateUrl: './data-catalog-detail.component.html',
  styleUrls: ['./data-catalog-detail.component.scss']
})
export class DataCatalogDetailComponent implements OnInit {
  public id: string | null = null;
  public dataCatalog: any;
  public productQuantity: string | null = null;
  public newImagesQty: number | null = null;
  public notFoundImagesQty: number | null = null;
  public invalidImagesQty: number | null = null;
  public corruptedImagesQty: number | null = null;
  public downloadedImagesQty: number | null = null;
  public scaledImagesQty: number | null = null;
  public staleImagesQty: number | null = null;
  public loading: boolean = false;
  public eImageStatus = imageStatus;

  constructor(
    public activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private vref: ViewContainerRef,
    private dialogService: DialogService,
    private router: Router,
    private location: Location,
    private translateService: TranslateService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.reload();
  }

  public reload() {
    this.fetchDataCatalog(this.id).then((result: any) => {
      if (result && result != null) {
        this.dataCatalog = result;
      }
    })

    this.productService.fetchQuantityOfProducts(this.id).then((result: string) => {
      this.productQuantity = result;
    })

    this.fetchQuantityOfImages(this.id, imageStatus.new).then((result: number) => {
      this.newImagesQty = result;
    })

    this.fetchQuantityOfImages(this.id, imageStatus.notFound).then((result: number) => {
      this.notFoundImagesQty = result;
    })

    this.fetchQuantityOfImages(this.id, imageStatus.invalid).then((result: number) => {
      this.invalidImagesQty = result;
    })

    this.fetchQuantityOfImages(this.id, imageStatus.corrupted).then((result: number) => {
      this.corruptedImagesQty = result;
    })

    this.fetchQuantityOfImages(this.id, imageStatus.downloaded).then((result: number) => {
      this.downloadedImagesQty = result;
    })

    this.fetchQuantityOfImages(this.id, imageStatus.scaled).then((result: number) => {
      this.scaledImagesQty = result;
    })

    this.fetchQuantityOfImages(this.id, imageStatus.stale).then((result: number) => {
      this.staleImagesQty = result;
    })
  }

  public addFragmentToUrl(fragment: string) {
    // regular expression selects the part of the string beginning with '#' or the end of the string
    let newUrl = (this.router.url).replace(new RegExp('(#.*)|$'), '#' + fragment);
    this.location.go(newUrl);
  }

  private fetchDataCatalog(id: string | null): Promise<any | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/data-catalogs/get`, { id }).then((response: any) => {
        if (response) {
          return resolve(response);
        }

        return resolve(null);
      })
    })
  }

  public toggleDataCatalog(enabled: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/data-catalogs/toggleEnabled`, { id: this.id }).then((response: any) => {
        if (response && response != null) {
          this.dataCatalog = response;
        }
        return this.dialogService.alert("", this.translateService.instant('Dialog.DataCatalogDetailComponent.msg_enabledDisabledDataCatalog'), 'success', 0, false);
      }).then(() => {
        return resolve();
      }).catch(() => {
        this.dialogService.alert("", this.translateService.instant('Dialog.DataCatalogDetailComponent.msg_errEnabledDisabledDataCatalog'), 'danger', 0, false);
        return reject();
      })
    })
  }

  public removeSupplierProducts() {
    this.productService.removeSupplierProducts(this.id);
  }

  private fetchQuantityOfImages(dataCatalogId: string | null, status: string): Promise<number> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/data-catalogs/countImagesByDataCatalogIdAndStatus`, { id: dataCatalogId, status: status }).then((response: any) => {
        if (response || response === 0) {
          return resolve(response);
        } else {
          return Promise.reject();
        }
      }).catch(() => {
        return reject();
      })
    })
  }

  public navigateToSupplier(supplierPartyId: string) {
    this.router.navigate(['app', 'admin', 'suppliers', supplierPartyId]);
  }

}
