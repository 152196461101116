import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ApikeyService {

  constructor(
    private httpService: HttpService,
    private errorService: ErrorService
  ) { }

  public getApiKeys(username: string): Promise<any[] | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(("**/apiKeys/get"), { username: username }).then((apiKeys: any[]) => {
        if (apiKeys && apiKeys.length > 0) {
          return resolve(apiKeys);
        }
        return resolve(null);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject(err);
      })
    })
  }

  public addApiKey(username: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(("**/apiKeys/create"), { username: username }).then((plaintext: string) => {
        return resolve(plaintext);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject(err);
      })
    })
  }

  public disableApiKey(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(("**/apiKeys/disable"), { apiKeyId: id }).then(() => {
        return resolve();
      }).catch((err) => {
        return reject(err);
      })
    })
  }
}
