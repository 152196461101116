import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from '@services/user.service';
import { CustomValidationService } from '@services/custom-validation.service';
import { ErrorService } from '@services/error.service';
import { DialogService } from '@services/dialog.service';

@Component({
  selector: 'app-password-change-form',
  templateUrl: './password-change-form.component.html',
  styleUrls: ['./password-change-form.component.scss'],
})
export class PasswordChangeFormComponent implements OnInit {
  @Input('username') username: string = "";
  @Output() exitFormEvent = new EventEmitter<true>();

  public passwordForm: FormGroup;
  public loadingSave: boolean = false;
  public newPassword: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private customValidator: CustomValidationService,
  ) {
    this.passwordForm = this.formBuilder.group({
      oldPassword: ['', {
        validators: [Validators.required],
        asyncValidators: [this.customValidator.oldPasswordValid('oldPassword')]
      }],
      newPassword: ['', {
        validators: [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*[A-Z])(?=.*[!@#$&*\.])(?=.*[0-9])(?=.*[a-z]).{8,}$')]
      }],
      newPasswordVerify: ['', {
        validators: [Validators.required],
        asyncValidators: [this.customValidator.matchPassword('newPassword', 'newPasswordVerify')]
      }],
    });
  }

  ngOnInit(): void {
  }

  public onSubmit(event: any) {
    this.loadingSave = true;
    if (event.submitter.name == "" && this.passwordForm.status == "VALID") {
      this.changePassword(
        this.passwordForm.controls['newPassword'].value
      ).then(() => {
        this.loadingSave = false;
        console.log('EXIT FORM');
        this.exitForm();

        this.dialogService.alert(null, this.translateService.instant('Template.PasswordChangeComponent.label_passwordChangeSuccess'), 'success');
      }).catch((err) => {
        this.loadingSave = false;
        
        this.dialogService.alert(null, this.translateService.instant('Template.PasswordChangeComponent.label_passwordChangeError'), 'danger');
        
        this.errorService.printError(err);
      })
    }
  }

  private changePassword(newPassword: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.userService.updatePassword(newPassword, this.username).then(() => {
        return resolve();
      }).catch((err) => {
        return reject(err);
      })
    })
  }

  private exitForm() {
    this.exitFormEvent.emit(true);
  }

  public onNewPasswordInput() {
    this.newPassword = this.passwordForm.controls['newPassword'].value;
  }

}
