import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@envs/environment.production';
import { User } from '@models/user';
import { TranslateService } from '@ngx-translate/core';
import { PARTY_ATTRIBUTE_TYPES, PARTY_RELATIONSHIP_TYPES } from '@services/constants.service';
import { DialogService } from '@services/dialog.service';
import { ErrorService } from '@services/error.service';
import { PartyService } from '@services/party.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-create-user-form',
  templateUrl: './create-user-form.component.html',
  styleUrls: ['./create-user-form.component.scss']
})
export class CreateUserFormComponent implements OnInit {
  @Input('userProps') userProps = { employerPartyId: "", employeeRoles: new Array<any>() };
  @Output() exitFormEvent = new EventEmitter<true>();

  public error: string = '';
  public loadingCreate: boolean = false;

  public userForm: FormGroup;
  public userExists: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private errorService: ErrorService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private partyService: PartyService
  ) {
    if (!environment.production) {
      this.userForm = this.formBuilder.group({
        employerPartyId: [this.userProps.employerPartyId, Validators.required],
        firstname: ['Max',
          Validators.compose([Validators.required])
        ],
        lastname: ['Mustermann',
          Validators.compose([Validators.required])
        ],
        email: ['test@ek-retail.com',
          Validators.compose([Validators.required, Validators.email])
        ],
      });
    } else {
      this.userForm = this.formBuilder.group({
        employerPartyId: [this.userProps.employerPartyId, Validators.required],
        firstname: ['',
          Validators.compose([Validators.required])
        ],
        lastname: ['',
          Validators.compose([Validators.required])
        ],
        email: ['',
          Validators.compose([Validators.required, Validators.email])
        ],
      });
    }
  }

  ngOnInit(): void {
    // employerPartyId is undefined at constructor time
    this.userForm.controls['employerPartyId'].setValue(this.userProps.employerPartyId);
  }

  public linkUser() {
    if (this.userExists) {
      this.createRelationship().then(() => {
        this.dialogService.alert("", this.translateService.instant("Dialog.CreateUserFormComponent.msg_userLinkSuccess"));
        return this.userService.updateUserRole(this.userForm.controls['email'].value, this.userProps.employeeRoles);
      }).then(() => {
        this.exitForm();        
      }).catch((err) => {
        this.errorService.printError(err);
      })
    } else {
      this.errorService.printError("User actually exists, but var is not set.")
    }
  }

  private createUser() {
    this.loadingCreate = true;

    this.userService.createUser(
      this.userForm.controls['firstname'].value,
      this.userForm.controls['lastname'].value,
      this.userForm.controls['email'].value,
      this.userProps.employeeRoles,
      this.userForm.controls['employerPartyId'].value,
    ).then((user: User) => {
      if (user && user.partyId && this.userProps && this.userProps.employerPartyId) {
        return this.partyService.setAttribute(user.partyId, PARTY_ATTRIBUTE_TYPES.LAST_SELECTED_PARTYID, this.userProps.employerPartyId);
      }

      return Promise.reject("User was not created successfully.");
    }).then(() => {
      this.dialogService.alert("", this.translateService.instant("Dialog.CreateUserFormComponent.msg_UserCreatedSuccess"));
      this.exitForm();

      // this.router.navigate(['/app', 'admin', 'suppliers', this.userForm.controls['employerPartyId'].value]);
    }).catch((error: string) => {
      this.error = error || this.translateService.instant("Dialog.CreateUserFormComponent.msg_userNotCreated");
      this.dialogService.alert("", this.translateService.instant("Dialog.CreateUserFormComponent.msg_userNotCreated"));
      this.errorService.printError(error);
      this.loadingCreate = false;
    });
  }

  private createRelationship(): Promise<void> {
    let email = this.userForm.controls['email'].value;
    return new Promise((resolve, reject) => {
      return this.userService.getUserByEmail(email).then((user: User | null) => {
        if (user && user.partyId) {
          return this.partyService.createPartyRelationship(this.userForm.controls['employerPartyId'].value, user.partyId, PARTY_RELATIONSHIP_TYPES.EMPLOYMENT);
        }
        return reject();
      }).then(() => {
        return resolve();
      }).catch((err) => {
        this.errorService.printError(err);
        return reject(err);
      })
    })
  }

  public checkIfUserExists() {
    this.loadingCreate = true;

    this.userService.getUserByEmail(this.userForm.controls['email'].value).then((user: User | null) => {
      if (user) {
        this.userExists = true;
        this.loadingCreate = false;
      } else {
        this.createUser();
      }
    }).catch((error: string) => {
      this.error = error || this.translateService.instant("Dialog.CreateUserFormComponent.msg_userNotCreated");
      this.errorService.printError(error);
      this.loadingCreate = false;
    });
  }

  private exitForm() {
    this.exitFormEvent.emit(true);
  }

}
