import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CustomValidationService {

  constructor(
    private userService: UserService
  ) { }

  public matchPassword(password: string, confirmPassword: string): AsyncValidatorFn {
    const test: AsyncValidatorFn = async (control: AbstractControl): Promise<ValidationErrors | null> => {
      if (!control || !control.parent) {
        return { internalError: true };
      }
      const passwordControl = control.parent.get(password);
      const confirmPasswordControl = control.parent.get(confirmPassword);

      if (!passwordControl || !confirmPasswordControl) {
        return { internalError: true };
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
        return { passwordMismatch: true };
      } else {
        confirmPasswordControl.setErrors(null);
        return null;
      }
    };

    return test;
  }

  public oldPasswordValid(oldPassword: string): AsyncValidatorFn {
    const test: AsyncValidatorFn = async (control: AbstractControl): Promise<ValidationErrors | null> => {
      if (!control || !control.parent) {
        return { internalError: true };
      }
      const oldPasswordControl = control.parent.get(oldPassword);

      if (!oldPasswordControl) {
        return { internalError: true };
      }

      // if (oldPasswordControl.errors && !oldPasswordControl.errors['oldPasswordInvalid']) {
      //   return null;
      // }

      return this.userService.checkPassword(oldPasswordControl.value).then((valid: boolean) => {
        if (!valid) {
          oldPasswordControl.setErrors({ oldPasswordInvalid: true });
          return { oldPasswordInvalid: true };
        } else {
          oldPasswordControl.setErrors(null);
          return null;
        }
      })
    };

    return test;
  }
}
