<div class="titlebar">
  <h3>{{ "Template.MainComponent.subHeader_adminHomepage" | translate }}</h3>
</div>

<div *ngIf="unavailable">
  <div class="alert alert-danger">
    <fa-icon icon="triangle-exclamation" size="3x"></fa-icon>
    <span>{{ "Template.MainComponent.msg_mediaCenterUnavailable" | translate }}</span>
  </div>
</div>

<div>
  <div class="row">
    <div class="col-md-6 col-xs-12">
      <h5>{{ "Template.MainComponent.label_sftpCredentials" | translate }}</h5>
      <div *ngIf="!loaded['FTP_ACCOUNTS']">
        <fa-icon icon="spinner" [spin]="true" *ngIf="loaded"></fa-icon>
      </div>

      <div *ngIf="loaded['FTP_ACCOUNTS'] && sftpAccounts && sftpAccounts.length">
        <div *ngFor="let account of sftpAccounts">
          <table class="table">
            <tbody>
              <tr>
                <th scope="row">{{ "Template.MainComponent.label_host" | translate }}:</th>
                <td>{{ account.host! }}</td>
              </tr>
              <tr>
                <th scope="row">{{ "Template.MainComponent.label_port" | translate }}:</th>
                <td>{{ account.port! }}</td>
              </tr>
              <tr>
                <th scope="row">{{ "Template.MainComponent.label_username" | translate }}:</th>
                <td>{{ account.username! }}</td>
              </tr>
              <tr>
                <th scope="row" class="border-bottom-0">{{ "Template.MainComponent.label_password" | translate }}:</th>
                <td class="border-bottom-0">{{ account.password! }}</td>
              </tr>
            </tbody>
          </table>

          <hr />
        </div>
      </div>
    </div>

    <div class="col-md-6 col-xs-12">
      <h5>{{ "Template.MainComponent.label_systemInformation" | translate }}</h5>
      <div *ngIf="!loaded['SYSTEM_INFORMATION']">
        <fa-icon icon="spinner" [spin]="true" *ngIf="loaded"></fa-icon>
      </div>

      <div *ngIf="loaded['SYSTEM_INFORMATION'] && systemInformation">
        <h6>{{ "Template.MainComponent.label_sftpStatus" | translate }}</h6>
        <table class="table">
          <tbody>
            <tr>
              <th scope="row">{{ "Template.MainComponent.label_host" | translate }}:</th>
              <td>{{ systemInformation.sftHost! }}</td>
            </tr>
            <tr>
              <th scope="row">{{ "Template.MainComponent.label_port" | translate }}:</th>
              <td>{{ systemInformation.sftpPort! }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
