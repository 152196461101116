import { Injectable } from '@angular/core';
import { IDataStreamAccess } from '@app/interfaces/idata-stream-access';
import { DataStream } from '@models/data-stream';
import { DataStreamGroup } from '@models/data-stream-group';
import { Supplier } from '@models/supplier';
import { ErrorService } from './error.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DataStreamService {

  constructor(
    private httpService: HttpService,
    private errorService: ErrorService
  ) { }

  public getAccessibleDataStreams(dataStreamGroupId: string) {
    // TODO:
    // get dataStreamGroup
    // get dataStreamGroupAccess[] using dataStreamGroupId

    // get dataStreamAccess using partyId from DataStreamGroupAccess
    // get dataStream[] using dataStreamId from dataStreamAccess
    //
  }

  public requestAllDataStreams(): Promise<DataStream[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get('**/data-streams/getAll').then((dataStreams: DataStream[]) => {
        return resolve(dataStreams);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      })
    })
  }

  public requestDataStreamGroups(): Promise<DataStreamGroup[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get('**/data-stream-groups/getAll').then((dataStreamGroups: DataStreamGroup[]) => {
        if (!dataStreamGroups) {
          return reject();
        }
        return resolve(dataStreamGroups);
      }).catch((error) => {
        this.errorService.printError(error);
        return reject();
      })
    })
  }

  public requestDataStreamSupplier(dataStreamId: string): Promise<Supplier | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get('**/data-streams/getDataStreamSupplier', { dataStreamId }).then((supplier: Supplier) => {
        if (supplier) {
          return resolve(supplier);
        }
        return resolve(null);
      }).catch((err) => {
        this.errorService.printError(err);
      })
    })
  }

  public requestDataStreams(dataStreamGroupId: string): Promise<DataStream[]> {
    let dataStreams: DataStream[] = [];
    return new Promise((resolve, reject) => {
      return this.httpService.get('**/data-streams/getByDataStreamGroupId', { dataStreamGroupId }).then((response: DataStream[]) => {
        if (response) {
          response.forEach(async (dataStream) => {
            let supplier: Supplier | null = await this.requestDataStreamSupplier(dataStream.id);
            if (supplier) {
              dataStream.supplier = supplier;
            }
            dataStreams.push(dataStream);
          })
        }
        return resolve(dataStreams);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      })
    })
  }

  public getAccesses(dataStreamId: string): Promise<IDataStreamAccess[] | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get("**/data-streams/get-accesses", { dataStreamId: dataStreamId }).then((dataStreamAccess: IDataStreamAccess[]) => {
        if (dataStreamAccess) {
          return resolve(dataStreamAccess);
        }
        return resolve(null);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      })
    })
  }

  public getDataStreamByParty(partyId: string): Promise<DataStream | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get("**/data-streams/getDataStreamByParty", { partyId: partyId }).then((dataStreams: DataStream[]) => {
        if (dataStreams && dataStreams.length == 1) {
          return resolve(dataStreams[0]);
        }
        return resolve(null);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      })
    })
  }

  public getDataStreamGroups(): Promise<DataStreamGroup[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/data-stream-groups/getAll`).then((result: any) => {
        return resolve(result);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public getDataStreamGroupAccesses(employerPartyId: string | undefined): Promise<string[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/data-stream-groups/get-accesses`, {
        employerPartyId,
      }).then((result: any) => {
        return resolve(result);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    });
  }

  public setDataStreamGroupAccesses(employerPartyId: string | undefined, dataStreamGroupIds: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/data-stream-groups/set-accesses`, {
        employerPartyId,
        dataStreamGroupIds,
      }).then((result: any) => {
        return resolve();
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    });
  }
}
