<div class="row">
  <div class="col-xs-212 col-sm-8">
    <img src="/assets/images/brand/title.png" class="img-fluid img-thumbnail p-0 shadow" />
  </div>
  <div class="col-xs-12 col-sm-4 mx-auto">
    <img src="/assets/images/brand/logo_claim.png" class="img-fluid mt-3 mb-5" />

    <h3>EK MedienCenter: Anmeldung</h3>
    <form (ngSubmit)="loginUser()" [formGroup]="loginForm" novalidate>
      <div class="form-group">
        <label for="username">E-Mail-Adresse</label>
        <input type="text" class="form-control" id="username" placeholder="Benutzername" formControlName="username" autocomplete="username" required>
        <small *ngIf="!loginForm.controls['username'].valid && !loginForm.controls['username'].untouched" class="text-danger">
          Bitte geben Sie Ihre E-Mail-Adresse ein.
        </small>
      </div>
      <div class="form-group">
        <label for="password">Passwort</label>
        <input type="password" class="form-control" id="password" placeholder="Passwort" formControlName="password" autocomplete="current-password" required>
        <small *ngIf="!loginForm.controls['password'].valid && !loginForm.controls['password'].untouched" class="text-danger">
          Bitte geben Sie Ihr Passwort ein.
        </small>
      </div>

      <button type="submit" class="btn btn-secondary d-block w-100" [disabled]="isLoading || !loginForm.valid">
        Anmelden
        <fa-icon *ngIf="isLoading" icon="spinner" [spin]="true" class="ml-1"></fa-icon>
      </button>
    </form>

    <div class="alert alert-danger mt-3" *ngIf="error && !isLoading">
      {{error}}
    </div>

    <p class="text-center mt-4">
      <a class="btn btn-outline-danger btn-sm" href="recover-password">
        Passwort vergessen?
      </a>
    </p>
  </div>
</div>