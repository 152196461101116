import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from '@services/user.service';
import { DialogService } from '@services/dialog.service';
import { ErrorService } from '@services/error.service';
import { PartyService } from '@services/party.service';
import { CustomerService } from '@services/customer.service';
import { SupplierService } from '@services/supplier.service';

import { PARTY_ATTRIBUTE_TYPES, ROLES } from '@services/constants.service';

import { Customer } from '@models/customer';
import { Supplier } from '@models/supplier';
import { User } from '@models/user';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

  @Input() isCustomer: boolean = false; // true -> Customer, false -> Supplier
  @Input() party: Customer | Supplier | null = null;

  public employees: string[] = [];
  public defaultFormValues: any = {};
  public loadingEmployees: boolean = false;

  private LOADER_NAME = "employee";

  constructor(
    private viewContainerRef: ViewContainerRef,
    private customerService: CustomerService,
    private supplierService: SupplierService,
    private userService: UserService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private translateService: TranslateService,
    private partyService: PartyService
  ) { }

  ngOnInit(): void {
    this.reload();
  }

  public reload(): Promise<void> {
    let party = this.party;
    return new Promise((resolve, reject) => {
      if (party != null) {
        this.defaultFormValues = party;
        this.loadEmployees(party).then((employees: string[]) => {
          this.employees = employees;

          return resolve();
        }).catch(() => {
          return reject();
        })
      }
    })
  }

  private loadEmployees(party: Customer | Supplier): Promise<string[]> {
    this.loadingEmployees = true;
    let partyId: string = party.partyId;
    if (!partyId) {
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
      if (this.isCustomer) {
        return this.customerService.getCustomerEmployees(partyId).then((employees: string[]) => {
          this.loadingEmployees = false;
          return resolve(employees);
        })
      } else {
        return this.supplierService.getSupplierEmployees(partyId).then((employees: string[]) => {
          this.loadingEmployees = false;
          return resolve(employees);
        })
      }
    })
  }

  public showLoader(event: boolean) {
    if (event == true) {
      this.dialogService.showLoader(`${this.LOADER_NAME}`);
    } else {
      this.dialogService.dismissLoader(`${this.LOADER_NAME}`);
    }
  }

  private updateUser(user: User, roles: string[]): Promise<void> {
    let existingUser: User = user;

    return new Promise((resolve, reject) => {
      return this.dialogService.confirm(
        this.translateService.instant('Dialog.EmployeesComponent.text_addingUser'),
        this.translateService.instant('Dialog.EmployeesComponent.text_addingUserToParty'),
        this.viewContainerRef
      ).then(() => {
        this.dialogService.showLoader(`${this.LOADER_NAME}.createUser`);
        // this.loadingEvent.emit(true);
        if (this.party != null) {
          return this.userService.linkUser(this.party.partyId, existingUser.partyId);
        }
        return reject();
      }).then(() => {
        if (existingUser.email) {
          return this.userService.updateUserRole(existingUser.email, roles);
        }
        return Promise.reject("Role could not be added, because user has no email.");
      }).then(() => {
        return resolve();
      }).catch((err) => {
        return reject(err);
      })
    })
  }

  public createOrUpdateUser() {
    let emailInput: string;
    let roles: string[];

    let showError: boolean = false;

    if (this.isCustomer) {
      roles = [ROLES.CUSTOMER];
    } else {
      roles = [ROLES.SUPPLIER];
    }

    this.dialogService.prompt(
      this.translateService.instant('Dialog.EmployeesComponent.text_createUser'),
      '',
      'email',
      '',
      this.viewContainerRef,
      this.translateService.instant('Dialog.EmployeesComponent.label_email')
    ).then((email: string | number | null) => {
      if (typeof email !== 'string') {
        return Promise.reject();
      }

      this.dialogService.showLoader(`${this.LOADER_NAME}.createUser`);
      emailInput = email;
      return this.userService.getUserByEmail(email);
    }).then((user: User | null) => {
      this.dialogService.dismissLoader(`${this.LOADER_NAME}.createUser`);
      showError = true;

      if (!user) {
        return this.createUser(emailInput, roles);
      } else {
        return this.updateUser(user, roles);
      }
    }).then(() => {
      this.dialogService.dismissLoader(`${this.LOADER_NAME}.createUser`);

      this.dialogService.alert(null, this.translateService.instant('Dialog.EmployeesComponent.text_userCreated'), 'success');
      this.reload();
    }).catch((error: string) => {
      this.dialogService.dismissLoader(`${this.LOADER_NAME}.createUser`);

      if (showError) {
        this.dialogService.alert(null, error || this.translateService.instant('Dialog.EmployeesComponent.text_errUserNotCreated'), 'danger');
      }
    });
  }

  public createUser(emailInput: string, roles: string[]): Promise<void> {
    if (!this.party || !this.party.partyId) {
      return Promise.reject("Party is empty.");
    }

    return new Promise((resolve, reject) => {
      return this.dialogService.promptMultiple(
        this.translateService.instant('Dialog.EmployeesComponent.text_createUser'),
        this.translateService.instant('Dialog.EmployeesComponent.text_enterName'),
        [
          {
            name: 'firstname',
            label: this.translateService.instant('Dialog.EmployeesComponent.label_firstName'),
            type: 'text',
            required: true,
          },
          {
            name: 'lastname',
            label: this.translateService.instant('Dialog.EmployeesComponent.label_lastName'),
            type: 'text',
            required: true,
          },
        ],
        this.viewContainerRef
      ).then((inputs: any) => {
        this.dialogService.showLoader(`${this.LOADER_NAME}.createUser`);

        if (Object.keys(inputs).length === 0) {
          return Promise.reject();
        }

        return this.userService.createUser(
          inputs.firstname,
          inputs.lastname,
          emailInput,
          roles,
          this.party!.partyId
        );
      }).then((user: User) => {
        if (this.party && this.party.partyId && user && user.partyId) {
          return this.partyService.setAttribute(user.partyId, PARTY_ATTRIBUTE_TYPES.LAST_SELECTED_PARTYID, this.party.partyId);
        }

        return Promise.resolve(null);
      }).then(() => {        
        return resolve();
      }).catch((err) => {
        this.errorService.printError(err);
        return reject(err);
      })
    })
  }

}
