<div class="titlebar">
    <h3>
        {{ "Template.SupplierProductDetailComponent.subHeader_supplierProduct" | translate }}
    </h3>

    <div class="buttons">
        <button class="btn btn-secondary" [disabled]="loading" (click)="reload()">
            <fa-icon icon="arrow-rotate-right"></fa-icon>
        </button>
    </div>
</div>

<fa-icon icon="spinner" [spin]="true" *ngIf="loading"></fa-icon>

<div *ngIf="supplierProduct">

    <div class="tab-pane fade show" id="details" role="tabpanel">
        <h5 class="mb-2">{{ "Template.SupplierProductDetailComponent.subHeader_details" | translate }}</h5>
        <div class="row">
            <div class="row">

                <div class="col-6 content">
                    <div class="row border-bottom">
                        <div class="col-6">
                            <strong>{{ "Template.SupplierProductDetailComponent.label_id" | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <span *ngIf="supplierProduct.id">{{ supplierProduct.id }}</span>
                        </div>
                    </div>

                    <div class="row border-bottom">
                        <div class="col-6">
                            <strong>{{ "Template.SupplierProductDetailComponent.label_dataCatalog" | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <a *ngIf="supplierProduct.dataCatalogId" (click)="navigateToDataCatalog(supplierProduct.dataCatalogId)">
                                {{ supplierProduct.dataCatalogId }}
                            </a>
                        </div>
                    </div>
    
                    <div class="row border-bottom">
                        <div class="col-6">
                            <strong>{{ "Template.SupplierProductDetailComponent.label_description" | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <span *ngIf="supplierProduct.description">{{ supplierProduct.description }}</span>
                        </div>
                    </div>
    
                    <div class="row border-bottom">
                        <div class="col-6">
                            <strong>{{ "Template.SupplierProductDetailComponent.label_supplierArticleNo" | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <span *ngIf="supplierProduct.supplierProductId">{{ supplierProduct.supplierProductId }}</span>
                        </div>
                    </div>
    
                    <div class="row border-bottom">
                        <div class="col-6">
                            <strong>{{ "Template.SupplierProductDetailComponent.label_supplier" | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <span *ngIf="supplierProduct.supplierPartyId">{{ supplierProduct.supplierPartyId }}</span>
                        </div>
                    </div>
    
                    <div class="row border-bottom">
                        <div class="col-6">
                            <strong>{{ "Template.SupplierProductDetailComponent.label_furtherIds" | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <ul *ngIf="supplierProduct.identifications" >
                                <li *ngFor="let sp of supplierProduct.identifications">
                                    <span *ngIf="sp.type && sp.value">{{ sp.type }}: {{ sp.value }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <div class="row border-bottom">
                        <div class="col-6">
                            <strong>{{ "Template.SupplierProductDetailComponent.label_linkedProducts" | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <ul *ngIf="supplierProduct.links" >
                                <li *ngFor="let link of supplierProduct.links">
                                    <span *ngIf="link.type && link.supplierProductId">{{ link.type }}: {{ link.supplierProductId }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <div class="row border-bottom">
                        <div class="col-6">
                            <strong>{{ "Template.SupplierProductDetailComponent.label_prices" | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <ul *ngIf="supplierProduct.prices" >
                                <li *ngFor="let price of supplierProduct.prices">
                                    <span *ngIf="price.type && price.value">{{ "Template.SupplierProductDetailComponent.text_" + price.type | translate }}: {{ price.value }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="row border-bottom">
                        <div class="col-6">
                            <strong>{{ "Template.SupplierProductDetailComponent.label_createdDate" | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <span *ngIf="supplierProduct.createdDate">{{ supplierProduct.createdDate | date :"d. MMMM yyyy, h:mm a z" }}</span>
                        </div>
                    </div>

                    <div class="row border-bottom">
                        <div class="col-6">
                            <strong>{{ "Template.SupplierProductDetailComponent.label_lastModifiedDate" | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <span *ngIf="supplierProduct.lastUpdatedDate">{{ supplierProduct.lastUpdatedDate | date :"d. MMMM yyyy, h:mm a z" }}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <strong>{{ "Template.SupplierProductDetailComponent.label_documents" | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <ul *ngIf="documentUrls">
                                <li *ngFor="let documentUrl of documentUrls | keyvalue">
                                    <a [href]="documentUrl.key" target="_blank">{{ documentUrl.value }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <hr />
    
                    <div class="row">
                        <div class="col-12">
                            <strong>{{ "Template.SupplierProductDetailComponent.label_attributes" | translate }}</strong>
                        </div>
                    </div>
                    <div id="attributesList" class="row">
                        <div class="col-12">                            
                            <table class="table table-striped" *ngIf="supplierProduct.attributes">
                                <thead>
                                    <tr>
                                        <th scope="col" class="col-md-2 col-sm-1">
                                            {{ "Template.SupplierProductDetailComponent.label_key" | translate }}
                                        </th>
                                        <th scope="col" class="col-md-2 col-sm-1">
                                            {{ "Template.SupplierProductDetailComponent.label_value" | translate }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let attribute of supplierProduct.attributes | keyvalue">
                                        <td> {{ attribute.key }} </td>
                                        <td> {{ attribute.value }} </td>                                            
                                    </tr>
                                </tbody>
                            </table>                                
                        </div>                        
                    </div>
                </div>
                
                <div class="col-6">
                    <div class="row" *ngFor="let imageUrl of imageUrls | keyvalue">
                        <img [src]="imageUrl.key">
                    </div>
                </div>
            </div>
        </div>

        
    </div>
</div>