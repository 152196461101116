<table *ngIf="dataStreamAccesses.length > 0" class="table table-striped">
    <thead>
        <th width="10%">#</th>
        <th width="45%">{{ "Template.DatastreamAccessesComponent.text_customer" | translate }}</th>
        <th width="20%" class="text-center">{{ "Template.DatastreamAccessesComponent.text_status" | translate }}</th>
        <th width="20%" class="text-center">{{ "Template.DatastreamAccessesComponent.text_details" | translate }}</th>
    </thead>

    <tbody>
        <tr *ngFor="let access of dataStreamAccesses">
            <td class="align-middle">
                <span *ngIf="access.supplier && access.supplier.identifications && access.supplier.identifications['CUSTOMER_NO']">
                    {{ access.supplier.identifications['CUSTOMER_NO'].value }}
                </span>
            </td>
            <td class="align-middle">
                <span *ngIf="access.supplier && access.supplier.name">
                    {{ access.supplier.name }}
                </span>
            </td>
            <td class="align-middle text-center">
                <!-- <#if vendor.dataStream.managedByVendor?has_content && vendor.dataStream.managedByVendor=="Y"> -->

                <button *ngIf="access.statusId && access.statusId=='APPROVED'" class="btn btn-link btn-sm pl-0 py-0"
                    type="button" data-toggle="tooltip" data-placement="top" title="{{ 'Template.DatastreamAccessesComponent.btn_accessAuthorised' | translate:{ dateTime: access.responseDate | date: 'dd.MM.yyyy, HH:mm' } }}">
                    <!-- <i class="fas fa-check text-success"></i> -->
                    <fa-icon class="text-success" icon="check"></fa-icon>
                </button>
                <button *ngIf="access.statusId && access.statusId=='PENDING'" class="btn btn-link btn-sm pl-0 py-0"
                    type="button" data-toggle="tooltip" data-placement="top" title="{{ 'Template.DatastreamAccessesComponent.btn_accessPending' | translate:{ dateTime: access.responseDate | date: 'dd.MM.yyyy, HH:mm' } }}">
                    <!-- <i class="fas fa-clock text-warning"></i> -->
                    <fa-icon class="text-warning" icon="clock"></fa-icon>
                </button>
                <button *ngIf="access.statusId && access.statusId=='DENIED'" class="btn btn-link btn-sm pl-0 py-0"
                    type="button" data-toggle="tooltip" data-placement="top" title="{{ 'Template.DatastreamAccessesComponent.btn_accessDenied' | translate:{ dateTime: access.responseDate | date: 'dd.MM.yyyy, HH:mm' } }}">
                    <!-- <i class="fas fa-times text-danger"></i> -->
                    <fa-icon class="text-danger" icon="times"></fa-icon>
                </button>

                <!-- <#else>
                        <button class="btn btn-link btn-sm pl-0 py-0" type="button" data-toggle="tooltip"
                            data-placement="top" title="Frei abrufbar">
                            <i class="fas fa-check text-success"></i>
                        </button>
                        </#if> -->
            </td>
            <td class="text-center align-middle">
                <!-- <a href="<@ofbizUrl>customerDetail</@ofbizUrl>?partyId=${access.partyId!}" class="btn btn-primary"> -->
                <a class="btn btn-primary">
                    {{ "Template.DatastreamAccessesComponent.text_details" | translate }}
                    <!-- <i class="fas fa-chevron-right"></i> -->
                    <fa-icon icon="chevron-right"></fa-icon>
                </a>
            </td>
        </tr>

    </tbody>
</table>

<p *ngIf="dataStreamAccesses.length == 0">{{ "Template.DatastreamAccessesComponent.text_noCustomersAuthorised" |
    translate }}.</p>