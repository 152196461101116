<div class="row mt-2" *ngIf="employerParty && user">
  <div class="col-xs-12 col-md-12">
    <div class="row">
      <div class="col-12">
        <button type="button" class="btn mx-1 btn-sm" placement="top" ngbTooltip="{{ 'Template.EmployeesComponent.tooltip_showMore' | translate }}" (click)="isCollapsed = !isCollapsed">
          <fa-icon *ngIf="!isCollapsed" icon="chevron-right"></fa-icon>
          <fa-icon *ngIf="isCollapsed" icon="chevron-up"></fa-icon>
        </button>
        <strong>{{ "Template.EmployeesComponent.text_apiKeys" | translate }}</strong>
        <button class="btn btn-secondary mx-1" (click)="addApiKey(user.username)" *ngIf="user && user.username" placement="top" ngbTooltip="{{ 'Template.EmployeesComponent.tooltip_generateApiKey' | translate }}" [disabled]="!employerParty.enabled">
          <fa-icon icon="plus"></fa-icon>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-11">
        <ul class="list-group" [ngbCollapse]="!isCollapsed">
          <li class="list-group-item" *ngFor="let apiKey of apiKeys.get(user.username)">
            <div class="row">
              <div class="col-sm-11 col-md-5">
                <span *ngIf="user && apiKey && apiKey.decryptedApiKey">
                  <input type="password" value="{{ apiKey.decryptedApiKey }}" />
                </span>
                <span *ngIf="user && apiKey && apiKey.apiKeyCreatedDate">
                  {{ "Template.EmployeesComponent.text_created" | translate : { createdDate: apiKey.apiKeyCreatedDate | date : "d. MMMM yyyy, H:mm:ss" } }}
                </span>
                <span *ngIf="user && apiKey && apiKey.apiKeyExpirationDate">
                  <span *ngIf="apiKey.apiKeyExpirationDate <= 9007199254740992">
                    {{ "Template.EmployeesComponent.text_validUntil" | translate : { expirationDate: apiKey.apiKeyExpirationDate | date : "d. MMMM yyyy, H:mm:ss" } }}
                  </span>
                  <span *ngIf="apiKey.apiKeyExpirationDate > 9007199254740992">
                    {{ "Template.EmployeesComponent.text_noExpirationDate" | translate }}
                  </span>
                </span>
              </div>
              <div class="col-sm-1 col-md-7">
                <button type="button" class="btn mx-1 btn-sm btn-outline-danger" placement="top" ngbTooltip="{{ 'Template.EmployeesComponent.tooltip_disableApiKey' | translate }}" (click)="disableApiKey(apiKey)">
                  <fa-icon icon="xmark"></fa-icon>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
