import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ErrorService } from '@services/error.service';

import { Supplier } from '@models/supplier';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@envs/environment.production';
import { ROLES } from '@services/constants.service';
import { SupplierService } from '@services/supplier.service';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {

  public loading: boolean = false;
  public loadingCreate: boolean = false;
  public loadingContinue: boolean = false;
  public createStep: number = 1;

  public suppliers: Supplier[] = [];
  public suppliersCount: number = 0;

  public supplierForm: FormGroup;
  public userProps = { employerPartyId: "", employeeRoles: [ROLES.SUPPLIER] };

  private supplierCreateModal: NgbModalRef | null = null;
  public error: string = '';

  public readonly FINISH: string = "finish";
  public readonly CONTINUE: string = "continue";

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private supplierService: SupplierService,
    private errorService: ErrorService,
    private translateService: TranslateService
  ) {
    if (!environment.production) {
      this.supplierForm = this.formBuilder.group({
        gln: ['400234400209',
          Validators.compose([])
        ],
        companyName: ['Test-Firma',
          Validators.compose([Validators.required])
        ],
      });      
    } else {
      this.supplierForm = this.formBuilder.group({
        gln: ['',
          Validators.compose([])
        ],
        companyName: ['',
          Validators.compose([Validators.required])
        ],
      });
    }
  }

  ngOnInit(): void {
    this.loadSuppliers();
  }

  public loadSuppliers(): Promise<void> {
    this.loading = true;

    return new Promise((resolve, reject) => {
      return this.supplierService.getSuppliers().then((suppliersMap: any) => {
        this.suppliers = suppliersMap.suppliers;
        this.suppliersCount = suppliersMap.count;  
        this.loading = false;

        return resolve();
      }).catch((error: any) => {
        this.loading = false;  
        this.errorService.printError(error);

        return reject(error);
      });
    })
  }

  public createSupplier(): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.supplierService.createSupplier(
        this.supplierForm.controls['companyName'].value,
        this.supplierForm.controls['gln'].value,
      ).then((respEmployerPartyId: string) => {
        if (respEmployerPartyId) {
          this.userProps.employerPartyId = respEmployerPartyId;
          this.supplierForm.reset();
          this.error = '';
        }

        this.loadSuppliers();
        return resolve();
      }).catch((error: string) => {
        this.error = error || this.translateService.instant("Dialog.SuppliersComponent.msg_supplierNotCreated");        

        return reject(error);
      });
    })
  }

  public openModal(modal: any) {
    this.supplierCreateModal = this.modalService.open(modal);
    this.supplierCreateModal.result.then(() => {
      this.resetSupplierForm();
    }, () => {
      this.resetSupplierForm();
    });
  }

  private resetSupplierForm() {
    this.supplierForm.reset();
    this.loadingCreate = false;
    this.error = '';
    this.createStep = 1;
  }

  public exitUserForm(event: boolean) {
    this.modalService.dismissAll();
    if (event == true) {
      this.router.navigate(['/app', 'admin', 'suppliers', this.userProps.employerPartyId]);
    }
  }

  public onSubmit(event: any) {
    if (event.submitter.name == this.CONTINUE) {
      // if button with name 'continue' was pressed
      this.loadingContinue = true;
      this.createSupplier().then(() => {
        this.loadingContinue = false;
        // go to the second step (creation of contact user)
        this.createStep = 2;
      }).catch((err) => {
        this.errorService.printError(err);
        this.loadingContinue = false;
      })
    } else if (event.submitter.name == this.FINISH) {
      // if button with name 'finish' was pressed
      this.loadingCreate = true;
      this.createSupplier().then(() => {
        this.loadingCreate = false;
        // dismiss the modal directly on success
        this.supplierCreateModal?.dismiss();
      }).catch((err) => {
        this.errorService.printError(err);
        this.loadingCreate = false;
      })
    }
  }

}
