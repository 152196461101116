import { Component, OnInit } from '@angular/core';
import { ISupplierProduct } from '@app/interfaces/isupplier-product';
import { environment } from '@envs/environment';
import { ProductService } from '@services/product.service';

@Component({
  selector: 'app-supplier-product-search',
  templateUrl: './supplier-product-search.component.html',
  styleUrls: ['./supplier-product-search.component.scss']
})
export class SupplierProductSearchComponent implements OnInit {

  public supplierProducts: ISupplierProduct[] = [];
  public ean: string = "";

  constructor(
    private productService: ProductService
  ) { }

  ngOnInit(): void { }

  public reload() {
    this.supplierProducts = [];
    this.productService.fetchByIdentification(this.ean).then((result: ISupplierProduct[]) => {
      result.forEach((element) => {
        this.supplierProducts.push(element);
      })
    })
  }

}
