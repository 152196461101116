<div *ngIf="dataStreams && dataStreams.length">
    <table class="table table-striped">
        <thead>
            <th width="60%">{{ "Template.DatastreamOverviewComponent.text_datastream" | translate }}</th>
            <th width="20%" class="text-center">{{ "Template.DatastreamOverviewComponent.text_status" | translate }}</th>
            <th width="20%" class="text-center">{{ "Template.DatastreamOverviewComponent.text_details" | translate }}</th>
        </thead>

        <tbody>
            <tr *ngFor="let dataStream of dataStreams">
                <td class="align-middle">
                    <span *ngIf="dataStream.supplier">{{dataStream.supplier.name}}</span>
                    <br />
                    <small
                        *ngIf="dataStream.supplier && dataStream.supplier.identifications">{{dataStream.supplier.identifications['GLN']}}</small>
                </td>
                <td class="align-middle text-center">
                    <!-- <div *ngIf="dataStream.managedBySupplier">
                        <button class="btn btn-link btn-sm ps-0 py-0" *ngIf="dataStream.statusId=='APPROVED'"
                            placement="top"
                            ngbTooltip="Freigabe genehmigt ({{dataStream.responseDate | date: 'dd.MM.yyyy, HH:mm'}} Uhr)">
                            <fa-icon icon="check" class="text-success"></fa-icon>
                        </button>
                        <button class="btn btn-link btn-sm ps-0 py-0" *ngIf="dataStream.statusId=='PENDING'"
                            placement="top"
                            ngbTooltip="Freigabe ausstehend ({{dataStream.responseDate | date: 'dd.MM.yyyy, HH:mm'}} Uhr)">
                            <fa-icon icon="clock" class="text-warning"></fa-icon>
                        </button>
                        <button class="btn btn-link btn-sm ps-0 py-0" *ngIf="dataStream.statusId=='DENIED'"
                            placement="top"
                            ngbTooltip="Freigabe abgelehnt ({{dataStream.responseDate | date: 'dd.MM.yyyy, HH:mm'}} Uhr)">
                            <fa-icon icon="times" class="text-danger"></fa-icon>
                        </button>
                    </div> -->
                    <!-- <button class="btn btn-link btn-sm ps-0 py-0" *ngIf="!dataStream.managedBySupplier" type="button"
                        placement="top" ngbTooltip="Frei abrufbar">
                        <fa-icon icon="check" class="text-success"></fa-icon>
                    </button> -->
                </td>
                <td class="text-center align-middle">
                    <a [routerLink]="['/app', 'suppliers', dataStream.id]" class="btn btn-primary">
                        {{ "Template.DatastreamOverviewComponent.text_details" | translate }}
                        <fa-icon icon="chevron-right"></fa-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<p *ngIf="!dataStreams || !dataStreams.length" class="px-2">
    {{ "Template.DatastreamOverviewComponent.text_noAccessPermissionsGranted" | translate }}
</p>