import { PARTY_RELATIONSHIP_TYPES } from "@services/constants.service";
import { Party } from "./party";
import { PartyRelationship } from "./party-relationship";
import { User } from "./user";

export class Employee extends Party {
    constructor(partyId: string, user: User) {
        super(partyId);
        this.user = user;
    }
    user: User;
}
