import { Component, OnInit } from '@angular/core';

import { AuthService } from '@services/auth.service';
import { HttpService } from '@services/http.service';
import { ErrorService } from '@services/error.service';

import { User } from '@models/user';

@Component({
  selector: 'app-datamanagement-ftp',
  templateUrl: './ftp.component.html',
  styleUrls: ['./ftp.component.scss']
})
export class DataManagementFtpComponent implements OnInit {

  public currentUser: User | null = null;
  public ftpInfo: any;

  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
    this.authService.getCurrentUser$().subscribe((user: (User | null)) => {
      this.currentUser = user;
    });

    this.getFtpInfo();
  }

  private getFtpInfo() {
    return this.httpService.get(`**/supplier/getFtpInfo`).then((ftpInfo: any) => {
      this.ftpInfo = ftpInfo;
    }).catch((error: any) => {
      this.errorService.printError(error);
    });
  }

  public copyToClipBoard(value: string) {

  }

}
