<ng-template #modalContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <button type="button" class="btn-close" (click)="dismiss()"></button>
  </div>
  <div class="modal-body">
    <div [innerHTML]="body"></div>

    <form [formGroup]="form" novalidate>
      <div class="form-group" *ngFor="let input of inputs">
        <label [for]="'input_' + input.name" *ngIf="input.label">{{input.label}}</label>
        <input [type]="input.type" class="form-control" [id]="'input_' + input.name" [placeholder]="input.label || 'Eingabe'" [formControlName]="input.name">

        <small *ngIf="!form.controls[input.name].valid && !form.controls[input.name].untouched" class="text-danger">
          {{ "Template.PromptModalComponent.msg_fillField" | translate }}.
        </small>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="dismiss()">
      {{ "Common.btn_cancel" | translate }}
    </button>

    <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="!form.valid">
      {{ "Common.btn_confirm" | translate }}
    </button>
  </div>
</ng-template>