<div class="titlebar">
  <h3>Datenschutz</h3>
</div>

<p>
  Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
</p>
<p>
  Ihre Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
</p>
<p>
  Dazu gehören Händlerdaten, Endkundendaten oder Lieferantendaten, sofern diese zur Abwicklung des von Ihnen erteilten Auftrages (z.B. Bestellungen, Endkunden-Belieferungen, Reklamationen, KESS-Aufträge und Single-Sign-On) erforderlich sind.
</p>
<p>
  Die Daten werden nach Durchführung des Auftrages gelöscht.
</p>
<p>
  Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
</p>
<br />
<h5>Hinweis zur Verwendung von Cookies</h5>
<p>
  Auf verschiedenen Seiten und in der EK App verwenden wir Cookies, um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen. Hierbei handelt es sich um kleine Textdateien, die auf Ihrem Rechner abgelegt werden.
</p>
<p>
  Die meisten der von uns verwendeten Cookies werden nach Ende der Browser-Sitzung wieder von Ihrer Festplatte gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Rechner und ermöglichen es uns, Ihren Rechner bei Ihrem nächsten Besuch wieder zu erkennen (sog. dauerhafte Cookies).
</p>
<br />
<h5>Auskunft, Löschung, Sperrung</h5>
<p>
  § 6 Rechte der betroffenen Person
</p>
<p>
  Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte uns gegenüber zu:
</p>
<p>
  <strong>1. Auskunftsrecht</strong>
</p>
<p>
  Sie können von uns eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden. Liegt eine solche Verarbeitung vor, können Sie von uns über folgende Informationen Auskunft verlangen:
  <br /> (1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
  <br /> (2) die Kategorien von personenbezogenen Daten, welche verarbeitet werden
  <br /> (3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;
  <br /> (4) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
  <br /> (5) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch uns oder eines Widerspruchsrechts gegen diese Verarbeitung;
  <br /> (6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
  <br /> (7) alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;
  <br /> (8) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art.22 Abs.1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.
  <br /> Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art.46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
  <br />
  <br />
</p>
<p>
  <strong>2. Recht auf Berichtigung</strong>
</p>
<p>
  Sie haben ein Recht auf Berichtigung und/oder Vervollständigung uns gegenüber, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Wir müssen die Berichtigung unverzüglich vornehmen.
  <br />
  <br />
</p>
<p>
  <strong>3. Recht auf Einschränkung der Verarbeitung</strong>
</p>
<p>
  Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
  <br /> (1) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen Daten für eine Dauer bestreiten, die es uns ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;
  <br /> (2) wenn die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;
  <br /> (3) wenn wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
  <br /> (4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art.21 Abs.1 DSGVO eingelegt haben und noch nicht feststeht, ob die uns zustehenden berechtigten Gründe gegenüber Ihren Gründen überwiegen.
  <br /> Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
  <br /> Wurde die Einschränkung der Verarbeitung nach den oben genannten Voraussetzungen eingeschränkt, werden Sie von uns unterrichtet bevor die Einschränkung aufgehoben wird.
  <br />
  <br />
</p>
<p>
  <strong>4. Recht auf Löschung</strong>
</p>
<p>
  a) Löschungspflicht
  <br /> Sie können von uns verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und wir sind verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
  <br /> (1) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
  <br /> (2) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art.6 Abs.1 lit.a oder Art.9 Abs.2 lit.a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
  <br /> (3) Sie legen gem. Art.21 Abs.1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art.21 Abs.2 DSGVO Widerspruch gegen die Verarbeitung ein.
  <br /> (4) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.
  <br /> (5) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem wir unterliegen.
  <br /> (6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art.8 Abs.1 DSGVO erhoben.
  <br /> b) Information an Dritte
  <br /> Haben wir die Sie betreffenden personenbezogenen Daten öffentlich gemacht und sind wir gem. Art.17 Abs.1 DSGVO zu deren Löschung verpflichtet, so treffen wir unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um die für die Datenverarbeitung Verantwortlichen, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
  <br /> c) Ausnahmen
  <br /> Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist
  <br /> (1) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
  <br /> (2) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem wir unterliegen, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die uns übertragen wurde;
  <br /> (3) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art.9 Abs.2 lit.h und i sowie Art.9 Abs.3 DSGVO;
  <br /> (4) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art.89 Abs.1 DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder
  <br /> (5) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
  <br />
  <br />
</p>
<p>
  <strong>5. Recht auf Unterrichtung</strong>
</p>
<p>
  Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung uns gegenüber geltend gemacht, sind wir verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht uns gegenüber das Recht zu, über diese Empfänger unterrichtet zu werden.
  <br /> 6. Recht auf Datenübertragbarkeit
  <br /> Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung zu übermitteln, sofern
  <br /> (1) die Verarbeitung auf einer Einwilligung gem. Art.6 Abs.1 lit.a DSGVO oder Art.9 Abs.2 lit.a DSGVO oder auf einem Vertrag gem. Art.6 Abs.1 lit.b DSGVO beruht und
  <br /> (2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt. In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von uns einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
  <br /> Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die uns übertragen wurde.
  <br /> Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die uns übertragen wurde.
  <br />
  <br />
</p>
<p>
  <strong>7. Widerspruchsrecht</strong>
</p>
<p>
  Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art.6 Abs.1 lit.e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
  <br /> Wir verarbeiten die Sie betreffenden personenbezogenen Daten dann nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
  <br /> Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
  <br /> Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
  <br /> Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
  <br />
  <br />
</p>
<p>
  <strong>8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</strong>
</p>
Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt
<br />
<br />
<p>
  <strong>9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling</strong>
</p>
<p>
  Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
  <br /> (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und uns erforderlich ist,
  <br /> (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen wir unterliegen, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
  <br /> (3) mit Ihrer ausdrücklichen Einwilligung erfolgt.
  <br /> Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art.9 Abs.1 DSGVO beruhen, sofern nicht Art.9 Abs.2 lit.a oder g gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
  <br /> Hinsichtlich der in (1) und (3) genannten Fälle treffen wir angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren.
  <br />
  <br />
</p>
<p>
  <strong>10. Recht auf Beschwerde bei einer Aufsichtsbehörde</strong>
</p>
<p>
  Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
  <br /> Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art.78 DSGVO.
  <br />
  <br />
  <strong>Verantwortlicher für die Datenverarbeitung:</strong>
  <br /> EK servicegroup eG
  <br /> Elpke 109
  <br /> 33605 Bielefeld
  <br /> Telefon: +49 521 2092-0
  <br />
  <br />
  <strong>Kontaktdaten unseres Datenschutzbeauftragten:</strong>
  <br /> Elpke 109
  <br /> 33605 Bielefeld
  <br /> datenschutz@ek-retail.com
  <br />
  <br />
</p>
<br />
<h5>MATOMO Besucheranalyse</h5>
<p>
  Diese Website benutzt den Open Source Webanalysedienst Matomo. Matomo verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Dazu werden die durch den Cookie erzeugten Informationen über die Benutzung dieser Website auf unserem Server gespeichert. Die IP-Adresse wird vor der Speicherung anonymisiert.
</p>
<p>
  Matomo-Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen.
</p>
<p>
  Die Speicherung von Matomo-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der anonymisierten Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren. Die durch den Cookie erzeugten Informationen über die Benutzung dieser Website werden nicht an Dritte weitergegeben. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
</p>
<p>
  Wenn Sie mit der Speicherung und Nutzung Ihrer Daten nicht einverstanden sind, können Sie die Speicherung und Nutzung hier deaktivieren. In diesem Fall wird in Ihrem Browser ein Opt-Out-Cookie hinterlegt, der verhindert, dass Matomo Nutzungsdaten speichert. Wenn Sie Ihre Cookies löschen, hat dies zur Folge, dass auch das Matomo Opt-Out-Cookie gelöscht wird. Das Opt-Out muss bei einem erneuten Besuch unserer Seite wieder aktiviert werden.
  <br />
  <br />
  <a data-toggle="modal" href="#privacyMatomo">Einstellungen Matomo</a>
</p>

<script id="CookieDeclaration" src="https://consent.cookiebot.com/1316dfb0-6e93-422f-863a-d2f9f75cbe73/cd.js" type="text/javascript" async></script>

<div class="modal fade" id="privacyMatomo" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Matomo Tracking</h5>
        <button type="button" class="close" data-dismiss="modal">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <iframe style="width: 100%;border: none; height: 220px;" src="https://statistik.ek-online.de/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=000000&fontSize=&fontFamily="></iframe>
      </div>
    </div>
  </div>
</div>