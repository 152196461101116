import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ApikeyService } from '@services/apikey.service';
import { ErrorService } from '@services/error.service';
import { DialogService } from '@services/dialog.service';

import { Party } from '@models/party';
import { User } from '@models/user';

@Component({
  selector: 'app-api-keys',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.scss']
})
export class ApiKeysComponent implements OnInit {

  @Input() employerParty: Party | null = null;
  @Input() user: User | null = null;
  @Output() updated = new EventEmitter();

  public isCollapsed: boolean = false;
  public apiKeys = new Map<string, any[] | null>();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private translateService: TranslateService,
    private apiKeyService: ApikeyService,
    private errorService: ErrorService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    if (this.user) {
      this.getApiKeys(this.user.username);
    }
  }

  private getApiKeys(username: string) {
    this.apiKeyService.getApiKeys(username).then((apiKeys: any[] | null) => {
      this.apiKeys.set(username, apiKeys);
    }).catch((err) => {
      this.errorService.printError(err);
    })
  }

  public addApiKey(username: string) {
    this.apiKeyService.addApiKey(username).then((plaintext: string | null) => {
      if (plaintext) {
        this.dialogService.confirm(
          this.translateService.instant('Dialog.EmployeesComponent.text_generatedApiKey'),
          this.translateService.instant('Dialog.EmployeesComponent.text_pleaseSave', { plainApiKey: plaintext }),
          this.viewContainerRef);
      }

      this.updated.emit();
    }).catch((err) => {
      this.errorService.printError(err);
    })
  }

  public disableApiKey(apiKey: any) {
    // apiKey is an object
    this.apiKeyService.disableApiKey(apiKey.id).then(() => {
      this.updated.emit();
    }).catch((err: any) => {
      this.errorService.printError(err);
    })
  }

}
