import { Injectable } from '@angular/core';
import { environment } from '@envs/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  public printError(error: any) {
    if (!environment.production) {
      console.log(error);
    }
  }

  public handleError(error: any) {
    //TODO: handle important errors with a tool
    console.log(error);
  }
}
