import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from '@models/customer';
import { Employee } from '@models/employee';
import { Supplier } from '@models/supplier';
import { User } from '@models/user';
import { TranslateService } from '@ngx-translate/core';
import { ROLES } from '@services/constants.service';
import { CustomerService } from '@services/customer.service';
import { DialogService } from '@services/dialog.service';
import { ErrorService } from '@services/error.service';
import { PartyService } from '@services/party.service';
import { SupplierService } from '@services/supplier.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {
  @Input()
  party!: Customer | Supplier | null;
  @Input()
  username!: string;
  @Input() isCustomer: boolean = false; // true -> Customer, false -> Supplier

  @Output() loadingEvent = new EventEmitter<boolean>();

  public employee: Employee | null = null;
  public employeeForm: FormGroup;
  public defaultFormValues: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private viewContainerRef: ViewContainerRef,
    private customerService: CustomerService,
    private supplierService: SupplierService,
    private userService: UserService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private translateService: TranslateService,
    private partyService: PartyService
  ) {
    this.employeeForm = this.formBuilder.group({
      firstname: [{
        // value: employee.user.firstname,
        value: "",
        disabled: true, // disabled, because otherwise the input field appears
      },
      Validators.compose([Validators.required])
      ],
      lastname: [{
        // value: employee.user.lastname,
        value: "",
        disabled: true,
      },
      Validators.compose([Validators.required])
      ],
      editing: [false],
    });
  }

  ngOnInit(): void {
    this.loadEmployee(this.username);
  }

  private initForm() {
    this.employeeForm.controls['editing'].patchValue(false);
    this.employeeForm.controls['firstname'].disable();
    this.employeeForm.controls['lastname'].disable();
  }

  private loadEmployee(username: string | null): void {
    if (!username) {
      return;
    } else {
      // Load employee from API
      this.userService.getUser(username).then((user) => {
        if (!this.party || (this.party && !this.party.partyId)) {
          return;
        }
        this.employee = new Employee(this.party.partyId, user);
        this.employeeForm.controls['firstname'].setValue(this.employee.user.firstname);
        this.employeeForm.controls['lastname'].setValue(this.employee.user.lastname);
        // this.employeeForm.markAsPristine();
        // this.initForm(party!, employees);
      })

    }
  }

  public async toggleUser(user: User, enable: boolean) {
    if (! await this.dialogService.confirm(
      `${enable ? this.translateService.instant('Dialog.EmployeesComponent.text_enableUser') : this.translateService.instant('Dialog.EmployeesComponent.text_disableUser')}`,
      `${enable ? this.translateService.instant('Dialog.EmployeesComponent.text_confirmEnableUser') : this.translateService.instant('Dialog.EmployeesComponent.text_confirmDisableUser')}`,
      this.viewContainerRef
    )) {
      return;
    }

    this.loadingEvent.emit(true);

    this.userService.updateEnabled(enable, user?.username).then(() => {
      this.dialogService.alert(null, `${enable ? this.translateService.instant('Dialog.EmployeesComponent.text_UserEnabled') : this.translateService.instant('Dialog.EmployeesComponent.text_UserDisabled')}`, 'success');
      this.reload();
      this.loadingEvent.emit(false);
    }).catch((error: string) => {
      this.dialogService.alert(null, error || `${enable ? this.translateService.instant('Dialog.EmployeesComponent.text_errEnablingUser') : this.translateService.instant('Dialog.EmployeesComponent.text_errDisablingUser')}`, 'danger');
      this.loadingEvent.emit(false);
    });
  }

  public async unlinkUser(user: User) {
    if (! await this.dialogService.confirm(
      this.translateService.instant('Dialog.EmployeesComponent.text_UnlinkUser'),
      this.translateService.instant('Dialog.EmployeesComponent.text_conformUnlinkUser'),
      this.viewContainerRef
    )) {
      return;
    }

    if (!this.party || !this.party.partyId || !user || !user.partyId) {
      this.errorService.printError("Either party or user are empty.");
      return;
    }

    // this.dialogService.showLoader(`${this.LOADER_NAME}.unlinkUser`);
    this.loadingEvent.emit(true);

    this.userService.unlinkUser(this.party.partyId, user.partyId).then(() => {
      this.dialogService.alert(null, this.translateService.instant('Dialog.EmployeesComponent.text_userUnlinked'), 'success');
      this.reload();

      // this.dialogService.dismissLoader(`${this.LOADER_NAME}.unlinkUser`);
      this.loadingEvent.emit(false);
    }).then(() => {
      if (user.email) {
        this.userService.removeUserRole(user.email, this.isCustomer ? [ROLES.CUSTOMER] : [ROLES.SUPPLIER]);
      } else {
        throw new Error("User email is empty.");

      }
    }).catch((error: string) => {
      this.dialogService.alert(null, error || this.translateService.instant('Dialog.EmployeesComponent.text_errUserNotUnlinked'), 'danger');
      // this.dialogService.dismissLoader(`${this.LOADER_NAME}.unlinkUser`);
      this.loadingEvent.emit(false);
    })
  }

  public async deleteUser(user: User) {
    if (! await this.dialogService.confirm(
      this.translateService.instant('Dialog.EmployeesComponent.text_removeUser'),
      this.translateService.instant('Dialog.EmployeesComponent.text_confirmRemoveUser'),
      this.viewContainerRef
    )) {
      return;
    }

    // this.dialogService.showLoader(`${this.LOADER_NAME}.deleteUser`);
    this.loadingEvent.emit(true);

    this.userService.deleteUser(user!.username).then(() => {
      this.dialogService.alert(null, this.translateService.instant('Dialog.EmployeesComponent.text_userRemoved'), 'success');
      this.reload();
      // this.dialogService.dismissLoader(`${this.LOADER_NAME}.deleteUser`);
      this.loadingEvent.emit(false);
    }).catch((error: string) => {
      this.dialogService.alert(null, error || this.translateService.instant('Dialog.EmployeesComponent.text_userNotRemoved'), 'danger');
      // this.dialogService.dismissLoader(`${this.LOADER_NAME}.deleteUser`);
      this.loadingEvent.emit(false);
    })
  }

  public async resetPassword(user: User) {
    if (! await this.dialogService.confirm(
      this.translateService.instant('Dialog.EmployeesComponent.text_resetPassword'),
      this.translateService.instant('Dialog.EmployeesComponent.text_confirmResetPassword'),
      this.viewContainerRef
    )) {
      return;
    }

    // this.dialogService.showLoader(`${this.LOADER_NAME}.resetPassword`);
    this.loadingEvent.emit(true);

    this.userService.resetPassword(user.email).then(() => {
      this.dialogService.alert(null, this.translateService.instant('Dialog.EmployeesComponent.text_passwordReset'), 'success');
      this.reload();
      // this.dialogService.dismissLoader(`${this.LOADER_NAME}.resetPassword`);
      this.loadingEvent.emit(false);
    }).catch((error: string) => {
      this.dialogService.alert(null, error || this.translateService.instant('Dialog.EmployeesComponent.text_errPasswordNotReset'), 'danger');
      // this.dialogService.dismissLoader(`${this.LOADER_NAME}.resetPassword`);
      this.loadingEvent.emit(false);
    });
  }

  public setUserAttribute(user: User, attributeTypeId: string, attributeValue: string) {
    // this.dialogService.showLoader(`${this.LOADER_NAME}.setUserAttribute`);
    this.loadingEvent.emit(true);
    if (!user || !user.partyId) {
      return;
    }

    this.partyService.setAttribute(user.partyId, attributeTypeId, attributeValue).then(() => {
      this.dialogService.alert(null, this.translateService.instant('Dialog.EmployeesComponent.text_attributeUpdated'), 'success');
      this.reload();
      // this.dialogService.dismissLoader(`${this.LOADER_NAME}.setUserAttribute`);
      this.loadingEvent.emit(false);
    }).catch((error: string) => {
      this.dialogService.alert(null, error || this.translateService.instant('Dialog.EmployeesComponent.text_errAttributeNotUpdated'), 'danger');
      // this.dialogService.dismissLoader(`${this.LOADER_NAME}.setUserAttribute`);
      this.loadingEvent.emit(false);
    });
  }

  public updateUserName(user: User, firstname: string, lastname: string, formGroup: FormGroup<any>) {
    user.loading = true;

    this.userService.updateName(firstname, lastname, user.username).then(() => {
      user.loading = false;
      this.reload();
    }).catch((error: string) => {
      user.loading = false;
      this.dialogService.alert(null, error || this.translateService.instant('Dialog.EmployeesComponent.text_errNameNotUpdated'), 'danger');
    })
  }

  public reload(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.party != null) {
        this.defaultFormValues = this.party;
      }
      this.loadEmployee(this.username);
      this.initForm();
      return resolve();
    });
  }

  public showForm(form: any, defaultValues: any, editingControl: any) {
    console.log(editingControl);
    if (editingControl) {
      if (editingControl.value) {
        console.log("if");
        editingControl.patchValue(false);

        Object.keys(form.controls).forEach((fieldName: string) => {
          form.controls[fieldName].patchValue(this.defaultFormValues[fieldName] || defaultValues[fieldName] || '');
          form.controls[fieldName].disable();
        });
      } else {
        console.log("else");
        editingControl.patchValue(true);

        Object.keys(form.controls).forEach((fieldName: string) => {
          form.controls[fieldName].enable();
        });
      }
    }
  }

  // public reload(): Promise<void> {
  //   let party = this.party;
  //   return new Promise((resolve, reject) => {
  //     if (party != null) {
  //       this.defaultFormValues = party;
  //       this.loadEmployees(party).then((employees: Employee[]) => {
  //         this.employees = employees;
  //         this.partyForm.markAsPristine();
  //         this.initForm(party!, employees);

  //         return resolve();
  //       }).catch(() => {
  //         return reject();
  //       })
  //     }
  //   })
  // }

}
