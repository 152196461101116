<div class="titlebar">
  <h3>{{ "Template.SettingsComponent.subHeader_settings" | translate }}</h3>
</div>

<div class="row">
  <div class="col-6">
    <h5>{{ "Template.SettingsComponent.subHeader_myData" | translate }}:</h5>
  </div>
  <div class="col-1">
  </div>
  <div class="col-5">
    <h5>{{ 'Template.SettingsComponent.text_application' | translate }}:</h5>
  </div>
</div>

<div class="row">
  <div class="col-6 col-md-6">
    <div class="form-group">
      <form [formGroup]="nameForm" (ngSubmit)="updateName()" novalidate>
        <label>{{ "Template.SettingsComponent.label_name" | translate }}</label>
        <div class="row">
          <div class="col-5">
            <input type="text" [ngClass]="nameForm.controls['editing'].value ? 'form-control' : 'form-control-plaintext'"
              [readonly]="!nameForm.controls['editing'].value" formControlName="firstname"
              placeholder="{{ 'Template.SettingsComponent.text_firstName' | translate }}">
          </div>
          <div class="col-5">
            <input type="text" [ngClass]="nameForm.controls['editing'].value ? 'form-control' : 'form-control-plaintext'"
              [readonly]="!nameForm.controls['editing'].value" formControlName="lastname"
              placeholder="{{ 'Template.SettingsComponent.text_lastName' | translate }}">
          </div>
          <div class="col-2">
            <button class="btn btn-secondary h-100" (click)="showForm(nameForm)" type="button" placement="top"
              ngbTooltip="{{ 'Template.SettingsComponent.text_edit' | translate }}">
              <fa-icon icon="pencil-alt"></fa-icon>
            </button>
          </div>
        </div>

        <div class="alert alert-danger text-center mt-2" *ngIf="nameError">
          {{nameError}}
        </div>

        <button type="submit" class="btn btn-success d-block w-100 mt-2" [disabled]="nameLoading || !nameForm.valid" *ngIf="nameForm.controls['editing'].value">
          {{ 'Template.SettingsComponent.btn_save' | translate }}
          <fa-icon icon="check"></fa-icon>
          <fa-icon icon="spinner" [spin]="true" *ngIf="nameLoading"></fa-icon>
        </button>
      </form>
    </div>
  </div>  
  <div class="col-1 col-md-1">
    
  </div>

  <div class="col-5 col-md-5">
    <div>
      <label>{{ 'Template.SettingsComponent.text_language' | translate }}</label>
      <div class="row">
          <div class="mt-1 mb-1">
            <select class="form-select" [(ngModel)]="selectedValue" (change)="onLangChange(selectedValue)">
              <option class="dropdown-item" value="de">Deutsch</option>
              <option class="dropdown-item" value="en">English</option>
            </select>
          </div>  
      </div> 
    </div>
  </div>
    <hr />
  <div class="col-6 col-md-6">
    <div class="form-group">
      <form [formGroup]="emailForm" (ngSubmit)="updateEmail()" novalidate>
        <label>{{ 'Template.SettingsComponent.label_emailAdress' | translate }}</label>
        <div class="row">
          <div class="col-10">
            <input type="text" [ngClass]="emailForm.controls['editing'].value ? 'form-control' : 'form-control-plaintext'"
              [readonly]="!emailForm.controls['editing'].value" formControlName="email"
              placeholder="{{ 'Template.SettingsComponent.label_emailAdress' | translate }}">
          </div>
          <div class="col-2">
            <button class="btn btn-secondary h-100" (click)="showForm(emailForm)" type="button" placement="top"
              ngbTooltip="{{ 'Template.SettingsComponent.text_edit' | translate }}">
              <fa-icon icon="pencil-alt"></fa-icon>
            </button>
          </div>
        </div>

        <div class="alert alert-danger text-center mt-2" *ngIf="emailError">
          {{emailError}}
        </div>

        <button type="submit" class="btn btn-success d-block w-100 mt-2" [disabled]="emailLoading || !emailForm.valid" *ngIf="emailForm.controls['editing'].value">
          {{ 'Template.SettingsComponent.btn_save' | translate }}
          <fa-icon icon="check"></fa-icon>
          <fa-icon icon="spinner" [spin]="true" *ngIf="emailLoading"></fa-icon>
        </button>
      </form>
    </div>

    <hr />

    <div class="form-group" *ngIf="currentUser">
      <label for="newPassword">{{ 'Template.SettingsComponent.label_password' | translate }}</label>
      <div class="row">
        <div class="col-10">
        </div>
        <div class="col-2">
          <button class="btn btn-secondary h-100" (click)="togglePasswordForm()" type="button" placement="top"
            ngbTooltip="{{ 'Template.SettingsComponent.text_edit' | translate }}">
            <fa-icon icon="pencil-alt"></fa-icon>
          </button>
        </div>
      </div>

      <div class="row">        
        <app-password-change-form class="col-10" *ngIf="showPasswordForm" [username]="currentUser.username" (exitFormEvent)="exitPasswordForm($event)"></app-password-change-form>
      </div>

    </div>

    <hr *ngIf="currentUser?.roles?.includes('ROLE_ADMIN')" />
  </div>
</div>

<div class="row" *ngIf="currentUser?.roles?.includes('ROLE_ADMIN')">
  <div class="col-12 col-md-6">

    <button class="btn d-block w-100 btn-secondary" (click)="openAdminModal(adminModal)">
      {{ 'Template.SettingsComponent.btn_addAdmin' | translate }}
    </button>
  </div>
</div>

<ng-template #adminModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ 'Template.SettingsComponent.subHeader_createAdmin' | translate }}</h4>
      <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
      <app-create-user-form [userProps]="userProps" (exitFormEvent)="exitUserForm($event)"></app-create-user-form>
    </div>
  </div>
</ng-template>