<div class="titlebar">
  <h3>{{ "Template.SuppliersComponent.subHeader_suppliers" | translate }}</h3>

  <div class="buttons">
    <button class="btn btn-success" [disabled]="loading" (click)="openModal(createSupplierModal)">
      <fa-icon icon="plus"></fa-icon>
    </button>
    <button class="btn btn-secondary" [disabled]="loading" (click)="loadSuppliers()">
      <fa-icon icon="arrow-rotate-right"></fa-icon>
    </button>
  </div>
</div>

<fa-icon icon="spinner" [spin]="true" *ngIf="loading"></fa-icon>

<div *ngIf="!loading && suppliers &&  !suppliers.length">
  <p>{{ "Template.SuppliersComponent.text_noSuppliersCreated" | translate }}</p>
</div>

<div *ngIf="!loading && suppliers && suppliers.length">
  <table class="table table-striped">
    <caption>
      {{suppliersCount}}
      <span *ngIf="suppliersCount != 1">{{ "Template.SuppliersComponent.text_multipleSuppliers" | translate }}</span>
      <span *ngIf="suppliersCount == 1">{{ "Template.SuppliersComponent.text_oneSupplier" | translate }}</span>
    </caption>
    <thead>
      <tr>
        <th scope="col">{{ "Template.SuppliersComponent.text_gln" | translate }}</th>
        <th scope="col">{{ "Template.SuppliersComponent.text_company" | translate }}</th>
        <th scope="col" class="text-center">{{ "Template.SuppliersComponent.text_enabled" | translate }}</th>
        <th scope="col">{{ "Template.SuppliersComponent.text_type" | translate }}</th>
        <th scope="col">&nbsp;</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let supplier of suppliers">
        <td scope="row" class="align-middle">
          <span *ngIf="supplier.identifications && supplier.identifications['GLN']">
            {{supplier.identifications["GLN"].value}}
          </span>

          <span *ngIf="!(supplier.identifications && supplier.identifications['GLN'])">
            ---
          </span>
        </td>
        <td class="align-middle">
          {{supplier.name}}
        </td>
        <td class="text-center align-middle">
          <fa-icon icon="check" class="text-success" *ngIf="supplier.enabled"></fa-icon>
          <fa-icon icon="times" class="text-danger" *ngIf="!supplier.enabled"></fa-icon>
        </td>
        <td>
          {{ supplier.typeId }}
        </td>
        <td class="text-end align-middle">
          <a [routerLink]="['/app', 'admin', 'suppliers', supplier.partyId]" class="btn btn-secondary">
            {{ "Template.SuppliersComponent.text_details" | translate }}
            <fa-icon icon="chevron-right"></fa-icon>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #createSupplierModal let-modal>
  <div class="modal-header">
    <h4 *ngIf="createStep == 1" class="modal-title" id="modal-basic-title">{{ "Template.SuppliersComponent.subHeader_createSupplier" | translate }}
    </h4>
    <h4 *ngIf="createStep == 2" class="modal-title" id="modal-basic-title">{{ "Template.SuppliersComponent.subHeader_createSupplierContact" | translate }}
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div *ngIf="createStep == 1">
      <form [formGroup]="supplierForm" novalidate (ngSubmit)="onSubmit($event)">
        <div class="form-group">
          <label for="gln">{{ "Template.SuppliersComponent.label_gln" | translate }}</label>
          <input type="text" class="form-control" id="gln" formControlName="gln" placeholder="GLN">
        </div>
        <div class="form-group">
          <label for="companyName">{{ "Template.SuppliersComponent.label_companyName" | translate }}</label>
          <input type="text" class="form-control" id="companyName" formControlName="companyName"
            placeholder="{{ 'Template.SuppliersComponent.label_companyName' | translate }}">
        </div>

        <div  class="row">
          <div class="col-6">
            <button type="submit" class="btn btn-success d-block w-100 h-100" [disabled]="loadingCreate || !supplierForm.valid" name="{{ FINISH }}" ngbTooltip="{{ 'Template.SuppliersComponent.tooltip_create' | translate }}">
              {{ "Template.SuppliersComponent.btn_create" | translate }}
              <fa-icon icon="chevron-right"></fa-icon>
              <fa-icon icon="spinner" [spin]="true" *ngIf="loadingCreate"></fa-icon>
            </button>
          </div>
          <div class="col-6">
            <button type="submit" class="btn btn-success d-block w-100 h-100" [disabled]="loadingCreate || !supplierForm.valid" name="{{ CONTINUE }}" ngbTooltip="{{ 'Template.SuppliersComponent.tooltip_createContact' | translate }}">
              {{ "Template.SuppliersComponent.btn_createContact" | translate }}
              <fa-icon icon="chevron-right"></fa-icon>
              <fa-icon icon="spinner" [spin]="true" *ngIf="loadingContinue"></fa-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="createStep == 2 && userProps.employerPartyId">
      <app-create-user-form [userProps]="userProps" (exitFormEvent)="exitUserForm($event)"></app-create-user-form>
    </div>

    <p class="alert alert-danger text-center mt-3" *ngIf="error">
      <strong>{{error}}</strong>
    </p>
  </div>
</ng-template>