<table class="table table-striped">
    <thead>
        <tr>
            <th scope="col" class="col-3">{{
                "Template.SupplierProductsOverviewComponent.label_supplierProductId" |
                translate }}</th>
            <th scope="col" class="col-3">EAN</th>
            <th scope="col" class="col-4">{{ "Template.SupplierProductsOverviewComponent.label_description"
                | translate }}</th>
            <th scope="col" class="col-2">&nbsp;</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let product of products">
            <td> {{ product.id }} </td>
            <!-- output the ean as column if there is a type containing "ean" -->
            <td><span  *ngIf="product && product.identifications && product.identifications[0].type == 'EAN'">{{ product.identifications[0].value }}</span></td>
            <td> {{ product.description }} </td>
            <td class="text-end align-middle">
                <a [routerLink]="['/app', 'admin', 'supplier-product', product.id]"
                    class="btn btn-secondary">
                    {{ "Template.SupplierProductsOverviewComponent.btn_details" | translate }}
                    <fa-icon icon="chevron-right"></fa-icon>
                </a>
            </td>
        </tr>
    </tbody>
</table>
<div class="row">
    <div class="col-md-12 text-end">
        <button class="btn btn-primary" type="button" (click)="loadMore()">{{ "Template.SupplierProductsOverviewComponent.btn_loadMore" | translate }}</button>
    </div>
</div>