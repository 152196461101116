<small *ngFor="let dataStream of dataStreams">
    <span *ngIf="dataStream.supplier">{{dataStream.supplier.name}}</span>

    (
    <span
        *ngIf="dataStream.supplier && dataStream.supplier.identifications && dataStream.supplier.identifications['GLN']">
        {{dataStream.supplier.identifications['GLN']}}
    </span>
    <span *ngIf="dataStream.productCount">
        {{dataStream.productCount}} {{ "Template.DatastreamPrevievComponent.text_products" | translate }}
    </span>)
    <br />
</small>