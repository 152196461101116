import { Component, OnInit } from '@angular/core';
import { HttpService } from '@services/http.service';
import { ErrorService } from '@services/error.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidationService } from '@services/custom-validation.service';

@Component({
  selector: 'app-media-search',
  templateUrl: './media-search.component.html',
  styleUrls: ['./media-search.component.scss']
})

export class MediaSearchComponent implements OnInit {

  public mediaForm: FormGroup = this.formBuilder.group({
    ean: ['', Validators.required],
    mediaType: ['',
      Validators.compose([Validators.pattern("^(|IMAGE|DOCUMENT|DATA)$")])
    ],
    statusType: [''],
    dataCatalog: ['']
  });
  public statusTypes: string[] = [];
  public mediaTypes: string[] = [];
  public uniqueDataCatalogs: string[] = [];

  mediaItems: any[] = [];
  countResult: number = 0;
  public showAlertMessage = false;
  loadingData: boolean = false;

  constructor(
    private httpService: HttpService,
    private errorService: ErrorService,
    private formBuilder: FormBuilder,
    private customValidator: CustomValidationService,
  ) { }

  ngOnInit(): void {
    this.getStatusTypes().then((response) => {
      if (response) {
        this.statusTypes = response;
      }
    })

    this.findUniqueDataCatalogId().then((response) => {
      if (response) {
        this.uniqueDataCatalogs = response;
      }
    })

    this.getMediaTypes().then((response) => {
      if (response) {
        this.mediaTypes = response;
      }
    })
  }

  private getMediaItemsCount(arr: any[]) {
    return arr.length;
  }

  public getStatusTypes(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/supplier-products/getStatusTypes`).then((response: string[]) => {
        return resolve(response);
      }).catch((error: any) => {
        this.errorService.printError(error);
        return reject(error);
      })
    })
  }

  public getMediaTypes(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/supplier-products/getMediaTypes`).then((response: string[]) => {
        return resolve(response);
      }).catch((error: any) => {
        this.errorService.printError(error);
        return reject(error);
      })
    })
  }

  public findUniqueDataCatalogId(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/supplier-products/findUniqueDataCatalogId`).then((response: string[]) => {
        return resolve(response);
      }).catch((error: any) => {
        this.errorService.printError(error);
        return reject(error);
      })
    })
  }

  public checkForm(form: FormGroup): Promise<void> {
    if (form == null || form.invalid) {
      return Promise.reject('Form is invalid');
    }
    this.loadingData = true;
    this.showAlertMessage = false;

    let ean = form.get('ean')?.value || "";
    let mediaType = form.get('mediaType')?.value || ""; // for a select input, the value is an object
    let statusType = form.get('statusType')?.value || ""; // for a select input, the value is an object
    let dataCatalog = form.get('dataCatalog')?.value || ""; // for a select input, the value is an object

    console.log(ean, mediaType, statusType, dataCatalog);

    if (ean || mediaType || statusType || dataCatalog) {
      return this.getMediaItem(ean, mediaType, statusType, dataCatalog);
    } else {
      this.showAlertMessage = true;
      this.countResult = 0;
      this.mediaItems = [];
      this.loadingData = false;
      return Promise.reject('Form inputs are empty');
    }
  }

  private getMediaItem(
    ean: string,
    mediaType: string,
    statusType: string,
    dataCatalog: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/media-items/getFilteredMediaItem`, {
        ean: ean,
        mediaType: mediaType,
        statusType: statusType,
        dataCatalogId: dataCatalog
      }).then((response: any) => {
        if (response) {
          this.mediaItems = response;
          this.countResult = this.getMediaItemsCount(this.mediaItems.map(item => item.mediaItem));
        } else {
          this.mediaItems = [];          
        }
        return resolve();
      }).finally(() => {
        this.loadingData = false;
      }).catch((error: any) => {
        this.errorService.printError(error);
        this.mediaItems = [];
        this.countResult = 0;
        return reject(error);
      });
    });
  }

}
