import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ISupplierProduct } from '@app/interfaces/isupplier-product';
import { HttpService } from '@services/http.service';

@Component({
  selector: 'app-supplier-products-overview',
  templateUrl: './supplier-products-overview.component.html',
  styleUrls: ['./supplier-products-overview.component.scss']
})
export class SupplierProductsOverviewComponent implements OnInit {
  public id: string | null = null;
  private page: number = 0;
  public products: ISupplierProduct[] = [];

  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.reload();
  }

  public reload() {
    this.page = 0;
    if (this.id) {
      this.fetchLimitedByDataCatalogId(this.id).then((result: ISupplierProduct[]) => {
        result.forEach((element) => {
          this.products.push(element);
        })
      })

    }
  }

  private fetchLimitedByDataCatalogId(dataCatalogId: string): Promise<ISupplierProduct[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/supplier-products/getLimitedByDataCatalogId`, { page: this.page, viewSize: 50, dataCatalogId: dataCatalogId }).then((response) => {
        if (response) {
          return resolve(response);
        }

        return resolve([]);
      })
    })
  }


  public loadMore() {
    this.page = this.page + 1;
    this.reload();
  }

}
