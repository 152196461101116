import { Injectable } from '@angular/core';
import { ISupplierProduct } from '@app/interfaces/isupplier-product';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from './dialog.service';
import { ErrorService } from './error.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private httpService: HttpService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private errorService: ErrorService
  ) { }

  public fetchProduct(id: string | null): Promise<ISupplierProduct | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/supplier-products/get`, { id }).then((response: ISupplierProduct) => {
        if (response) {
          return resolve(response);
        }
        return resolve(null);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject(err);
      })
    })
  }

  public fetchProductImages(supplierProductId: string | null): Promise<Map<string, string>> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/supplier-products/getImageUrls`, { id: supplierProductId }).then((response: Map<string, string>) => {
        if (response) {
          return resolve(response);
        }

        return resolve(new Map());
      }).catch((err) => {
        this.errorService.printError(err);
        return reject(err);
      })
    })
  }

  public fetchProductDocuments(supplierProductId: string | null): Promise<Map<string, string>> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/supplier-products/getDocumentUrls`, { id: supplierProductId }).then((response: Map<string, string>) => {
        if (response) {
          return resolve(response);
        }

        return resolve(new Map());
      }).catch((err) => {
        this.errorService.printError(err);
        return reject(err);
      })
    })
  }

  public removeSupplierProducts(dataCatalogId: string | null): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/supplier-products/removeByDataCatalogId`, { id: dataCatalogId }).then((response: number) => {
        if (response && response > 0) {
          return this.dialogService.alert("", this.translateService.instant('Dialog.DataCatalogDetailComponent.msg_removedSupplierProducts', { amount: response }), 'success', 0, false);
        } else {
          return Promise.reject();
        }
      }).catch(() => {
        this.dialogService.alert("", this.translateService.instant('Dialog.DataCatalogDetailComponent.msg_errRemovingSupplierProducts'), 'danger', 0, false);
        return resolve();
      })
    })
  }

  public fetchQuantityOfProducts(dataCatalogId: string | null): Promise<string> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/supplier-products/countByDataCatalogId`, { id: dataCatalogId }).then((response: any) => {
        if (response || response === 0) {
          return resolve(response.toString());
        } else {
          return Promise.reject();
        }
      }).catch((err) => {
        this.errorService.printError(err);
        return reject(err);
      })
    })
  }

  public fetchByIdentification(ean: string): Promise<ISupplierProduct[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/supplier-products/getByEAN`, { ean: ean }).then((response) => {
        if (response && response != null) {
          return resolve(response);
        }
      }).catch((err) => {
        this.errorService.printError(err);
        return reject(err);
      })
    })
  }

  public resetProductData(partyId: string | null): Promise<number> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/supplier/resetDataCatalog`, { partyId: partyId }).then((response: number) => {
        if (response && response != null) {
          return resolve(response);
        }
        return resolve(0);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject(err);
      })
    })
  }

  public getMediaItemsForParty(partyId: string | null): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/media-items/getMediaItemsForParty`, {
        partyId
      }).then((mediaItems: any) => {
        return resolve(mediaItems);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject(err);
      })
    })
  }
}
