import { Component, Input, OnInit } from '@angular/core';
import { IDataStreamAccess } from '@app/interfaces/idata-stream-access';
import { DataStream } from '@models/data-stream';
import { Supplier } from '@models/supplier';
import { DataStreamService } from '@services/data-stream.service';
import { ErrorService } from '@services/error.service';
import { HttpService } from '@services/http.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-data-stream-accesses',
  templateUrl: './data-stream-accesses.component.html',
  styleUrls: ['./data-stream-accesses.component.scss']
})
export class DataStreamAccessesComponent implements OnInit {
  public _partyId: string = '';

  @Input()
  public set partyId(partyId: string) {
    this._partyId = partyId;
  }

  public dataStreamAccesses: IDataStreamAccess[] = [];

  constructor(
    private dataStreamService: DataStreamService,
    private errorService: ErrorService,
    private httpService: HttpService
  ) { }

  ngOnInit(): void {
    this.init();
  }

  private init(): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.dataStreamService.getDataStreamByParty(this._partyId).then((dataStream: DataStream | null) => {
        if (!dataStream) {
          return resolve();
        }
        return this.dataStreamService.getAccesses(dataStream.id).then((dataStreamAccesses: IDataStreamAccess[] | null) => {
          if (!dataStreamAccesses || dataStreamAccesses == null) {
            return resolve();
          }
          this.dataStreamAccesses = dataStreamAccesses;

          return this.addSuppliers(dataStreamAccesses);
        })
      }).then(() => {
        return resolve();
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      })
    })
  }

  private addSuppliers(dataStreamAccesses: IDataStreamAccess[]): Promise<void> {
    return new Promise((resolve, reject) => {
      dataStreamAccesses?.forEach(element => {
        this.requestSupplier(element.partyId).then((supplier: Supplier) => {
          element.supplier = supplier;
        })
      })
      return resolve();
    })
  }

  private getSupplierName(partyId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      return this.httpService.get("**/suppliers/get", { partyId }).then((supplier: Supplier) => {
        if (supplier && supplier.name) {
          return resolve(supplier.name);
        }
        return resolve("");
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      })
    })
  }

  private getSupplier(partyId: string): Observable<Supplier> {
    let test: Observable<Supplier> = new Observable((observer) => {
      this.requestSupplier(partyId).then((supplier: Supplier) => {
        observer.next(supplier);
      }).catch((err) => {
        observer.error(err);
      })
    })
    return test;
  }

  private requestSupplier(partyId: string): Promise<Supplier> {
    return new Promise((resolve, reject) => {
      return this.httpService.get("**/suppliers/get", { partyId }).then((supplier: Supplier) => {
        if (supplier) {
          return resolve(supplier);
        }
        return reject();
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      })
    })
  }

}
