<div class="row">
  <div class="col-6">
    <strong>{{ "Template.StatusComponent.text_dataUploaded" | translate }}:</strong>
  </div>
  <div class="col-6">
    <fa-icon icon="check" *ngIf="dataCatalogStatus?.status != 'PRISTINE'" class="text-success"></fa-icon>
    <fa-icon icon="close" *ngIf="!dataCatalogStatus || dataCatalogStatus?.status == 'PRISTINE'" class="text-danger"></fa-icon>
  </div>
</div>
<div class="row" *ngIf="dataCatalogStatus?.status != 'PRISTINE' && dataCatalogStatus?.lastUpdatedDate">
  <div class="col-6">
    <strong>{{ "Template.StatusComponent.text_latestUpload" | translate }}:</strong>
  </div>
  <div class="col-6">
    <span>{{ dataCatalogStatus?.lastUpdatedDate | date : "dd.MM.yyyy, HH:mm" }} {{ "Template.StatusComponent.text_timeUnitH" | translate }}</span>
  </div>
</div>
<div class="row" *ngIf="dataCatalogStatus && dataCatalogStatus.status">
  <div class="col-6">
    <strong>{{ "Template.StatusComponent.text_state" | translate }}:</strong>
  </div>
  <div class="col-6">
    <span *ngIf="dataCatalogStatus.status == 'PRISTINE'">{{ "Template.StatusComponent.text_pristine" | translate }}</span>
    <span *ngIf="dataCatalogStatus.status == 'UPLOADED'">{{ "Template.StatusComponent.text_uploaded" | translate }}...</span>
    <span *ngIf="dataCatalogStatus.status == 'PROCESSING'">{{ "Template.StatusComponent.text_processing" | translate }}...</span>
    <span *ngIf="dataCatalogStatus.status == 'CLEANING'">{{ "Template.StatusComponent.text_cleaning" | translate }}...</span>
    <span *ngIf="dataCatalogStatus.status == 'DELETE_REQUESTED'">{{ "Template.StatusComponent.text_deleteRequested" | translate }}...</span>
    <span *ngIf="dataCatalogStatus.status == 'DELETING'">{{ "Template.StatusComponent.text_deleted" | translate }}...</span>
    <span *ngIf="dataCatalogStatus.status == 'READY'">{{ "Template.StatusComponent.text_ready" | translate }}</span>

    <fa-icon icon="spinner" [spin]="true" size="1x" *ngIf="dataCatalogStatus?.status == 'READY' && mediaItemsByStatus?.['NEW']?.length" class="text-warning ms-2" [ngbTooltip]="popContent"></fa-icon>
    <ng-template #popContent> {{ mediaItemsByStatus["NEW"]?.length }} {{ "Template.StatusComponent.text_checkingMediaItems" | translate }}... </ng-template>
  </div>
</div>
<div class="row" *ngIf="dataCatalogStatus && dataCatalogStatus.uploadedProductCount && dataCatalogStatus.uploadedProductCount != null && dataCatalogStatus.uploadedProductCount != undefined">
  <div class="col-6">
    <strong>{{ "Template.StatusComponent.text_uploadedProductCount" | translate }}:</strong>
  </div>
  <div class="col-6">
    {{ dataCatalogStatus.uploadedProductCount }}
  </div>
</div>
<div class="row" *ngIf="dataCatalogStatus && dataCatalogStatus.validProductCount != null && dataCatalogStatus.validProductCount != undefined">
  <div class="col-6">
    <strong>{{ "Template.StatusComponent.text_validProductCount" | translate }}:</strong>
  </div>
  <div class="col-6">
    {{ dataCatalogStatus.validProductCount }}
  </div>
</div>
