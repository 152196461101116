<div class="titlebar">
    <h3>{{ "Template.MediaSearchComponent.subHeader_mediaSearch" | translate }}</h3>
</div>

<form name="filterMediaItems" [formGroup]="mediaForm">
    <div class="row mb-3">
        <div *ngIf="showAlertMessage" class="alert alert-info">
            {{ 'Template.MediaSearchComponent.alert_formAlert' | translate }}
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-6 col-xs-12">
            <label for="EAN">{{ "Template.MediaSearchComponent.label_ean" | translate }}:</label>
            <input type="text" class="form-control" id="productIdentifier" name="productIdentifier" placeholder="EAN" value="" formControlName="ean">
        </div>
        
        <div class="col-6 col-xs-12">
            <label for="mediaType">{{ 'Template.MediaSearchComponent.label_mediaType' | translate }}:</label>
            <select class="form-control" id="mediaType" name="mediaType" formControlName="mediaType">
                <option value="" >{{ 'Template.MediaSearchComponent.text_all' | translate }}</option>
                <option *ngFor="let mediaType of mediaTypes" [value]="mediaType">
                    {{ mediaType }}
                </option>
            </select>
        </div>    
    </div>
    
    <div class="row mb-3">  
        <div class="col-6 col-xs-12">
            <label for="status">{{ "Template.MediaSearchComponent.label_status" | translate }}:</label>
            <select class="form-control" name="statusType" id="statusType" formControlName="statusType">
                <option value="">{{ "Template.MediaSearchComponent.text_all" | translate }}
                </option>
                    <option *ngFor="let statusType of statusTypes" [value]="statusType">
                        {{ statusType }}
                    </option>
            </select>
        </div>

        <div class="col-6 col-xs-12">
            <label for="dataCatalogId">{{ 'Template.MediaSearchComponent.label_catalog' | translate }}:</label>
            <select class="form-control" id="dataCatalogId" name="dataCatalogId" formControlName="dataCatalog">
                <option value="">{{ 'Template.MediaSearchComponent.text_all' | translate }}</option>
                    <option *ngFor="let dataCatalog of uniqueDataCatalogs" [value]="dataCatalog">
                        {{ dataCatalog }}
                    </option>
            </select>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1 text-center">
            <button 
                (click)="checkForm(mediaForm)" type="submit" class="btn btn-secondary btn-block"
                [disabled]="!mediaForm.valid">
                {{ 'Template.MediaSearchComponent.btn_search' | translate }}
            </button>
        </div>
    </div>
</form>


<div *ngIf="loadingData; else content" class="mt-5">
    <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1 text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden"></span>
            </div>
        </div>
    </div>
</div>

<ng-template #content>
    <div *ngIf="mediaItems && mediaItems.length > 0 ">
        <hr />

        <h6 class="my-2">{{ 'Template.MediaSearchComponent.text_quantityResults' | translate: {quantityResults: countResult} }}:
        </h6>
            <p>
                {{ 'Template.MediaSearchComponent.msg_maxResults' | translate }}.
            </p>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col" class="border-top-0">{{ 'Template.MediaSearchComponent.text_type' | translate }}</th>
                    <th scope="col" class="border-top-0">{{ 'Template.MediaSearchComponent.text_catalog' | translate }}</th>
                    <th scope="col" class="border-top-0">{{ 'Template.MediaSearchComponent.text_source' | translate }}</th>
                    <!-- <th scope="col" class="border-top-0">{{ 'Template.MediaSearchComponent.text_ean' | translate }}</th> -->
                    <th scope="col" class="border-top-0">{{ 'Template.MediaSearchComponent.text_filename' | translate }}</th>
                    <th scope="col" class="border-top-0">{{ 'Template.MediaSearchComponent.text_originalFilename' | translate }}</th>
                    <th scope="col" class="border-top-0">{{ 'Template.MediaSearchComponent.text_status' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let media of mediaItems | slice:0:1000">
                    <td >
                        <div *ngIf="media.mediaType && media.mediaType == 'DATA'">
                            <small>{{ 'Template.MediaSearchComponent.text_mediaTypeData' | translate}}</small>
                        </div>
                        <div *ngIf="media.mediaType && media.mediaType == 'DOCUMENT'">
                            <small>{{ 'Template.MediaSearchComponent.text_mediaTypeDocument' | translate}}</small>
                        </div>
                        <div *ngIf="media.mediaType && media.mediaType == 'IMAGE'">
                            <small>{{ 'Template.MediaSearchComponent.text_mediaTypeImage' | translate}}</small>
                        </div>
                    </td>
                    <td >
                        <div *ngIf="media.dataCatalogId">
                            <small>{{ media.dataCatalogId }} </small>
                        </div>
                    </td>
                    <td >
                        <div *ngIf="media.connectionId">
                            <small><p> {{ media.connectionId }}</p></small>
                        </div>
                    </td>
                    <!--<td>
                        <div *ngIf="media.mediaItem">
                            <small>{{ media.ean }}</small>
                        </div>
                    </td>-->
                    <td>
                        <div *ngIf="media.filename">
                            <small>{{ media.filename }}</small>
                        </div>
                    </td>
                    <td>
                        <div *ngIf="media.originalFilename">
                            <small>{{ media.originalFilename }}</small>
                        </div>
                        <div *ngIf="media.originalUrl">
                            <small><a [href]="media.originalUrl" target="_blank">{{ media.originalUrl }}</a></small>
                        </div>
                    </td>
                    <td >
                        <div *ngIf="media.status">
                            <small>{{ media.status }} </small>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<div *ngIf="(!mediaItems || mediaItems.length < 1) && !loadingData">
    <h5 class="text-danger mt-4">{{ 'Template.MediaSearchComponent.subHeader_noMediaFound' | translate }}.</h5>
</div>