import { Component, OnInit } from '@angular/core';

import { ErrorService } from '@services/error.service';
import { HttpService } from '@services/http.service';

@Component({
  selector: 'app-admin-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class AdminMainComponent implements OnInit {

  public sftpAccounts: any[] = [];
  public systemInformation: any;

  public sftpUsersCollapsed: boolean = true;

  public unavailable: boolean = false;
  public loaded: any = {};

  constructor(
    private httpService: HttpService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
    this.loadSftpAccounts();
    this.loadSystemInformation();
  }

  private loadSftpAccounts() {
    this.httpService.get('**/admin/getSftpData').then((sftpData: any[]) => {
      this.sftpAccounts = sftpData;
      this.loaded["FTP_ACCOUNTS"] = true;
    }).catch((error: any) => {
      this.loaded["FTP_ACCOUNTS"] = true;
      this.errorService.printError(error);
    });
  }

  private loadSystemInformation() {
    this.httpService.get('**/admin/getSystemInformation').then((systemInformation: any[]) => {
      this.systemInformation = systemInformation;
      this.loaded["SYSTEM_INFORMATION"] = true;
    }).catch((error: any) => {
      this.loaded["SYSTEM_INFORMATION"] = true;
      this.errorService.printError(error);
    });
  }

}
