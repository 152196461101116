import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IDataCatalogMessage } from '@app/interfaces/idata-catalog-message';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnChanges {

  // cannot be changed
  @Input() messages: Map<number, IDataCatalogMessage[]> | null = null;
  @Output() translatedEvent = new EventEmitter<number>();
  public translatedMessages: string[] = [];

  constructor(
    private translateService: TranslateService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // clear translatedMessages
    this.translatedMessages = [];
    this.processMessages(this.messages);
  }

  ngOnInit(): void { }

  private processMessages(messages: Map<number, IDataCatalogMessage[]> | null) {
    if (!messages) {
      return;
    }

    this.translateAndConcatMessages(messages);
    this.translatedEvent.emit(this.translatedMessages.length);
  }

  private translateAndConcatMessages(messages: Map<number, IDataCatalogMessage[]>) {
    // traverse map and translate messages
    messages.forEach((value: IDataCatalogMessage[], key: number) => {
      let concatMessage = this.concatMessages(value);
      this.translatedMessages.push(concatMessage);
    });
  }

  private concatMessages(messages: IDataCatalogMessage[]): string {
    // concat all messages that have the same line number
    let concatMessage: string = "";
    messages.forEach((message: IDataCatalogMessage) => {
      concatMessage += " " + this.translateMessage(message);
    });

    return concatMessage;
  }

  private translateMessage(message: IDataCatalogMessage): string {
    let translatedMessage: string = "";
    let translationPrefix = "DataCatalogStatusMessages.";
    let messageCode: string = message.messageCode;

    translatedMessage = this.translateService.instant(translationPrefix + messageCode, {
      columnIndex: message.columnNumber, // the column number comes in correctly, so no increment needed here
      rowNumber: message.lineNumber,
      excelLetter: this.getExcelLetterForIndex(message.columnNumber),
      ean: message.ean,
      supplierArticleNo: message.supplierArticleNo,
      columnValue: message.columnValue
    });

    return translatedMessage;
  }

  private getExcelLetterForIndex(index: number): string {
    let result = '';
    while (index > 0) {
      const remainder = (index - 1) % 26;
      result = String.fromCharCode(65 + remainder) + result;
      index = Math.floor((index - 1) / 26);
    }
    return result;
  }

}
