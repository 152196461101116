import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '@services/auth.service';
import { DialogService } from '@services/dialog.service';
import { ErrorService } from '@services/error.service';
import { ProductService } from '@services/product.service';
import { HttpService } from '@services/http.service';
import { ROLES } from '@services/constants.service';

import { User } from '@models/user';
import { DataCatalogStatus } from '@models/data-catalog-status';

@Component({
  selector: 'app-datamanagement-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class DataManagementActionsComponent implements OnInit {

  @Input() partyId: string | null = null;
  @Input() dataCatalogStatus: DataCatalogStatus | null = null;

  public isAdmin: boolean = false;
  public currentLanguage: string | null = 'DE';
  public requestingDownload: boolean = false;

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private productService: ProductService,
    private errorService: ErrorService,
    private authService: AuthService,
    private httpService: HttpService,
  ) { }

  ngOnInit(): void {
    this.setIsAdmin();

    this.subscribeLanguage();
  }

  public resetProductData() {
    this.dialogService.showLoader("dataReset");
    this.productService.resetProductData(this.partyId).then(() => {
      this.dialogService.alert(null, this.translateService.instant("Dialog.FileUploadFormComponent.msg_startedDeletingProducts"));
      this.dialogService.dismissLoader("dataReset");
    }).catch((err) => {
      this.dialogService.alert(null, this.translateService.instant("Dialog.FileUploadFormComponent.msg_errorDeletingProducts"), 'danger');
      this.errorService.printError(err);
      this.dialogService.dismissLoader("dataReset");
    })
  }

  private setIsAdmin() {
    this.authService.getCurrentUser().then((user: (User | null)) => {
      if (user && user.roles) {
        if (user.roles && user.roles.includes(ROLES.ADMIN)) {
          this.isAdmin = true;
        }
      }
    }).catch((err) => {
      this.errorService.printError(err);
    });
  }

  private subscribeLanguage() {
    this.currentLanguage = this.translateService.currentLang || 'DE';
    this.translateService.onLangChange.subscribe((lang) => {
      this.currentLanguage = lang.lang;
    });
  }

  public getUploadedFile() {
    let url = '**/supplier/getUploadedFile?partyId=' + this.partyId;

    this.httpService.openExternalUrl(url);
  }

  public requestDownload(format: string) {
    this.requestingDownload = true;

    let url = '**/supplier-products/requestDownloadProducts?partyId=' + this.partyId + '&format=' + format;
    this.httpService.get(url).then((res) => {
      this.dialogService.alert(null, this.translateService.instant("Dialog.FileUploadFormComponent.msg_startedDownloadingProducts"));
      this.requestingDownload = false;
    }).catch((err) => {
      this.dialogService.alert(null, this.translateService.instant("Dialog.FileUploadFormComponent.msg_errorDownloadingProducts"), 'danger');
      this.errorService.printError(err);
      this.requestingDownload = false;
    });
  }

}
