import { Injectable } from '@angular/core';
import { IDataCatalogMessage } from '@app/interfaces/idata-catalog-message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor() { }

  public sortMessages(a: IDataCatalogMessage, b: IDataCatalogMessage): number {
    if (a.lineNumber !== b.lineNumber) {
      return a.lineNumber - b.lineNumber; // Sort by line number
    } else {
      return a.columnNumber - b.columnNumber; // If line numbers are equal, sort by column number
    }
  }

  public createMessageMap(messages: IDataCatalogMessage[]): Map<number, IDataCatalogMessage[]> {
    let tmpMessages = new Map<number, IDataCatalogMessage[]>();
    for (let index = 0; index < messages.length; index++) {
      const element = messages[index];

      // add all messages to the map (line number is the map's key)
      let mapValue: IDataCatalogMessage[] | undefined = tmpMessages.get(element.lineNumber);
      if (!mapValue) {
        tmpMessages.set(element.lineNumber, [element]);
      } else {
        mapValue.push(element);
        tmpMessages.set(element.lineNumber, mapValue);
      }
    }

    return tmpMessages;
  }

}
