<div class="titlebar">
    <h3>
        {{ "Template.DataCatalogDetailComponent.subHeader_dataCatalog" | translate }}
        <span *ngIf="dataCatalog && dataCatalog.id">{{ dataCatalog.id }}</span>
    </h3>

    <div class="buttons">
        <!-- <button class="btn btn-primary" (click)="goToFragment('details')">
            Details
        </button>
        <button class="btn btn-primary" (click)="goToFragment('products')">
            Produkte
        </button> -->
        <button class="btn btn-secondary" [disabled]="loading" (click)="reload()">
            <fa-icon icon="arrow-rotate-right"></fa-icon>
        </button>
    </div>
</div>

<fa-icon icon="spinner" [spin]="true" *ngIf="loading"></fa-icon>

<div *ngIf="dataCatalog">
    <ul class="nav nav-tabs" [activeId]="activatedRoute.fragment | async" ngbNav #tabs="ngbNav">
        <li class="nav-item" ngbNavItem="details">
            <a class="nav-link" ngbNavLink="#details" (click)="addFragmentToUrl('details')">
                {{ "Template.DataCatalogDetailComponent.label_details" | translate }}
            </a>

            <ng-template ngbNavContent fragment="details" id="details">
                <div class="tab-pane fade show" id="details" role="tabpanel">
                    <h5 class="mb-2"></h5>
                    <div class="row">
                        <div class="col-6 col-xs-12 px-4">
                            <div class="row">
                                <div class="col-4">
                                    <strong>{{ "Template.DataCatalogDetailComponent.label_dataCatalogId" | translate }}</strong>
                                </div>
                                <div class="col-8">
                                    <span *ngIf="dataCatalog.id">{{ dataCatalog.id }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <strong>{{ "Template.DataCatalogDetailComponent.label_name" | translate }}</strong>
                                </div>
                                <div class="col-8">
                                    <span *ngIf="dataCatalog.name">{{ dataCatalog.name }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <strong>{{ "Template.DataCatalogDetailComponent.label_enabled" | translate }}</strong>
                                </div>
                                <div class="col-8">
                                    <span *ngIf="dataCatalog.enabled">{{ "Template.DataCatalogDetailComponent.text_yes" | translate }}</span>
                                    <strong class="text-danger" *ngIf="!dataCatalog.enabled">
                                        {{ "Template.DataCatalogDetailComponent.text_no" | translate }}
                                        <fa-icon icon="exclamation-triangle"></fa-icon>
                                    </strong>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <strong>{{ "Template.DataCatalogDetailComponent.label_createdDate" | translate }}</strong>
                                </div>
                                <div class="col-8">
                                    <span *ngIf="dataCatalog.createdDate">{{ dataCatalog.createdDate | date :"d. MMMM yyyy" }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <strong>{{ "Template.DataCatalogDetailComponent.label_supplier" | translate }}</strong>
                                </div>
                                <div class="col-8">
                                    <a *ngIf="dataCatalog.supplierPartyId" (click)="navigateToSupplier(dataCatalog.supplierPartyId)">
                                        {{ dataCatalog.supplierPartyId }}
                                    </a>
                                    <span ></span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <strong>{{ "Template.DataCatalogDetailComponent.label_dataCatalogFormatId" | translate }}</strong>
                                </div>
                                <div class="col-8">
                                    <span *ngIf="dataCatalog.dataCatalogFormatId">{{ dataCatalog.dataCatalogFormatId }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <strong>{{ "Template.DataCatalogDetailComponent.label_sourceDirectory" | translate }}</strong>
                                </div>
                                <div class="col-8">
                                    <span *ngIf="dataCatalog.sourceDirectory">{{ dataCatalog.sourceDirectory }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <strong>{{ "Template.DataCatalogDetailComponent.label_sourceFilename" | translate }}</strong>
                                </div>
                                <div class="col-8">
                                    <span *ngIf="dataCatalog.sourceFilename">{{ dataCatalog.sourceFilename }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <strong>{{ "Template.DataCatalogDetailComponent.label_productQuantity" | translate }}</strong>
                                </div>
                                <div class="col-8">
                                    <span *ngIf="productQuantity">{{ productQuantity }}</span>
                                    <span *ngIf="!productQuantity">
                                        <fa-icon icon="spinner" [spin]="true"></fa-icon>
                                    </span>
                                </div>                                
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <strong>{{ "Template.DataCatalogDetailComponent.label_imagesStatusDistribution" | translate }}</strong>
                                </div>
                                <div class="col-12">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="col-md-2 col-sm-1">
                                                    {{ "Template.DataCatalogDetailComponent.label_status" | translate }}
                                                </th>
                                            <th scope="col" class="col-md-2 col-sm-1">
                                                {{ "Template.DataCatalogDetailComponent.label_quantity" | translate }}
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{ eImageStatus.new }}</td>
                                                <td >
                                                    <span *ngIf="!newImagesQty && newImagesQty != 0">
                                                        <fa-icon icon="spinner" [spin]="true"></fa-icon>
                                                    </span>
                                                    <span *ngIf="newImagesQty || newImagesQty == 0">
                                                        {{ newImagesQty }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{ eImageStatus.downloaded }}</td>
                                                <td >
                                                    <span *ngIf="!downloadedImagesQty && downloadedImagesQty != 0">
                                                        <fa-icon icon="spinner" [spin]="true"></fa-icon>
                                                    </span>
                                                    <span *ngIf="downloadedImagesQty || downloadedImagesQty == 0">
                                                        {{ downloadedImagesQty }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{ eImageStatus.notFound }}</td>
                                                <td >
                                                    <span *ngIf="!notFoundImagesQty && notFoundImagesQty != 0">
                                                        <fa-icon icon="spinner" [spin]="true"></fa-icon>
                                                    </span>
                                                    <span *ngIf="notFoundImagesQty || notFoundImagesQty == 0">
                                                        {{ notFoundImagesQty }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{ eImageStatus.invalid }}</td>
                                                <td >
                                                    <span *ngIf="!invalidImagesQty && invalidImagesQty != 0">
                                                        <fa-icon icon="spinner" [spin]="true"></fa-icon>
                                                    </span>
                                                    <span *ngIf="invalidImagesQty || invalidImagesQty == 0">
                                                        {{ invalidImagesQty }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{ eImageStatus.corrupted }}</td>
                                                <td >
                                                    <span *ngIf="!corruptedImagesQty && corruptedImagesQty != 0">
                                                        <fa-icon icon="spinner" [spin]="true"></fa-icon>
                                                    </span>
                                                    <span *ngIf="corruptedImagesQty || corruptedImagesQty == 0">
                                                        {{ corruptedImagesQty }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{ eImageStatus.scaled }}</td>
                                                <td >
                                                    <span *ngIf="!scaledImagesQty && scaledImagesQty != 0">
                                                        <fa-icon icon="spinner" [spin]="true"></fa-icon>
                                                    </span>
                                                    <span *ngIf="scaledImagesQty || scaledImagesQty == 0">
                                                        {{ scaledImagesQty }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{ eImageStatus.stale }}</td>
                                                <td >
                                                    <span *ngIf="!staleImagesQty && staleImagesQty != 0">
                                                        <fa-icon icon="spinner" [spin]="true"></fa-icon>
                                                    </span>
                                                    <span *ngIf="staleImagesQty || staleImagesQty == 0">
                                                        {{ staleImagesQty }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>                        
                            </div>

                        </div>
                        <div class="col-6 col-xs-12">
                            <div class="row">
                                <div class="col-12">
                                    <button class="btn btn-warning d-block w-100 mb-2" *ngIf="dataCatalog.enabled"
                                        (click)="toggleDataCatalog(false)">{{ "Template.DataCatalogDetailComponent.btn_disable" | translate }}
                                    </button>
                                    <button class="btn btn-success d-block w-100 mb-2" *ngIf="!dataCatalog.enabled"
                                        (click)="toggleDataCatalog(true)">{{ "Template.DataCatalogDetailComponent.btn_enable" | translate }}
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <button class="btn btn-warning d-block w-100 mb-2" *ngIf="dataCatalog.enabled"
                                (click)="removeSupplierProducts()">{{ "Template.DataCatalogDetailComponent.btn_removeSupplierProducts" | translate }}</button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <hr />
                </div>
            </ng-template>
        </li>
        <li class="nav-item" ngbNavItem="products">
            <a class="nav-link" ngbNavLink="#products" (click)="addFragmentToUrl('products')">                
                {{ "Template.DataCatalogDetailComponent.label_products" | translate }}
            </a>
            <ng-template ngbNavContent fragment="products" id="products">
                <div class="tab-pane fade show" id="products" role="tabpanel">
                    <app-supplier-products-overview></app-supplier-products-overview>
                </div>
            </ng-template>
        </li>
    </ul>
    <div class="tab-content" [ngbNavOutlet]="tabs"></div>
    <router-outlet></router-outlet>
</div>