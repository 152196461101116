import { Component, OnInit } from '@angular/core';
import { ErrorService } from '@services/error.service';
import { HttpService } from '@services/http.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {

  public functions: string[] = [];
  public functionsInfo: any[] = [];

  constructor(
    private httpService: HttpService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.fetchFunctions().then((result) => {
      if (result) {
        this.functions = result;
      }
    })
    this.fetchFunctionsInfo().then((result) => {
      if (result) {
        this.functionsInfo = result;
      }
    })
  }

  private fetchFunctions(): Promise<string[] | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/admin/getAzureFunctions`).then((functions: string[]) => {
        if (functions) {
          return resolve(functions);
        }
        return resolve(null);
      })
    })
  }

  private fetchFunctionsInfo(): Promise<string[] | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/admin/getAzureFunctionsInfo`).then((functionsInfo: any[]) => {
        if (functionsInfo) {
          return resolve(functionsInfo);
        }
        return resolve(null);
      })
    })
  }

}