<button class="btn btn-danger d-block w-100 mb-2" (click)="resetProductData()" *ngIf="isAdmin" [disabled]="dataCatalogStatus?.status != 'READY'">{{ "Template.MainComponent.label_deleteProducts" | translate }}</button>

<a class="btn btn-secondary d-block w-100 mb-2" [href]="'/assets/data/supplier-data-template-' + currentLanguage?.toUpperCase() + '.xlsx'">{{ "Template.MainComponent.label_downloadTemplate" | translate }} </a>

<button class="btn btn-secondary d-block w-100 mb-2" (click)="getUploadedFile()">{{ "Template.MainComponent.label_downloadUploadedFile" | translate }}</button>

<button class="btn btn-secondary d-block w-100 mb-2" (click)="requestDownload('PIM')" *ngIf="isAdmin" [disabled]="requestingDownload">
	{{ "Template.MainComponent.label_downloadGeneratedFile" | translate }} (Format PIM)
	<fa-icon [icon]="'spinner'" [spin]="true" *ngIf="requestingDownload"></fa-icon>
</button>

<button class="btn btn-secondary d-block w-100 mb-2" (click)="requestDownload('SUPPLIER_PORTAL')" [disabled]="requestingDownload">
	{{ "Template.MainComponent.label_downloadGeneratedFile" | translate }}
	<span *ngIf="isAdmin">({{ "Template.MainComponent.label_format" | translate }} Supplier Portal)</span>
	<fa-icon [icon]="'spinner'" [spin]="true" *ngIf="requestingDownload"></fa-icon>
</button>
