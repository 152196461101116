<div class="titlebar">
    <h3>{{ "Template.SupplierProductSearchComponent.label_title" | translate }}</h3>
</div>

<div class="mb-2">
    <input type="text" class="form-control" id="search-product" placeholder="EAN" [(ngModel)]="ean">
    <button class="btn btn-primary mt-2" type="submit" (click)="reload()">{{ "Template.SupplierProductSearchComponent.btn_search" | translate }}</button>
</div>

<table class="table table-striped" *ngIf="supplierProducts && supplierProducts.length">
    <thead>
        <tr>
            <th scope="col" class="col-md-2 col-sm-1">
                {{ "Template.SupplierProductSearchComponent.label_supplierPartyName" | translate }}
            </th>
            <th scope="col" class="col-md-2 col-sm-1">
                {{ "Template.SupplierProductSearchComponent.label_description" | translate }}                        
            </th>
            <th scope="col" class="col-md-2">&nbsp;</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let product of supplierProducts">
            <td> {{ product.supplierPartyName }} </td>
            <td> {{ product.description }} </td>
            <td class="text-end align-middle">
                <a [routerLink]="['/app', 'admin', 'supplier-product', product.id]"
                    class="btn btn-secondary">
                    {{ "Template.SupplierProductSearchComponent.btn_details" | translate }}
                    <fa-icon icon="chevron-right"></fa-icon>
                </a>
            </td>
        </tr>
    </tbody>
</table>
