<div class="container" *ngIf="currentUser">
  <div class="pre-header bg-white text-end">
    <div class="text-primary" ngbDropdown>
      <button type="button" class="btn btn-sm btn-link" type="button" ngbDropdownToggle>
        <fa-icon icon="circle-user" class="mr-1"></fa-icon>
        {{currentUser.firstname}}
        {{currentUser.lastname}}
        |
        <strong>{{currentEmployerPartyName}}</strong>
      </button>
      <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
        <div class="px-3 mb-0">
          <div class="select-employer">
            <h6 class="dropdown-header">{{ "Template.ContainerComponent.subHeader_chooseCompany" | translate }}:</h6>
            <p *ngFor="let partyRel of partyRels">
              <span *ngIf="partyRel.toParty && partyRel.toParty.attributes">
                <strong class="text-primary" *ngIf="partyRel.toParty.partyId == currentEmployerPartyId">
                  {{partyRel.toParty.attributes['NAME']}}
                </strong>
                <a (click)="switchEmployerPartyId(partyRel.toParty.partyId)" class="text-dark" *ngIf="partyRel.toParty.partyId != currentEmployerPartyId">{{partyRel.toParty.attributes['NAME']}}</a>
              </span>
            </p>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" [routerLink]="['/app', 'common', 'settings']" [routerLinkActive]="['mc-active']">
          {{ "Template.ContainerComponent.text_settings" | translate }}
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item text-danger" (click)="logout()">
          <strong>{{ "Template.ContainerComponent.text_logout" | translate }}</strong>
        </a>
      </div>
    </div>
  </div>
</div>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <a class="navbar-brand py-0" [routerLink]="['/app', 'common', 'main']" [routerLinkActive]="['mc-active']">
      <img src="/assets/images/brand/logo_mc.png" height="50" class="d-inline-block mr-1" alt="EK MedienCenter">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNavigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="mainNavigation">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item active" *ngIf="currentUser && userRoles.includes('ROLE_CUSTOMER')">
          <a class="nav-link" [routerLink]="['/app', 'admin', 'main']" [routerLinkActive]="['mc-active']">{{
            "Template.ContainerComponent.btn_download" | translate }}</a>
        </li>
        <li class="nav-item" *ngIf="currentUser && userRoles.includes('ROLE_SUPPLIER')">
          <a class="nav-link" [routerLink]="['/app', 'suppliers', 'main']" [routerLinkActive]="['mc-active']">{{
            "Template.ContainerComponent.btn_supplierCockpit" | translate }}</a>
        </li>
        <li class="nav-item" *ngIf="currentUser && userRoles.includes('ROLE_ADMIN')">
          <a class="nav-link" [routerLink]="['/app', 'admin', 'main']" [routerLinkActive]="['mc-active']">{{
            "Template.ContainerComponent.btn_homepage" | translate }}</a>
        </li>
        <li class="nav-item dropdown" *ngIf="currentUser && userRoles.includes('ROLE_ADMIN')">
          <div ngbDropdown>
            <a id="administration-dropdown" class="nav-link" ngbDropdownToggle role="button">{{
              "Template.ContainerComponent.btn_administration" | translate }}</a>
            <div ngbDropdownMenu="administration-dropdown">
              <!-- <a ngbDropdownItem class="dropdown-item" [routerLink]="['/app', 'admin', 'jobs']"
                [routerLinkActive]="['mc-active']">
                {{ "Template.ContainerComponent.text_jobs" | translate }}
              </a> -->
              <a ngbDropdownItem class="dropdown-item" [routerLink]="['/app', 'admin', 'emails']" [routerLinkActive]="['mc-active']">
                {{ "Template.ContainerComponent.text_emailCenter" | translate }}
              </a>
              </div>
          </div>
        </li>
        <li class="nav-item dropdown" *ngIf="currentUser && userRoles.includes('ROLE_ADMIN')">
          <div ngbDropdown>
            <a id="data-dropdown" class="nav-link" ngbDropdownToggle role="button">{{ "Template.ContainerComponent.btn_data"
              | translate }}</a>
            <div ngbDropdownMenu="data-dropdown">
              <!-- <a ngbDropdownItem class="dropdown-item" [routerLink]="['/app', 'admin', 'missing-media']"
                [routerLinkActive]="['mc-active']">{{ "Template.ContainerComponent.text_missingMedia" | translate }}</a> -->
              <a ngbDropdownItem class="dropdown-item" [routerLink]="['/app', 'admin', 'media-search']"
                [routerLinkActive]="['mc-active']">{{ "Template.ContainerComponent.text_mediaSearch" | translate }}</a>
              <!-- <a ngbDropdownItem class="dropdown-item" [routerLink]="['/app', 'admin', 'data-streams']"
                [routerLinkActive]="['mc-active']">{{ "Template.ContainerComponent.text_datastreams" | translate }}</a>              
              <a ngbDropdownItem class="dropdown-item" [routerLink]="['/app', 'admin', 'preview']"
                [routerLinkActive]="['mc-active']">{{ "Template.ContainerComponent.text_preview" | translate }}</a> -->
              <a ngbDropdownItem class="dropdown-item" [routerLink]="['/app', 'admin', 'data-catalogs']"
                [routerLinkActive]="['mc-active']">{{ "Template.ContainerComponent.text_dataCatalogs" | translate }}</a>
              <a ngbDropdownItem class="dropdown-item" [routerLink]="['/app', 'admin', 'supplier-product-search']"
                [routerLinkActive]="['mc-active']">{{ "Template.ContainerComponent.text_supplierProductSearch" | translate }}</a>
            </div>
          </div>
        </li>
        <li class="nav-item" *ngIf="currentUser && userRoles.includes('ROLE_ADMIN')">
          <a class="nav-link" [routerLink]="['/app', 'admin', 'customers']" [routerLinkActive]="['mc-active']">{{
            "Template.ContainerComponent.text_customers" | translate }}</a>
        </li>
        <li class="nav-item" *ngIf="currentUser && userRoles.includes('ROLE_ADMIN')">
          <a class="nav-link" [routerLink]="['/app', 'admin', 'suppliers']" [routerLinkActive]="['mc-active']">{{
            "Template.ContainerComponent.text_suppliers" | translate }}</a>
        </li>

        <li class="nav-item" *ngIf="!currentUser">
          <a class="nav-link" [routerLink]="['login']" [routerLinkActive]="['mc-active']">{{
            "Template.ContainerComponent.text_login" | translate }}</a>
        </li>
      </ul>

    </div>
  </div>
</nav>

<div class="container p-4 content-wrapper">
  <div class="alerts">
    <div *ngFor="let alert of alerts">
      <ngb-alert [type]="alert.color" [dismissible]="!alert.unclosable" (closed)="closeAlert(alert)" [ngClass]="{'fadeout': alert.fadingOut}">
        <strong *ngIf="alert.title">{{alert.title}}: </strong>
        <span [innerHTML]="alert.body"></span>
      </ngb-alert>
    </div>
  </div>

  <router-outlet></router-outlet>

</div>

<div id="footer">
  <div class="container">
    <div class="row">
      <div class="col-6 col-xs-12">
        <h3 class="hidden-xs">{{ "Template.ContainerComponent.subHeader_information" | translate }}</h3>
        <a href="https://www.ek-retail.com/" target="_blank">{{ "Template.ContainerComponent.text_aboutUs" | translate }}</a>
        <!-- <a [routerLink]="['/contact']" [routerLinkActive]="['mc-active']">{{ "Template.ContainerComponent.text_contact"
          | translate }}</a> -->
      </div>
      <div class="col-6 col-xs-12">
        <h3 class="hidden-xs">{{ "Template.ContainerComponent.text_Miscellaneous" | translate }}</h3>
        <a href="/assets/data/DataUserAgreement.pdf" target="_blank" *ngIf="currentUser">{{
          "Template.ContainerComponent.text_UserAgreement" | translate }}</a>
        <a [routerLink]="['/privacy']" [routerLinkActive]="['mc-active']">{{ "Template.ContainerComponent.text_privacy"
          | translate }}</a>
        <a [routerLink]="['/imprint']" [routerLinkActive]="['mc-active']">{{ "Template.ContainerComponent.text_imprint"
          | translate }}</a>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 text-center">
        <small>&copy; {{ now | date: 'yyyy'}} <strong>EK servicegroup eG</strong>, {{
          "Template.ContainerComponent.text_allRightsReserved" | translate }}</small>
      </div>
    </div>
  </div>
</div>

<div class="loading-indicator" *ngIf="loading">
  <div class="overlay">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
    <h1>{{ "Template.ContainerComponent.subHeader_pleaseWait" | translate }}...</h1>
  </div>
</div>