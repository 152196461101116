import { PartyRelationship } from "@models/party-relationship";
import { DatabaseObject } from "@models/database-object";
import { PartyIdentification } from "./party-identification";

export class Party extends DatabaseObject {
  constructor(partyId: string) {
    super();
    this.partyId = partyId;
    this.partyRelationshipsFrom = [];
    this.partyRelationshipsTo = [];
    this.identifier = null;
  }
  partyId: string;
  enabled?: boolean;
  identifier: string | null;
  typeId?: string;
  identifications?: {
    [key: string]: PartyIdentification
  };
  partyRelationshipsFrom: PartyRelationship[];
  partyRelationshipsTo: PartyRelationship[];
  attributes?: {
    [key: string]: string
  };
}
