import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent implements AfterViewInit {

  @Input() public title: string = '';
  @Input() public body: string = '';
  @Input() public buttons: any[] = [
    {
      text: this.translateService.instant("Dialog.GenericModalComponent.btn_okay"),
      index: 1,
    }
  ];

  @Output() public indexOutput = new EventEmitter<number>();

  @ViewChild('modalContent') private modalContent!: TemplateRef<GenericModalComponent>;
  private modalRef!: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private translateService: TranslateService
  ) { }

  ngAfterViewInit(): void {
    this.open();
  }

  open() {
    this.modalRef = this.modalService.open(this.modalContent)
  }

  close(index: number) {
    this.modalRef.close();
    this.indexOutput.emit(index);
  }

  dismiss() {
    this.modalRef.dismiss();
    this.indexOutput.emit(0);
  }

}
