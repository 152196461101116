<div class="titlebar">
    <h3>{{ "Template.JobsComponent.subHeader_jobs" | translate }}</h3>    
</div>

<div class="row">
    <div class="col-3">
        <h5>{{ "Template.JobsComponent.subHeader_activeFunctionApps" | translate }}</h5>
        <table class="table">
            <tbody>
                <tr *ngFor="let function of functions">
                    <td>{{ function }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="col-9">
        <h5>{{ "Template.JobsComponent.subHeader_statusInfo" | translate }}</h5>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">{{ "Template.JobsComponent.label_subprojectName" | translate }}</th>
                    <th scope="col">{{ "Template.JobsComponent.label_functionStatus" | translate }}</th>
                    <th scope="col">{{ "Template.JobsComponent.label_trigger" | translate }}</th>
                    <th scope="col">{{ "Template.JobsComponent.label_lastStart" | translate }}</th>
                    <th scope="col">{{ "Template.JobsComponent.label_lastStop" | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let infoItem of functionsInfo">
                    <td><span *ngIf="infoItem.id">{{ infoItem.id }}</span></td>
                    <td><span *ngIf="infoItem.status">{{ infoItem.status }}</span></td>
                    <td><span *ngIf="infoItem.viaTrigger">{{ infoItem.viaTrigger }}</span></td>
                    <td><span *ngIf="infoItem.lastStart">{{ infoItem.lastStart | date :"d. MMMM yyyy, H:mm:ss" }}</span></td>
                    <td><span *ngIf="infoItem.lastStop">{{ infoItem.lastStop | date :"d. MMMM yyyy, H:mm:ss" }}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>


<div class="row">
    <div class="col-4">
        
    </div>
</div>


