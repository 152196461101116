import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public error: any;
  public loginForm: FormGroup;
  public isLoading: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['',
        Validators.compose([Validators.required, Validators.email])
      ],
      password: ['',
        Validators.compose([Validators.required])
      ]
    });
  }

  ngOnInit(): void {
  }

  public loginUser(): void {
    this.isLoading = true;

    this.authService.loginUser(this.loginForm.value.username, this.loginForm.value.password).then(() => {
      if (this.authService.redirectUrl) {
        this.router.navigateByUrl(this.authService.redirectUrl);
        this.authService.redirectUrl = '';
      } else {
        this.router.navigateByUrl('/app');
      }
      this.isLoading = false;
    }).catch(error => {
      this.isLoading = false;

      this.error = error || 'Die Anmeldung ist momentan nicht möglich.';
    });
  }

}
